import { createColumnHelper } from '@tanstack/react-table'
import { McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import {
  formatValue,
  FuelLineType,
  FuelType as FuelTypeEnum,
} from '../../../../utils'
import {
  ExpanderCell,
  NumericCell,
  NumericHeader,
  NumericTooltipCell,
} from '../../../../components/Table/Table.styles'
import { type BatchResponse } from '../../../../api-models/performance/fuel-oil-stock'
import BioFuelIcon from '../../../../assets/images/bio.svg'
import {
  filterFuelTypeSelection,
  mapFuelLineTypeToHeaderText,
} from '../../../../utils/mappers/FuelLineMappers.utils'

const columnHelper = createColumnHelper<any>()

export const getColumns = (
  fuelLineTypes: Array<number>,
  batchEntries: Array<BatchResponse>,
  precision: number,
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[],
  hasMainEngSecondaryFuelLine: boolean,
) => [
  columnHelper.display({
    id: 'fuelType',
    header: 'Fuel',
    cell: ({ row }) => {
      const { batchId } = row.original
      const batch = batchEntries.find(({ id }) => id === batchId)
      const isBioFuel = batch && batch.fuel.bioPercentage > 0

      return (
        <ExpanderCell isHead={row.getCanExpand()}>
          <McIcon
            icon='chevron-right'
            className={`${!row.getCanExpand() ? 'hidden' : ''} ${
              row.getIsExpanded() ? 'open' : ''
            }`}
          />
          <span>{row.original.fuelType}</span>
          {isBioFuel && <img src={BioFuelIcon} alt='Bio fuel icon' />}
        </ExpanderCell>
      )
    },
  }),
  ...fuelLineTypes.map((fuelLineType) => {
    const fuelLine = FuelLineType[fuelLineType]
    const matchingFuelTypeSelection = filterFuelTypeSelection(
      currentFuelTypeSelections,
      fuelLineType,
    )
    return columnHelper.display({
      id: `${fuelLine}.consumedMass`,
      header: () => (
        <NumericHeader>
          {mapFuelLineTypeToHeaderText(
            matchingFuelTypeSelection?.fuelType ?? 0,
            matchingFuelTypeSelection?.fuelLineType ?? 0,
            hasMainEngSecondaryFuelLine,
          )}{' '}
          (MT)
        </NumericHeader>
      ),
      cell: ({ row }) =>
        row.original[fuelLine] ? (
          <McTooltip position='top-center' appearance='inverse'>
            <NumericTooltipCell slot='trigger'>
              {formatValue(
                row.original[fuelLine].consumedMass,
                row.original[fuelLine].consumedMass ? precision : 0,
              )}
            </NumericTooltipCell>
            <span>
              {getDataAvailabilityMessage(
                row.original[fuelLine].dataAvailability,
              )}
            </span>
          </McTooltip>
        ) : (
          <NumericCell>
            {formatValue(
              row.original[fuelLine]?.consumedMass,
              row.original[fuelLine]?.consumedMass ? precision : 0,
            )}
          </NumericCell>
        ),
    })
  }),
  columnHelper.accessor('total', {
    header: () => <NumericHeader>Total (MT)</NumericHeader>,
    enableSorting: false,
    cell: (props) => (
      <NumericCell>
        {formatValue(props.getValue(), props.getValue() ? precision : 0)}
      </NumericCell>
    ),
  }),
]

/**
 * Takes a list of objects and converts into a single object
 * @param listOfObj List of objects to convert into a dictionary
 * @param keyInCurrentObj the key used in the old object
 * @param getNewKey a function for handling transformations for the new key
 */
const toDictionaryFromKey = (
  listOfObj: { [key: string]: any }[],
  keyInCurrentObj: string,
  getNewKey?: (keyInCurrentObj: string) => string,
): { [key: string]: any } => {
  return listOfObj.reduce((finalObj, currentObj) => {
    const { [keyInCurrentObj]: oldKey, ...rest } = currentObj
    const newKey = getNewKey ? getNewKey(oldKey) : keyInCurrentObj
    return {
      ...finalObj,
      [newKey]: rest,
    }
  }, {})
}

export const flattenFuelLines = (fuelLines: { [key: string]: any }[]) =>
  toDictionaryFromKey(
    fuelLines,
    'fuelLineType',
    (fuelLineType) => FuelLineType[fuelLineType],
  )

export const flattenFuelTypes = (fuelTypes: { [key: string]: any }[]) =>
  toDictionaryFromKey(
    fuelTypes,
    'fuelType',
    (fuelType) => FuelTypeEnum[fuelType],
  )

export const isDataAvailabilityLow = (availability: number) => {
  return availability ? availability <= 98.5 : false
}

const getDataAvailabilityMessage = (availability: number) => {
  return availability ? (
    <>
      Data for fuel line was available for <b>{availability}%</b> of the period
    </>
  ) : (
    <>Data availability for this fuel line not found for the period</>
  )
}
