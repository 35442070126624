import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import moment from 'moment'

import { Performance } from '../../../api-models'
import { VesselPageContext } from '../../../contexts'
import { getFuelTypeMonitor } from '../../../services/performance'
import {
  ChartContainer,
  ChartStencil,
  Loading,
  NotFound,
  VesselStatusBlock,
} from '../../../commons'
import { UNITS, FUEL_LINE_TYPES } from '../../../utils/constants'
import { whiteColorScheme } from '../../../theme'
import { FuelConsumptionOverviewChart } from '../charts/FuelConsumptionOverviewChart'
import { StatusBlock } from './StatusBlock'
import { FuelLine } from '../../../api-models/performance/status'
import { displayErrorModal } from '../../../utils'
import { useGetCurrentFuelTypeSelections } from '../../../queries/PerformanceApi/Papi'

export const FuelConsumptionOverview: FunctionComponent = () => {
  const { vesselStatus, imoNo } = useContext(VesselPageContext)
  const timestamp = vesselStatus?.timestamp
  const { data: getCurrentFuelTypeSelections, isLoading } =
    useGetCurrentFuelTypeSelections(imoNo!)

  const [fuelConsumptionData, setFuelConsumptionData] =
    React.useState<Performance.FuelConsumption.FuelTypeMonitorResponse>()

  const loadData = useCallback(() => {
    setFuelConsumptionData(undefined)
    const queryPeriod = {
      to: moment.utc().toISOString(),
      from: moment.utc().subtract(3, 'days').toISOString(),
    } as Performance.Common.QueryPeriod
    if (imoNo) {
      getFuelTypeMonitor(imoNo, queryPeriod)
        .then(setFuelConsumptionData)
        .catch((e) => {
          displayErrorModal({
            statusText: 'Could not fetch fuel consumption data',
            message: e.message,
          })
        })
    }
  }, [imoNo])

  useEffect(() => {
    /* Calling loadData on mount or if loadData has changed as a result of new imoNo */
    loadData()
  }, [loadData, timestamp])

  if (isLoading || !getCurrentFuelTypeSelections) {
    return <Loading />
  }

  return (
    <ChartContainer
      expandHeight={true}
      y1Label={{
        name: UNITS.MT_PER_HOUR,
        colorScheme: whiteColorScheme,
      }}
    >
      {!fuelConsumptionData && <ChartStencil chartType='area' />}
      {fuelConsumptionData && !fuelConsumptionData.hasData && (
        <NotFound text='No data for the last three days found' />
      )}
      {fuelConsumptionData &&
        fuelConsumptionData.hasData &&
        fuelConsumptionData.timestamps && (
          <FuelConsumptionOverviewChart
            id='fc-overview'
            timestamps={fuelConsumptionData.timestamps}
            queryPeriod={fuelConsumptionData.queryPeriod}
            fuelConsumptionData={fuelConsumptionData}
          />
        )}
      {!!vesselStatus && (
        <VesselStatusBlock timestamp={vesselStatus.timestamp}>
          <StatusBlock
            header={'ME'}
            dataFuelLines={
              vesselStatus.fuelConsumption?.mainEngFuelLines as FuelLine[]
            }
            unit={UNITS.MT_PER_HOUR}
            currentFuelTypeSelections={getCurrentFuelTypeSelections.filter(
              (fuelLine) =>
                [
                  FUEL_LINE_TYPES.ME_MAIN,
                  FUEL_LINE_TYPES.ME_SECONDARY,
                ].includes(fuelLine.fuelLineType),
            )}
          />
          <StatusBlock
            header='AE'
            dataFuelLines={vesselStatus.fuelConsumption?.auxEngFuelLines}
            unit={UNITS.KG_PER_HOUR}
            currentFuelTypeSelections={getCurrentFuelTypeSelections.filter(
              (fuelLine) =>
                [
                  FUEL_LINE_TYPES.AE_MAIN,
                  FUEL_LINE_TYPES.AE_SECONDARY,
                  FUEL_LINE_TYPES.AE_MDO,
                ].includes(fuelLine.fuelLineType),
            )}
          />
          <StatusBlock
            header='Boiler'
            dataFuelLines={vesselStatus.fuelConsumption?.boilerFuelLines}
            unit={UNITS.KG_PER_HOUR}
            currentFuelTypeSelections={getCurrentFuelTypeSelections.filter(
              (fuelLine) =>
                [
                  FUEL_LINE_TYPES.BOILER_MAIN,
                  FUEL_LINE_TYPES.BOILER_MDO,
                ].includes(fuelLine.fuelLineType),
            )}
          />
        </VesselStatusBlock>
      )}
    </ChartContainer>
  )
}
