import { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import { VesselPageContext } from '../../../../contexts'
import { Loading, Modal } from '../../../../commons'
import { RecoveryForm } from './RecoveryForm'
import { Performance } from '../../../../api-models'
import * as PerformanceAPI from '../../../../services/performance'
import { displayErrorModal } from '../../../../utils'
import { StockState } from '../../models'

type Props = {
  recoveryAvailableFromDate?: moment.Moment | null
  stateOfStock: StockState
  closeHandler: () => void
}

export const RecoveryModal = ({
  recoveryAvailableFromDate,
  stateOfStock,
  closeHandler,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [batches, setBatches] =
    useState<Performance.FuelOilStock.BatchResponse[]>()
  const showErrorWithTitle = (statusText) => (error) =>
    displayErrorModal({
      statusText,
      message: error.message,
    })

  useEffect(() => {
    PerformanceAPI.getBatches(imoNo, '', true)
      .then(setBatches)
      .catch(showErrorWithTitle('Could not get batches on board'))
  }, [imoNo])

  return (
    <Modal title='Recovery' visible closeHandler={closeHandler}>
      {batches ? (
        <RecoveryForm
          recoveryAvailableFromDate={recoveryAvailableFromDate}
          closeHandler={closeHandler}
          batches={batches}
          stateOfStock={stateOfStock}
        />
      ) : (
        <Loading />
      )}
    </Modal>
  )
}
