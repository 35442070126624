import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import { ModelData, initChart, updateChart, TestData } from './Charts.utils'
import { resolveOperatingCondition } from '../../MetcReportPage.utils'
import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'

const ISO_SFOC_CHART_ID = 'iso-sfoc-chart'
const ISO_PMAX_PCOMP_CHART_ID = 'iso-pmax-pcomp-chart'

type Props = {
  isActive: boolean
  general: GandalfApi.Metc.General
  equipment: GandalfApi.Metc.Equipment
  result: GandalfApi.Metc.Result
  dataFormat: number
}

const IsoCharts = ({
  isActive,
  general,
  equipment,
  result,
  dataFormat,
}: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const sfocChartRef = useRef<EChartsType>()
  const pmaxPcompChartRef = useRef<EChartsType>()

  const operatingCondition = resolveOperatingCondition(
    dataFormat,
    general,
    equipment,
  )

  const setSfocData = () => {
    if (!operatingCondition) {
      return
    }

    const modelData: Array<ModelData> = [
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.sfoc,
        name: 'SFOC shop test',
      },
    ]
    const testData: Array<TestData> = [
      {
        x: result.general.engineLoad,
        y: result.adjustedIsoConditions.adjustedToISOConditions.sfoc,
        name: 'SFOC test (ISO)',
      },
    ]

    updateChart(modelData, testData, sfocChartRef.current)
  }

  const setPcompPmaxData = () => {
    if (!operatingCondition) {
      return
    }

    const modelData: Array<ModelData> = [
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.pcomp,
        name: 'Pcomp shop test',
      },
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.pmax,
        name: 'Pmax shop test',
      },
    ]
    const testData: Array<TestData> = [
      {
        x: result.general.engineLoad,
        y: result.adjustedIsoConditions.adjustedToISOConditions.pcomp,
        name: 'Pcomp test (ISO)',
      },
      {
        x: result.general.engineLoad,
        y: result.adjustedIsoConditions.adjustedToISOConditions.pmax,
        name: 'Pmax test (ISO)',
      },
    ]

    updateChart(modelData, testData, pmaxPcompChartRef.current)
  }

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      sfocChartRef.current = initChart({
        id: ISO_SFOC_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'SFOC',
        yUnit: 'g/kWh',
        truncateYAxis: 20,
      })
      setSfocData()

      pmaxPcompChartRef.current = initChart({
        id: ISO_PMAX_PCOMP_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Pmax & Pcomp',
        yUnit: 'bar',
      })
      setPcompPmaxData()
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) {
      return
    }

    setSfocData()
    setPcompPmaxData()
  }, [result.adjustedIsoConditions])

  return (
    <ChartsWrapper>
      <ChartWrapper id={ISO_SFOC_CHART_ID} />
      <ChartWrapper id={ISO_PMAX_PCOMP_CHART_ID} />
    </ChartsWrapper>
  )
}

export default IsoCharts
