import { useContext } from 'react'

import { TableContainer } from '../../EngineTestReportPage.styles'
import { formatNumberWithUnit } from '../../../../utils'
import { WindowContext } from '../../../../contexts'
import { UNITS } from '../../../../utils/constants'

type Props = {
  general: GandalfApi.Aetc.General
  adjustedIsoConditions: GandalfApi.Aetc.AdjustedIsoConditions
}

const AdjustedToIsoConditions = ({ general, adjustedIsoConditions }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <thead>
          <tr>
            <th>Measurements adjusted to ISO condition</th>
            <th>Measured value</th>
            <th>Model</th>
            <th>SFOC impact</th>
            <th>Pmax impact</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Engine room / barometric pressure</td>
            <td>
              {general.engRoomPres.value} {UNITS.MILLIMETER_H2O}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomPres.modelValue,
                2,
                UNITS.MILLIBAR,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomPres.sfoc,
                2,
                UNITS.SFOC,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomPres.pmax,
                2,
                UNITS.BAR,
              )}
            </td>
          </tr>
          <tr>
            <td>Engine room / blower inlet temperature</td>
            <td>
              {general.engRoomTemp.value} {UNITS.TEMPERATURE}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomTemp.modelValue,
                2,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomTemp.sfoc,
                2,
                UNITS.SFOC,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.engRoomTemp.pmax,
                2,
                UNITS.BAR,
              )}
            </td>
          </tr>
          <tr>
            <td>Scavenge air temperature</td>
            <td>
              {general.scavAirTemp.value} {UNITS.TEMPERATURE}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.scavAirTemp.modelValue,
                2,
                UNITS.TEMPERATURE,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.scavAirTemp.sfoc,
                2,
                UNITS.SFOC,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.scavAirTemp.pmax,
                2,
                UNITS.BAR,
              )}
            </td>
          </tr>
          <tr>
            <td>LCV correction</td>
            <td>
              {general.batchLcv.value} {UNITS.KJ_PER_KILO}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.lcvCorrection.modelValue,
                0,
                UNITS.KJ_PER_KILO,
              )}
            </td>
            <td>
              {formatNumberWithUnit(
                adjustedIsoConditions.lcvCorrection.sfoc,
                2,
                UNITS.SFOC,
              )}
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>Adjusted to ISO Conditions</td>
            <td>-</td>
            <td>-</td>
            <td className='strong'>
              {formatNumberWithUnit(
                adjustedIsoConditions.adjustedToISOConditions?.sfoc,
                2,
                UNITS.SFOC,
              )}
            </td>
            <td className='strong'>
              {formatNumberWithUnit(
                adjustedIsoConditions.adjustedToISOConditions?.pmax,
                2,
                UNITS.BAR,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default AdjustedToIsoConditions
