import React, { FunctionComponent, useContext } from 'react'

import { FancyLabel, ValueLabel } from '../../../commons'
import { mapFuelTypeToColorScheme } from '../mappers'
import { formatValue } from '../../../utils'
import {
  getDecimalsFromUnit,
  convertToFuelLineUnit,
} from '../utils/fuel-consumption'
import { whiteColorScheme } from '../../../theme'
import { Performance } from '../../../api-models'
import { VesselPageContext } from '../../../contexts'
import { mapFuelLineTypeFuelTypeToName } from '../../../utils/mappers/FuelLineMappers.utils'

interface FuelConsumptionBlockProps {
  fuelLine: Performance.Status.FuelLine | undefined
  unit: string
  fuelLineType: number
  fuelType: number
}

export const FuelConsumptionBlock: FunctionComponent<
  FuelConsumptionBlockProps
> = ({ fuelLine, fuelLineType, unit, fuelType }) => {
  const configuration = useContext(VesselPageContext).configuration!

  return fuelLine ? (
    <React.Fragment>
      <FancyLabel
        colorScheme={mapFuelTypeToColorScheme(fuelLine.fuelType)}
        value={true}
        style={{ cursor: 'unset' }}
      >
        {mapFuelLineTypeFuelTypeToName(
          fuelLine.fuelLineType,
          fuelType,
          configuration.hasMainEngSecondaryFuelLine,
        )}
      </FancyLabel>
      <ValueLabel unit={unit}>
        {formatValue(
          convertToFuelLineUnit(fuelLine.consumption, fuelLine.fuelLineType),
          getDecimalsFromUnit(unit, false, fuelLineType),
        )}
      </ValueLabel>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <FancyLabel
        colorScheme={whiteColorScheme}
        value={true}
        style={{ cursor: 'unset' }}
      >
        {mapFuelLineTypeFuelTypeToName(
          fuelLineType,
          fuelType,
          configuration.hasMainEngSecondaryFuelLine,
        )}
      </FancyLabel>
      <ValueLabel unit={unit}>
        {formatValue(null, getDecimalsFromUnit(unit, false, fuelLineType))}
      </ValueLabel>
    </React.Fragment>
  )
}
