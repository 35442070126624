import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'
import {
  initChart,
  updateChart,
} from '../../../MetcReportPage/ResultsTab/Charts/Charts.utils'
import { UNITS } from '../../../../../utils/constants'

type Props = {
  isActive: boolean
  operatingCondition: GandalfApi.Aetc.OperatingCondition
  result: GandalfApi.Aetc.Result
}

const KeyParamsCharts = ({ isActive, operatingCondition, result }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const chartRefs = useRef<Array<EChartsType>>([])

  const charts = [
    {
      id: 'key-params-scav-pres-chart',
      xLabel: 'Engine load',
      xUnit: UNITS.ENGINE_LOAD,
      yLabel: 'Scavenge air pressure',
      yUnit: UNITS.BAR,
      modelData: operatingCondition
        ? [
            {
              x: operatingCondition.referenceCondition.load,
              y: operatingCondition.isoCondition.pscav,
              name: 'Scav pressure ISO shop test',
            },
          ]
        : [],
      testData: [
        {
          x: result.general.engineLoad,
          y: result.keyParams.scavAirPres.isoConditionAdjustedValue,
          name: 'Scav pressure test (ISO)',
        },
      ],
    },
    {
      id: 'key-params-exh-gas-temp-chart',
      xLabel: 'Engine load',
      xUnit: UNITS.ENGINE_LOAD,
      yLabel: 'Exh. gas temp.',
      yUnit: UNITS.TEMPERATURE,
      modelData: operatingCondition
        ? [
            {
              x: operatingCondition.referenceCondition.load,
              y: operatingCondition.isoCondition.exhaustGasCylOutTemp,
              name: 'Exh. gas temp. ISO shop test',
            },
          ]
        : [],
      testData: [
        {
          x: result.general.engineLoad,
          y: result.keyParams.exhGasTempAvg.isoConditionAdjustedValue,
          name: 'Exh. gas temp. test (ISO)',
        },
      ],
    },
    {
      id: 'key-params-fuel-rack-chart',
      xLabel: 'Engine load',
      xUnit: UNITS.ENGINE_LOAD,
      yLabel: 'Fuel rack',
      yUnit: UNITS.MILLIMETER,
      modelData: operatingCondition
        ? [
            {
              x: operatingCondition.referenceCondition.load,
              y: operatingCondition.isoCondition.fuelPumpIndex,
              name: 'Fuel rack ISO shop test',
            },
          ]
        : [],
      testData: [
        {
          x: result.general.engineLoad,
          y: result.keyParams.fuelPumpIdx.isoConditionAdjustedValue,
          name: 'Fuel rack test (ISO)',
        },
      ],
    },
    {
      id: 'key-params-vit-pos-chart',
      xLabel: 'Engine load',
      xUnit: UNITS.ENGINE_LOAD,
      yLabel: 'VIT position',
      yUnit: UNITS.MILLIMETER,
      modelData: operatingCondition
        ? [
            {
              x: operatingCondition.referenceCondition.load,
              y: operatingCondition.isoCondition.vitPos,
              name: 'VIT position ISO shop test',
            },
          ]
        : [],
      testData: [
        {
          x: result.general.engineLoad,
          y: result.keyParams.vitPos.isoConditionAdjustedValue,
          name: 'VIT position test (ISO)',
        },
      ],
    },
    {
      id: 'key-params-tc-speed-chart',
      xLabel: 'Engine load',
      xUnit: UNITS.ENGINE_LOAD,
      yLabel: 'Turbocharger speed',
      yUnit: UNITS.RPM,
      modelData: operatingCondition
        ? [
            {
              x: operatingCondition.referenceCondition.load,
              y: operatingCondition.isoCondition.tcRpm,
              name: 'TC RPM ISO shop test',
            },
          ]
        : [],
      testData: [
        {
          x: result.general.engineLoad,
          y: result.keyParams.tcSpeed.isoConditionAdjustedValue,
          name: 'TC RPM test (ISO)',
        },
      ],
    },
  ]

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      charts.forEach(({ modelData, testData, ...config }, idx) => {
        chartRefs.current[idx] = initChart(config) as EChartsType
        updateChart(modelData, testData, chartRefs.current[idx])
      })
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) {
      return
    }

    charts.forEach(({ modelData, testData, ...config }, idx) => {
      updateChart(modelData, testData, chartRefs.current[idx])
    })
  }, [result])

  return (
    <ChartsWrapper>
      {charts.map((config) => (
        <ChartWrapper key={config.id} id={config.id} />
      ))}
    </ChartsWrapper>
  )
}

export default KeyParamsCharts
