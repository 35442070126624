import moment from 'moment'
import * as echarts from 'echarts'

import {
  type IThemeInterface,
  fillOpacity,
  normal,
  grey,
  red,
} from '../../../theme'
import { UserEntryTypes } from '../../stock-management'
import { type Performance } from '../../../api-models'
import {
  BatchMonitorSeriesMapped,
  THEMES_BY_FUEL_TYPE,
  type ElementStyles,
} from '../models/batch-monitor'
import { BatchIndicatorsLabels } from '../../stock-management/models'
import { BatchIndicatorType } from '../../../queries/PerformanceApi/Papi.consts'
import {
  filterFuelTypeSelection,
  mapFuelLineTypeFuelTypeToName,
} from '../../../utils/mappers/FuelLineMappers.utils'

const getDimensionDisplayName = (
  indicator: Performance.FuelConsumption.Indicator,
): string => {
  if (indicator.type === BatchIndicatorType.Blend) {
    return BatchIndicatorsLabels.Blending
  }

  if (indicator.type === BatchIndicatorType.Error) {
    return BatchIndicatorsLabels.Error
  }

  return indicator.batch?.displayName || '-'
}

export const mapBatchMonitorToDimensions = (
  hasMainEngSecondaryFuelLine: boolean,
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[],
  batchMonitorSeries?: Array<Performance.FuelConsumption.FuelLineBatchMonitor>,
  queryPeriod?: Performance.Common.QueryPeriod,
): BatchMonitorSeriesMapped => {
  if (!batchMonitorSeries || !batchMonitorSeries[0] || !queryPeriod) {
    return { indicators: [], fuelLines: [] }
  }

  const fuelLinesMapped: any[][] = [
    ...batchMonitorSeries.flatMap((fuelLine) => {
      const matchingFuelTypeSelection = filterFuelTypeSelection(
        currentFuelTypeSelections,
        +fuelLine.fuelLineType,
      )
      return fuelLine.indicators.map((indicator, index, indicators) => [
        mapFuelLineTypeFuelTypeToName(
          matchingFuelTypeSelection?.fuelLineType ?? 0,
          matchingFuelTypeSelection?.fuelType ?? 0,
          hasMainEngSecondaryFuelLine,
        ),
        moment.utc(indicator.timestamp).valueOf(),
        indicators[index + 1]
          ? moment.utc(indicators[index + 1].timestamp).valueOf()
          : moment.utc(queryPeriod.to).valueOf(),
        indicator.type,
        indicator.fuelType,
        indicator.batch ? indicator.batch.id : '',
        getDimensionDisplayName(indicator),
        indicator.batch ? indicator.batch.type : '',
        indicator.fromBatch ? indicator.fromBatch.id : '',
        indicator.fromBatch ? indicator.fromBatch.displayName : '',
        indicator.fromBatch ? indicator.fromBatch.type : '',
        indicator.toBatch ? indicator.toBatch.id : '',
        indicator.toBatch ? indicator.toBatch.displayName : '',
        indicator.toBatch ? indicator.toBatch.type : '',
        indicator.errorMessage ? indicator.errorMessage : '',
      ])
    }),
  ]

  const fuelLines = batchMonitorSeries.map(
    (line) => line.fuelLineType,
    // mapFuelLineTypeToName(line.fuelLineType)
  )

  return { indicators: fuelLinesMapped, fuelLines: fuelLines }
}

export const getTheme = (
  indicatorType: number,
  fuelType?: number,
): IThemeInterface => {
  if (
    indicatorType === UserEntryTypes.BunkeredBatch &&
    typeof fuelType === 'number'
  ) {
    return THEMES_BY_FUEL_TYPE[fuelType]
  }

  if (indicatorType === BatchIndicatorType.Error) {
    return fillOpacity({ ...normal, border: red[500], bg: red[500] }, 0.6)
  }

  return fillOpacity({ ...normal, border: grey[500], bg: grey[500] }, 0.6)
}

export const getElementStyles = (
  indicatorType: number,
  fuelType?: number,
): ElementStyles => {
  const theme = getTheme(indicatorType, fuelType)
  const lineWidth = 1

  return {
    fill: theme.bg,
    stroke: theme.border,
    lineWidth: lineWidth,
    textFill: '#000',
    cursor: 'pointer',
  }
}

export const truncateLabel = (
  x: number,
  text: string,
  barLength: number,
  LABEL_WIDTH_PADDING: number,
): string => {
  const removeLastCharacter = (str) =>
    str.length < 4
      ? ''
      : str.slice(0, str.length - 4) + str.slice(str.length - 3, str.length)

  if (
    x + barLength <= LABEL_WIDTH_PADDING ||
    barLength <= LABEL_WIDTH_PADDING
  ) {
    const labelWidth = (echarts as any).format.getTextRect('...').width
    if (
      barLength <= labelWidth + LABEL_WIDTH_PADDING ||
      barLength + x <= labelWidth + LABEL_WIDTH_PADDING
    ) {
      return ''
    }
    return '...'
  }

  const labelWidth = (echarts as any).format.getTextRect(text).width
  let labelWidth2 = labelWidth
  let labelText = text
  while (
    (barLength <= labelWidth2 + LABEL_WIDTH_PADDING ||
      x + barLength <= labelWidth2 + LABEL_WIDTH_PADDING * 2) &&
    labelText !== ''
  ) {
    if (labelText[labelText.length - 1] === '.') {
      labelText = removeLastCharacter(labelText)
    } else {
      labelText = labelText.substring(0, text.length - 4) + '...'
    }
    labelWidth2 = (echarts as any).format.getTextRect(labelText).width
  }
  return labelText
}
