import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Formik,
  type FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import moment, { type Moment } from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { type Event } from '../../../../api-models/hdc/events'
import { editEvent, postEvent } from '../../../../services/hdc'
import { UNITS } from '../../../../utils/constants'
import { displayErrorModal } from '../../../../utils'
import { SimpleFormRow } from '../../components/form-row'
import OverlayLoader from '../../components/overlay-loader'
import HDCContext from '../../context/context'
import {
  ESubReport,
  EventTypeName,
  HDCFormType,
  type HDCReportFormValues,
} from '../../types'
import { flattenFormikErrors, getFormikTouchedFieldsErrors } from '../../utils'
import getEventValidationSchema from '../../validation/events'
import * as S from './style'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import { FormInputDateTime } from '../../../../components/formik'
import Notifications from '../../../../components/Notifications/Notifications'

type FormValues = {
  comment: string
  quantity: number | null
  timestamp: Moment | null
}

type Props = {
  data?: Event
  handleClose?: Function
}

const DischargeEvent = ({ data, handleClose }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { values: report } = useFormikContext<HDCReportFormValues>()
  const {
    state: HDCState,
    imoNo,
    setEvents,
    setHasUnsavedEvent,
  } = useContext(HDCContext)
  const { eventTypes, form, report: HDCReport } = HDCState
  const [dirty, setDirty] = useState<boolean>(false)

  const eventType = useMemo(() => {
    return eventTypes?.find(
      (eventType) => eventType.name === EventTypeName.DISCHARGE_SLUDGE,
    )
  }, [eventTypes])

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<any>,
  ): Promise<any> => {
    if (!eventType?.id || !HDCState.form.type) {
      return displayErrorModal({
        statusText: 'Failed to proceed',
        message: 'Could not save event data, please try to refresh the page.',
      })
    }

    // The ! bangs are fine here as the formik validator will catch null values,
    // see `getEventValidationSchema`
    const event = {
      comment: values.comment || null,
      endTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      metadata: {
        quantity: values.quantity!,
      },
      source: HDCState.form.type,
      startTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      typeId: eventType.id,
    }

    try {
      let response: { event: Event }
      const subReport =
        form.type === HDCFormType.BRIDGE_FORM
          ? ESubReport.Bridge
          : ESubReport.EngineRoom
      if (data) {
        response = await editEvent(
          { ...event, id: data.id, typeName: data.typeName },
          imoNo,
          report.id,
          form.type!,
        )
        const events = (HDCReport?.[subReport].events || []).filter(
          ({ id }) => id !== data.id,
        )
        setEvents([...events, response.event], subReport)
        handleClose?.()
      } else {
        response = await postEvent(event, imoNo, report.id, HDCState.form.type)
        const events = HDCReport?.[subReport].events || []
        setEvents([...events, response.event], subReport)
        helpers.resetForm()
      }
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to save event',
        message:
          err?.body?.error || 'Could not save event data, please try again',
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const initialValues = data
    ? {
        comment: data.comment || '',
        quantity: data.metadata?.quantity || null,
        timestamp: moment(data.startTimestamp).utc(),
      }
    : {
        comment: '',
        quantity: null,
        timestamp: null,
      }

  useEffect(() => {
    if (data) {
      return
    }
    setHasUnsavedEvent(dirty)
  }, [data, dirty, setHasUnsavedEvent])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      validate={(values) => {
        try {
          void validateYupSchema(
            values,
            getEventValidationSchema(EventTypeName.DISCHARGE_SLUDGE),
            true,
            { report },
          )
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
    >
      {({ dirty, errors: formikErrors, isSubmitting, submitForm, touched }) => {
        const errors = getFormikTouchedFieldsErrors(formikErrors, touched)
        setDirty(dirty)
        return (
          <S.Wrapper>
            <S.Description>
              Please note that the reporting of the discharge of sludge to shore
              is for financial follow up shore side. This reporting will not
              impact the data in the stock module or the automatic calculation
              and deduction of sludge in the remaining onboard figures.
            </S.Description>
            <Notifications alerts={flattenFormikErrors(errors)} />
            <SimpleFormRow>
              <FormInputDateTime
                name='timestamp'
                label='Date and time, UTC'
                min={report.periodStart}
                max={report.periodEnd}
                openToDate={report.periodStart}
                minuteSpecific
                passThroughMoment
                hideErrorMessage
              />
            </SimpleFormRow>
            <SimpleFormRow>
              <HDCInputField
                name='quantity'
                addon={UNITS.VOLUME}
                label='Discharge quantity'
                type='number'
                isInvalid={!!errors.quantity}
              />
            </SimpleFormRow>
            <SimpleFormRow>
              <HDCTextareaField
                name='comment'
                label='Additional comments to the discharge event'
              />
            </SimpleFormRow>
            <S.ButtonWrapper>
              <McButton
                fit={windowSize}
                click={submitForm}
                type='button'
                disabled={isSubmitting}
              >
                {data ? 'Save changes' : 'Add Event'}
              </McButton>
            </S.ButtonWrapper>
            {isSubmitting && <OverlayLoader padding='0px' />}
          </S.Wrapper>
        )
      }}
    </Formik>
  )
}
export default DischargeEvent
