import { useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Form, Formik, FormikTouched, useFormikContext } from 'formik'
import { FormInputDateTime } from '../../../../components/formik'
import moment, { Moment } from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import styled, { info } from '../../../../theme'
import { InfoBox, Loading, ModalControls } from '../../../../commons'
import { FormContent, FormProgressBar } from '../../../../commons/'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import * as PerformanceAPI from '../../../../services/performance'
import {
  displayErrorModal,
  FuelLineType,
  FuelType,
  isShoreContext,
} from '../../../../utils'
import { BunkeringModalInitialRob } from './BunkeringModalInitialRob'
import { type FormBatch, type InitialRobBatch, StockState } from '../../models'
import { ManualRobTable } from './ManualRobTable'
import {
  generateDisplayName,
  mapBunkeringFormValuesToBatch,
  sortBatchesByTimestampDescending,
} from '../../utils'
import { hasAllRequiredBatchParams } from './utils'
import { type BunkeringFormValues } from '../bunkering/BunkeringForm.types'
import { FormConsumptionSelection } from '../form-consumption-selection/FormConsumptionSelection'
import { type RecoveryAndStartupCommonRobType } from '../form-consumption-selection/FormBatchSelection'

// React Joyride, Initial ROB Guide components
import { TourROB, TourSteps } from './tour-guide'
import { MM_STEPS } from './tour-guide/steps'

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

const TimeFormContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  z-index: 1000020;
`

export interface InitialRobFormValues extends RecoveryAndStartupCommonRobType {
  timestamp?: Moment
  consumption: {
    batchSelections: Performance.FuelOilStock.BatchSelectionResponse[]
    fuelTypeSelections: Performance.FuelOilStock.FuelTypeSelection[]
  }
}

const formSteps = ['Information', 'HS', 'VLS', 'ULS', 'MDO', 'Consumption']
const fuelTypes: Array<FuelType> = [
  FuelType.HS,
  FuelType.VLS,
  FuelType.ULS,
  FuelType.MDO,
]

const generateTempBatchId = () => uuidv4()

const mapStartupBatchToInitialRobBatch = (batch) => ({
  ...batch,
  id: generateTempBatchId(),
  rob: 0,
  displayName: generateDisplayName(
    batch.fuel.type,
    batch.fuel.isDistillate,
    `${batch.quantityPerChiefEngineer}`,
    batch.deliveryDetails.portCode,
    batch.timestamp,
  ),
  labReport: {
    ...batch.labReport,
  },
})

const mapStartupBatchesToInitialRobBatches = (
  batches: Performance.FuelOilStock.BunkeredBatchResponse[],
): InitialRobBatch[] =>
  batches
    .map(mapStartupBatchToInitialRobBatch)
    .sort(sortBatchesByTimestampDescending)

const mapInitialRobValuesToStartupRequest = (
  values: InitialRobFormValues,
  batches: InitialRobBatch[],
): Performance.FuelOilStock.Startup => {
  const { consumption, manualrob } = values
  const { batchSelections, fuelTypeSelections } = consumption
  const entries: Performance.FuelOilStock.StartupEntry[] = batches.map(
    (batch) => {
      const batchSelection = batchSelections.find((s) => s.id === batch.id)
      return {
        batch: batch as Performance.FuelOilStock.BunkeredBatch,
        rob: manualrob.rob[batch.id] || 0,
        selected: !!batchSelection,
      }
    },
  )
  return {
    robTimestamp: values.timestamp?.toISOString() ?? '',
    entries,
    fuelTypeSelections,
  }
}

/**
 * The function returns true if a batch has missing information,
 * the error should be shown only if the user has interacted with the ROB field
 * for that batch in the form and inputted a value greater than 0.
 */
export const hasBatchMissingInformation = (
  batch: InitialRobBatch,
  values: InitialRobFormValues,
  touched: FormikTouched<InitialRobFormValues>,
) =>
  !hasAllRequiredBatchParams(batch) &&
  values.manualrob.rob[batch.id] &&
  values.manualrob.rob[batch.id] > 0 && // Only show error if there is batch rob (i.e. > 0)
  touched.manualrob?.rob &&
  touched.manualrob?.rob[batch.id] // Only show error if user has interacted with field

type Props = {
  closeHandler: (redirect: boolean) => void
  setModalErrorMessage: (message: string) => void
  modalErrorMessage?: string
  stateOfStock: StockState
}

export const InitialRobForm = ({
  closeHandler,
  setModalErrorMessage,
  stateOfStock,
}: Props) => {
  const vesselPageContext = useContext(VesselPageContext)
  const { windowSize } = useContext(WindowContext)
  const _fit = windowSize === 'large' ? 'medium' : windowSize
  const imoNo = vesselPageContext.imoNo!
  const { configuration } = vesselPageContext
  const [currentStep, setCurrentStep] = useState(0)
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [batches, setBatches] = useState<InitialRobBatch[]>([])
  const [loadingEntries, setLoadingEntries] = useState(false)
  const [showBunkeringModal, setShowBunkeringModal] = useState(false)
  const [selectedBatch, setSelectedBatch] = useState<FormBatch>()
  const disabled = isShoreContext()

  const [initialValues, setInitialValues] = useState<InitialRobFormValues>()
  const [hasMissingInformationError, setHasMissingInformationError] =
    useState<boolean>()

  let noOfSteps = 5
  // When configuration.hasMainEngSecondaryFuelLine is present, then formSteps should have MM as a step
  if (configuration?.hasMainEngSecondaryFuelLine) {
    if (!formSteps.includes('MM') && !fuelTypes.includes(FuelType.MM)) {
      TourSteps.splice(TourSteps.length - 1, 0, MM_STEPS)
      formSteps.splice(formSteps.length - 1, 0, 'MM')
      fuelTypes.push(FuelType.MM)
    }
    noOfSteps = 6
  }

  // This component is used in the form inside formik. We need this since we want to decide whether to show the mode error message based on the ROB
  const ModalErrorDisplay = () => {
    const { values, touched } = useFormikContext<InitialRobFormValues>()
    useEffect(() => {
      const shouldDisplayModalError = batches.some((batch) =>
        hasBatchMissingInformation(batch, values, touched),
      )
      if (shouldDisplayModalError) {
        setHasMissingInformationError(true)
        setModalErrorMessage(
          'One or more batches are missing information. Please click on the marked batches to update them.',
        )
      } else {
        setModalErrorMessage('')
        setHasMissingInformationError(false)
      }
    }, [values, touched])
    return null
  }

  useEffect(() => {
    if (imoNo) {
      PerformanceAPI.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time of ROB interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo])

  const getBatchesForFuelType = useCallback(
    (fuelType: FuelType): InitialRobBatch[] =>
      batches.filter((batch) => batch.fuel.type === fuelType),
    [batches],
  )

  useEffect(() => {
    setLoadingEntries(true)
    PerformanceAPI.getStartupBatches(imoNo)
      .then((data: Performance.FuelOilStock.BunkeredBatchResponse[]) => {
        const batches = mapStartupBatchesToInitialRobBatches(data)
        if (data) {
          setBatches(batches)
        }
      })
      .catch((e) => {
        displayErrorModal(e)
      })
      .finally(() => setLoadingEntries(false))
  }, [imoNo])

  useEffect(() => {
    PerformanceAPI.getCurrentFuelTypeSelections(imoNo)
      .then((selections) => {
        const fuelTypeSelections: Performance.FuelOilStock.FuelTypeSelection[] =
          selections.map(({ fuelType, fuelLineType }) => ({
            fuelType,
            fuelLineType,
          }))
        // The MDO fuel lines cannot be set by user but must be sent to startup request,
        // we also need to check that the fuel line is present at the vessel by checking the vessel configuration
        ;[FuelLineType.AEMDO, FuelLineType.BoilerMDO].forEach(
          (fuelLineType) => {
            if (configuration?.fuelLineTypes.includes(fuelLineType)) {
              fuelTypeSelections.push({
                fuelType: FuelType.MDO,
                fuelLineType,
              })
            }
          },
        )

        setInitialValues({
          manualrob: {
            rob: {},
          },
          consumption: {
            batchSelections: [],
            fuelTypeSelections,
          },
        })
      })
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get current fuel type selections',
          message: e.message,
        }),
      )
  }, [imoNo, configuration])

  /**
   * React Joyride, Inital ROB Guide
   * this is needed out here to make the guide more interactive
   * and create a parent scope for click events and data sharing.
   */
  const CURRENT_TOUR_STEPS = TourSteps[0]

  const INITIAL_STATE = {
    key: new Date(), // This field makes the tour to re-render when we restart the tour
    run: false,
    continuous: true, // Show next button
    loading: false,
    stepIndex: 0, // Make the component controlled
    steps: CURRENT_TOUR_STEPS,
    disableOverlay: false,
    disableScrollParentFix: true,
  }

  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      // start the tour
      case 'START':
        return { ...state, run: true }
      // Reset to 0th step
      case 'RESET':
        return { ...state, stepIndex: 0 }
      // Stop the tour
      case 'STOP':
        return { ...state, run: false }
      // Toggle the background overlay
      case 'TOGGLE_OVERLAY':
        return { ...state, disableOverlay: !state.disableOverlay }
      // Update the steps for next / back button click
      case 'NEXT_OR_PREV':
        return { ...state, ...action.payload }
      // Change steps in between form step (Should triger a re-render)
      case 'CHANGE_STEPS':
        return {
          ...state,
          steps: TourSteps[currentStep],
          stepIndex: 0,
          run: true,
          loading: false,
          key: new Date(),
          disableOverlay: false,
        }
      default:
        return state
    }
  }
  const [tourState, tourDispatch] = useReducer(reducer, INITIAL_STATE)

  const putBatch = (
    bunkeringFormValues: BunkeringFormValues,
    batchId?: string,
  ) => {
    const newBatches = batches.filter((b) => b.id !== batchId)
    const id = batchId || generateTempBatchId()
    const newBatch = mapBunkeringFormValuesToBatch(bunkeringFormValues)
    const newInitialRobBatch: InitialRobBatch = {
      ...newBatch,
      id,
    }
    newBatches.push(newInitialRobBatch)
    newBatches.sort(sortBatchesByTimestampDescending)
    setBatches(newBatches)
    setShowBunkeringModal(false)
    setSelectedBatch(undefined)
  }

  const deleteBatch = (batchId: string) => {
    setBatches(batches.filter((batch) => batch.id !== batchId))
    setShowBunkeringModal(false)
  }

  const submitInitialRob = (
    values: InitialRobFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const startUpRequestBody = mapInitialRobValuesToStartupRequest(
      values,
      batches,
    )
    PerformanceAPI.postStockStartup(imoNo, startUpRequestBody)
      .then((response) => closeHandler(false))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  if (loadingEntries || !openWindow || !initialValues) {
    return <Loading />
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) =>
        submitInitialRob(values, setSubmitting)
      }
    >
      {({ isSubmitting, values, isValid, dirty, errors, setFieldValue }) => (
        <Form>
          <Wrapper>
            <TourROB
              tourState={tourState}
              dispatch={tourDispatch}
              currentInitialRobStep={currentStep}
            />
            <div className='joyride-rob-2'>
              <FormProgressBar currentStep={currentStep} steps={formSteps}>
                {values.timestamp && currentStep !== 0 && (
                  <InfoBox theme={info} boxMargin='0px 16px'>
                    Time of startup:{' '}
                    {values.timestamp.utc().format('DD MMM YYYY HH:mm [UTC]')}
                  </InfoBox>
                )}
                <FormContent show={currentStep === 0}>
                  <TimeFormContainer
                    className='joyride-rob-3'
                    onClick={() => {
                      !tourState.disableOverlay &&
                        tourDispatch({ type: 'TOGGLE_OVERLAY' })
                    }}
                  >
                    <FormInputDateTime
                      fit={_fit}
                      name='timestamp'
                      disabled={disabled}
                      label='Startup timestamp'
                      min={moment.utc(openWindow.period.from)}
                      max={moment.utc(openWindow.period.to)}
                      passThroughMoment
                      onChange={(value: Moment) => {
                        const rob = { ...values.manualrob.rob }
                        batches.forEach((batch) => {
                          if (
                            moment.utc(batch.timestamp).isSameOrAfter(value)
                          ) {
                            rob[batch.id] = batch.quantityPerChiefEngineer
                          }
                        })
                        void setFieldValue('rob', rob)
                        tourDispatch({
                          type: 'NEXT_OR_PREV',
                          payload: { stepIndex: tourState.stepIndex + 1 },
                        })
                      }}
                    />
                  </TimeFormContainer>
                </FormContent>
                {fuelTypes.map((fuelType, idx) => (
                  <FormContent
                    key={`fuelType-${fuelType}`}
                    show={currentStep === idx + 1}
                    className={`formcontent-${fuelType}`}
                  >
                    <ManualRobTable
                      fuelType={fuelType}
                      data={getBatchesForFuelType(fuelType)}
                      setSelectedBatch={setSelectedBatch}
                      setShowBunkeringModal={setShowBunkeringModal}
                      hideReactTour={() => tourDispatch({ type: 'STOP' })}
                    />
                  </FormContent>
                ))}
                <FormContent
                  show={currentStep === noOfSteps}
                  style={{ padding: '32px' }}
                >
                  <FormConsumptionSelection
                    stateOfStock={stateOfStock}
                    batches={batches.filter((batch) =>
                      moment
                        .utc(batch.timestamp)
                        .isSameOrBefore(values.timestamp),
                    )}
                  />
                </FormContent>
              </FormProgressBar>
            </div>
          </Wrapper>
          <ModalControls>
            <NavLink
              exact
              to={`/MaerskStarConnect/vessel/${imoNo}/overview`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <McButton
                label='Not now'
                appearance='neutral'
                className='left'
                variant='plain'
                click={() => closeHandler(true)}
                type='button'
              />
            </NavLink>

            {currentStep !== 0 && (
              <McButton
                label='Back'
                appearance='neutral'
                click={() => setCurrentStep(currentStep - 1)}
                type='button'
                tabIndex={100}
              />
            )}
            {currentStep < formSteps.length - 1 && (
              <McButton
                label='Next'
                appearance='primary'
                disabled={
                  !values.timestamp ||
                  (currentStep > 0 &&
                    (!dirty ||
                      // disable button if there is an error present on the rob fields in steps > 0
                      (errors?.manualrob &&
                        Object.keys(errors.manualrob).length > 0) ||
                      hasMissingInformationError))
                }
                click={() => setCurrentStep(currentStep + 1)}
                type='button'
                tabIndex={101}
              />
            )}
            {currentStep === formSteps.length - 1 && (
              <McButton
                label='Start stock'
                appearance='primary'
                disabled={isSubmitting || disabled || !isValid || !dirty}
                type='submit'
              />
            )}
          </ModalControls>
          {showBunkeringModal && (
            <BunkeringModalInitialRob
              batch={selectedBatch}
              batches={batches}
              submitBunkering={(
                bunkeringFormValues: BunkeringFormValues,
                batchId?: string,
              ) => {
                putBatch(bunkeringFormValues, batchId)
              }}
              deleteBunkering={deleteBatch}
              closeHandler={() => {
                setShowBunkeringModal(false)
                setSelectedBatch(undefined)

                // restart the tour when closing the modal
                tourDispatch({ type: 'START' })
              }}
            />
          )}
          <ModalErrorDisplay />
        </Form>
      )}
    </Formik>
  )
}
