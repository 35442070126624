import { FuelLineType, FuelType } from '../models'

export const isFuelTypePrimary = (type: FuelType) =>
  [FuelType.HS, FuelType.ULS, FuelType.MDO, FuelType.VLS].includes(type)

export const isFuelTypeDual = (type: FuelType) => [FuelType.MM].includes(type)

export const filterFuelTypeSelection = (
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[],
  fuelLineType: FuelLineType,
) => {
  return currentFuelTypeSelections.find(
    (selection) => selection.fuelLineType === fuelLineType,
  )
}

export const mapFuelLineTypeToHeaderText = (
  fuelType: FuelType,
  fuelLineType: FuelLineType,
  hasMainEngSecondaryFuelLine: boolean,
): string => {
  switch (fuelLineType) {
    case FuelLineType.MEMain:
    case FuelLineType.MESecondary:
      if (isFuelTypePrimary(fuelType)) return 'ME Main'
      if (isFuelTypeDual(fuelType)) return 'ME Dual'
      break
    case FuelLineType.AEMain:
    case FuelLineType.AESecondary:
      if (hasMainEngSecondaryFuelLine) {
        if (isFuelTypePrimary(fuelType)) return 'AE Main'
        if (isFuelTypeDual(fuelType)) return 'AE Dual'
      }
      return fuelLineType === FuelLineType.AEMain ? 'AE Main' : 'AE Secondary'
    case FuelLineType.AEMDO:
      return 'AE MDO'
    case FuelLineType.BoilerMDO:
      return 'Boiler MDO'
    case FuelLineType.BoilerMain:
      return 'Boiler Main'
    default:
      return 'Unknown'
  }

  return 'Unknown'
}

export const mapFuelLineTypeFuelTypeToName = (
  fuelLineType: FuelLineType,
  fuelType: FuelType,
  hasMainEngSecondaryFuelLine: boolean,
): string => {
  switch (fuelLineType) {
    case FuelLineType.MEMain:
    case FuelLineType.MESecondary:
      if (isFuelTypePrimary(fuelType)) return 'Main'
      if (isFuelTypeDual(fuelType)) return 'Dual'
      return 'Unknown'
    case FuelLineType.AEMain:
    case FuelLineType.AESecondary:
      if (hasMainEngSecondaryFuelLine) {
        if (isFuelTypePrimary(fuelType)) return 'Main'
        if (isFuelTypeDual(fuelType)) return 'Dual'
        return 'Unknown'
      }
      return fuelLineType === FuelLineType.AEMain ? 'Main' : 'Secondary'
    case FuelLineType.AEMDO:
    case FuelLineType.BoilerMDO:
      return 'MDO'
    case FuelLineType.BoilerMain:
      return 'Main'
    default:
      return 'Unknown'
  }
}

export const getFuelLineTypeSelectOptions = (
  fuelType: FuelType,
  fuelLineType: FuelLineType,
  hasMainEngSecondaryFuelLine: boolean,
): { label: string; value: FuelLineType }[] => {
  const createOption = (label: string, value: FuelLineType) => [
    { label, value },
  ]

  switch (fuelLineType) {
    case FuelLineType.MEMain:
    case FuelLineType.MESecondary:
      if (isFuelTypePrimary(fuelType))
        return createOption('ME Main', fuelLineType)
      if (isFuelTypeDual(fuelType)) return createOption('ME Dual', fuelLineType)
      break
    case FuelLineType.AEMain:
    case FuelLineType.AESecondary:
      if (hasMainEngSecondaryFuelLine) {
        if (isFuelTypePrimary(fuelType))
          return createOption('AE Main', fuelLineType)
        if (isFuelTypeDual(fuelType))
          return createOption('AE Dual', fuelLineType)
      }
      return createOption(
        fuelLineType === FuelLineType.AEMain ? 'AE Main' : 'AE Secondary',
        fuelLineType,
      )
    case FuelLineType.AEMDO:
      return createOption('AE MDO', FuelLineType.AEMDO)
    case FuelLineType.BoilerMDO:
      return createOption('Boiler MDO', FuelLineType.BoilerMDO)
    case FuelLineType.BoilerMain:
      return createOption('Boiler Main', FuelLineType.BoilerMain)
    default:
      return []
  }

  return []
}
