import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'

import {
  ApiReportType,
  OilCode,
  Batch,
} from '../../../../api-models/hdc/report'
import { type BatchResponse } from '../../../../api-models/performance/fuel-oil-stock'
import { Loading } from '../../../../commons'
import { FormSelect } from '../../../../components/formik'
import InputField from '../../../../components/form/input-field'
import FormSection from '../../../../components/form/section'
import { VesselPageContext } from '../../../../contexts'
import { getBatchesById } from '../../../../services/performance'
import { displayErrorModal } from '../../../../utils'
import { FUEL_LINE_TYPES, UNITS } from '../../../../utils/constants'

import { SimpleFormRow } from '../../components/form-row'
import { type FuelTypeGroup } from '../../components/fuel-consumption-table/types'
import { HdcFuelConsumptionTable } from '../../components/fuel-consumption-table/hdc-fuel-consumption'
import { mapFuelConsumptionData } from '../../components/fuel-consumption-table/mapping'
import HDCInputField from '../../components/hdc-input-field'
import LeakageAndSludge from '../../components/hdc-leakage-and-sludge'
import HDCTextareaField from '../../components/hdc-textarea-field'
import OverlayLoader from '../../components/overlay-loader'
import { HDCContext } from '../../context'
import { HDCReportFormValues } from '../../types'
import {
  flattenFormikErrors,
  getCLOConsumptionInKg,
  getCLOFeedRate,
  getFilteredByStepErrors,
  getFilteredByStepWarnings,
  mainEngineName,
} from '../../utils'
import Notifications from '../../../../components/Notifications/Notifications'

const OIL_CODE_OPTIONS = Object.values(OilCode).map((oilCode) => ({
  label: oilCode,
  value: oilCode,
}))

const FuelConsumptionStep = () => {
  const [fuelTypeGroups, setFuelTypeGroups] = useState<
    FuelTypeGroup[] | undefined
  >(undefined)
  const configuration = useContext(VesselPageContext).configuration!
  const { values, errors, isSubmitting } =
    useFormikContext<HDCReportFormValues>()
  const { mainEngines, fuelConsumption, cylindersLubeOil } = values.engineRoom
  const { state: HDCState } = useContext(HDCContext)
  const {
    form: { currentStep, steps, type },
    report,
  } = HDCState
  const { waterTemperature } = values.bridge
  const [isCloValidationMsgVisible, setIsCloValidationMsgVisible] =
    useState(false)
  const [newCloValidationMsg, setNewCloValidationMsg] = useState('')

  useEffect(() => {
    const createFuelTypeGroups = async () => {
      const batchIds = fuelConsumption.batches.map((batch) => batch.batchId)
      let papiBatches: BatchResponse[] = []
      let sulphurContent: number = 0

      try {
        papiBatches = await getBatchesById(`${configuration.imoNo}`, batchIds)
      } catch (error) {
        displayErrorModal(error)
      } finally {
        const groups: FuelTypeGroup[] = mapFuelConsumptionData(
          fuelConsumption.batches,
          papiBatches,
        )
        sulphurContent = getSulContOfMostConsumedFuel(
          fuelConsumption.batches,
          papiBatches,
        )
        showCloValidationAndInfoMsg(sulphurContent)
        setFuelTypeGroups(groups)
      }
    }

    if (fuelTypeGroups !== undefined || !fuelConsumption.batches) {
      return
    } else {
      createFuelTypeGroups()
    }
  }, [fuelTypeGroups, configuration.imoNo, fuelConsumption.batches])

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  const getSulContOfMostConsumedFuel = (
    batches: Batch[],
    papiBatches: BatchResponse[],
  ): number => {
    let highestConsumedMassFuel = 0
    let fuelConsumedForSelectedBatch = 0
    let highestConsumedMassFuelBatch: Batch
    let highestConsumedMassFuelSulphur = 0
    if (
      !batches ||
      batches.length === 0 ||
      !papiBatches ||
      papiBatches.length === 0
    ) {
      return highestConsumedMassFuelSulphur
    }
    batches.forEach((batch) => {
      batch.fuelLines.forEach((fuelLine) => {
        // Only consider the fuelLine 10, i.e. MainEngine consumption
        if (fuelLine.fuelLine === FUEL_LINE_TYPES.ME_MAIN) {
          fuelConsumedForSelectedBatch = +fuelLine.consumedMass
        }
      })
      if (fuelConsumedForSelectedBatch > highestConsumedMassFuel) {
        highestConsumedMassFuel = fuelConsumedForSelectedBatch
        highestConsumedMassFuelBatch = batch
      }
    })
    // Get the sulphur content of the highest consumed fuelType
    const selectedBatch = papiBatches.filter((papiBatch) => {
      return papiBatch.id === highestConsumedMassFuelBatch?.batchId
    })
    if (
      selectedBatch.length > 0 &&
      selectedBatch[0].hasOwnProperty('sulphur')
    ) {
      let selectedBatchData = selectedBatch[0]
      highestConsumedMassFuelSulphur = selectedBatchData['sulphur']
    }
    return highestConsumedMassFuelSulphur
  }

  const showCloValidationAndInfoMsg = (sulphurContent: number) => {
    if (sulphurContent === 0) {
      return
    }
    if (sulphurContent > 1.5 && values.reportType !== ApiReportType.ALONGSIDE) {
      if (waterTemperature.value! > 28) {
        setIsCloValidationMsgVisible(true)
        setNewCloValidationMsg(
          `Water temperature(T) is ${waterTemperature.value}°C. The CLO feed rate must be increased by 10%, since the temperature is above 28°C and Sulphur content is above 1.5%`,
        )
      }
      if (waterTemperature.value === null) {
        setIsCloValidationMsgVisible(true)
        setNewCloValidationMsg(
          `Water temperature(T) is null. In case T>28°C, the CLO feed rate must be increased by 10%, and the validation would be different, since Sulphur content is above 1.5%`,
        )
      }
    }
  }

  return (
    <>
      <Notifications
        alerts={flattenFormikErrors(
          getFilteredByStepErrors(errors, type!, steps[currentStep]),
        )}
        warnings={[...new Set(Object.values(warnings))]}
        advices={isCloValidationMsgVisible ? [newCloValidationMsg] : []}
      />
      <FormSection
        header='Fuel consumption'
        description='Please evaluate and confirm your fuel consumption and ROB for the report period. If you see any unexpected quantities please go the Fuel Consumption page and validate/correct the flowmeter data on the individual consumers.'
      >
        <>
          {!fuelTypeGroups ? (
            <Loading />
          ) : (
            <HdcFuelConsumptionTable fuelTypeGroups={fuelTypeGroups} />
          )}
          <LeakageAndSludge
            leakageMass={fuelConsumption.leakageMass}
            sludge={fuelConsumption.sludge}
          />
        </>
      </FormSection>
      <FormSection
        header='Cylinder oil consumption'
        description='Enter your cylinder oil data for the report period. If you do not have flowmeter installed, please share and align measuring process with your colleagues in order to have most consistent estimate in the daily reports.'
      >
        <FieldArray
          name='engineRoom.cylindersLubeOil'
          render={() =>
            cylindersLubeOil.map((cylinder, idx) => (
              <React.Fragment key={`cylinder-${idx + 1}`}>
                <h4 className='mds-headline--x-small'>
                  {mainEngineName(
                    values.engineRoom.mainEngines.length,
                    cylinder.number,
                  )}
                </h4>
                <SimpleFormRow>
                  <HDCInputField
                    decimals={2}
                    hasWarning={
                      !!warnings[
                        `engineRoom.cylindersLubeOil.${idx}.consumptionLiters`
                      ]
                    }
                    label={`Cylinder oil consumption [${UNITS.LITER}]`}
                    name={`engineRoom.cylindersLubeOil.${idx}.consumptionLiters.value`}
                    placeholder='Cylinder oil consumption'
                    type='number'
                  />
                  <HDCInputField
                    decimals={2}
                    hasWarning={
                      !!warnings[`engineRoom.cylindersLubeOil.${idx}.density`]
                    }
                    label={`Density [${UNITS.DENSITY_FIFTEEN}]`}
                    name={`engineRoom.cylindersLubeOil.${idx}.density.value`}
                    placeholder='Density'
                    type='number'
                  />
                  <HDCInputField
                    decimals={2}
                    hasWarning={
                      !!warnings[
                        `engineRoom.cylindersLubeOil.${idx}.avgTemperature`
                      ]
                    }
                    label={`Average temperature [${UNITS.TEMPERATURE}]`}
                    name={`engineRoom.cylindersLubeOil.${idx}.avgTemperature.value`}
                    placeholder='Average temperature'
                    type='number'
                  />
                  <HDCInputField
                    hasWarning={
                      !!warnings[
                        `engineRoom.cylindersLubeOil.${idx}.totalBaseNumber`
                      ]
                    }
                    label='Total base number'
                    name={`engineRoom.cylindersLubeOil.${idx}.totalBaseNumber.value`}
                    placeholder='Total Base Number'
                    type='number'
                  />
                  <FormSelect
                    name={`engineRoom.cylindersLubeOil.${idx}.oilCode.value`}
                    id={`engineRoom.cylindersLubeOil.${idx}.oilCode.value`}
                    label='Oil code'
                    placeholder='Select oil code'
                    options={OIL_CODE_OPTIONS}
                    hideErrorMessage
                  />
                  <InputField
                    value={getCLOConsumptionInKg(
                      cylindersLubeOil[idx].consumptionLiters.value || 0,
                      cylindersLubeOil[idx].avgTemperature.value || 0,
                      cylindersLubeOil[idx].density.value || 0,
                    )}
                    label={`Calculated cylinder oil consumption [${UNITS.KILOGRAM}]`}
                    disabled
                  />
                  {values.reportType !== ApiReportType.ALONGSIDE && (
                    <InputField
                      value={getCLOFeedRate(
                        getCLOConsumptionInKg(
                          cylindersLubeOil[idx].consumptionLiters.value || 0,
                          cylindersLubeOil[idx].avgTemperature.value || 0,
                          cylindersLubeOil[idx].density.value || 0,
                        ),
                        mainEngines[idx].averagePower.value || 0,
                        mainEngines[idx].runTime,
                      )}
                      label={`Calculated CLO feed rate [${UNITS.GRAM_KILOWATT_HOUR}]`}
                      disabled
                      isInvalid={
                        !!getIn(
                          errors,
                          `engineRoom.cylindersLubeOil.${idx}.cloFeedRate.value`,
                        ) ||
                        !!warnings[
                          `engineRoom.cylindersLubeOil.feedRate.engineRoom.cylindersLubeOil.${idx.toString()}.feedRate`
                        ]
                      }
                    />
                  )}
                </SimpleFormRow>
              </React.Fragment>
            ))
          }
        />
      </FormSection>

      <FormSection header='Report comments'>
        <SimpleFormRow>
          <HDCTextareaField
            name='engineRoom.comment'
            label='Additional reporting comments'
          />
        </SimpleFormRow>
      </FormSection>
      {isSubmitting && <OverlayLoader padding='0px' />}
    </>
  )
}

export default FuelConsumptionStep
