import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Formik,
  type FormikHelpers,
  getIn,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import moment, { type Moment } from 'moment'
import { McButton, McCheckbox } from '@maersk-global/mds-react-wrapper'

import HDCContext from '../../context/context'
import { WindowContext } from '../../../../contexts'
import { type Event } from '../../../../api-models/hdc/events'
import { ApiReportType } from '../../../../api-models/hdc/report'
import { editEvent, postEvent } from '../../../../services/hdc'
import { UNITS } from '../../../../utils/constants'
import { displayErrorModal } from '../../../../utils'
import { SimpleFormRow } from '../../components/form-row'
import OverlayLoader from '../../components/overlay-loader'
import {
  ESubReport,
  EventTypeName,
  HDCFormType,
  type HDCReportFormValues,
} from '../../types'
import { flattenFormikErrors, getFormikTouchedFieldsErrors } from '../../utils'
import getEventValidationSchema from '../../validation/events'
import * as S from './style'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import InputField from '../../../../components/form/input-field'
import { FormInputDateTime, FormTypeahead } from '../../../../components/formik'
import Notifications from '../../../../components/Notifications/Notifications'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../reports.utils'

interface IFormValues {
  alternativeArrivalTerminal: {
    code: string
    name: string
  }
  arrivalTerminal: {
    code: string
    name: string
  }
  comment: string
  remainingDistanceToPilotStation: number | null
  timestamp: Moment | null
  useCustomArrival: boolean
}

type Props = {
  data?: Event
  handleClose?: Function
}

const RouteChangeEvent = ({ data, handleClose }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { values: report, setFieldValue } =
    useFormikContext<HDCReportFormValues>()
  const {
    state: HDCState,
    imoNo,
    getReport,
    setEvents,
    setFormUseCustomArrival,
    setHasUnsavedEvent,
  } = useContext(HDCContext)
  const { eventTypes, form, report: HDCReport, reportType } = HDCState
  const [dirty, setDirty] = useState<boolean>(false)

  const eventType = useMemo(() => {
    return eventTypes?.find(
      (eventType) => eventType.name === EventTypeName.ROUTE_CHANGE,
    )
  }, [eventTypes])

  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []
    return terminals.filter((t) => t.data.isActive).map(getTerminalOption)
  }, [terminals, isTerminalsSuccess])

  const handleCustomArrivalToggle = (useCustomArrival, setFieldValue) => {
    setFieldValue('arrivalTerminal', { code: '', name: '' })
    setFieldValue('alternativeArrivalTerminal', { code: '', name: '' })
    setFieldValue('useCustomArrival', !useCustomArrival)
  }

  const handleSubmit = async (
    values: IFormValues,
    helpers: FormikHelpers<any>,
  ): Promise<void> => {
    if (!eventType?.id || !HDCState.form.type) {
      return displayErrorModal({
        statusText: 'Failed to proceed',
        message: 'Could not save event data, please try to refresh the page.',
      })
    }

    // The ! bangs are fine here as the formik validator will catch null values
    // see `getEventValidationSchema`
    const event = {
      comment: values.comment || null,
      endTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      metadata: {
        alternativeArrivalTerminal: values.alternativeArrivalTerminal.code
          ? values.alternativeArrivalTerminal
          : null,
        arrivalTerminal: values.arrivalTerminal.code
          ? values.arrivalTerminal
          : null,
        remainingDistanceToPilotStation: values.remainingDistanceToPilotStation,
      },
      source: HDCState.form.type,
      startTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      typeId: eventType.id,
    }

    try {
      let response: { event: Event }
      const subReport =
        form.type === HDCFormType.BRIDGE_FORM
          ? ESubReport.Bridge
          : ESubReport.EngineRoom
      if (data) {
        response = await editEvent(
          { ...event, id: data.id, typeName: data.typeName },
          imoNo,
          report.id,
          form.type!,
        )
        const events = (HDCReport?.[subReport].events || []).filter(
          ({ id }) => id !== data.id,
        )
        setEvents([...events, response.event], subReport)
        setFieldValue(`${subReport}.events`, [...events, response.event])
        handleClose?.()
      } else {
        response = await postEvent(event, imoNo, report.id, HDCState.form.type)
        const events = HDCReport?.[subReport].events || []
        setFieldValue(`${subReport}.events`, [...events, response.event])
        helpers.resetForm()
      }

      // fetch report to get updated Destination and Remaining distance to PS
      const updatedReport = await getReport(report.id)
      if (!updatedReport.bridge) {
        throw new Error(
          'Failed to update arrival port and remaining distance to PS on Voyage step',
        )
      }
      setFieldValue(
        'bridge.arrivalTerminal',
        updatedReport.bridge.arrivalTerminal,
      )
      setFieldValue(
        'bridge.alternativeArrivalTerminal',
        updatedReport.bridge.alternativeArrivalTerminal,
      )
      setFormUseCustomArrival(!!updatedReport.bridge.alternativeArrivalTerminal)
      setFieldValue(
        'bridge.remainingDistanceToPilotStation',
        updatedReport.bridge.remainingDistanceToPilotStation,
      )
    } catch (err) {
      void displayErrorModal({
        statusText: err.message || 'Failed to save event',
        message:
          err?.body?.error || 'Could not save event data, please try again',
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const initialValues = data
    ? {
        alternativeArrivalTerminal: {
          code: data.metadata?.alternativeArrivalTerminal?.code || '',
          name: data.metadata?.alternativeArrivalTerminal?.name || '',
        },
        arrivalTerminal: {
          code: data.metadata?.arrivalTerminal?.code || '',
          name: data.metadata?.arrivalTerminal?.name || '',
        },
        comment: data.comment || '',
        remainingDistanceToPilotStation:
          data.metadata?.remainingDistanceToPilotStation || null,
        timestamp: moment(data.startTimestamp).utc(),
        useCustomArrival: !!data.metadata?.alternativeArrivalTerminal?.code,
      }
    : {
        alternativeArrivalTerminal: {
          code: '',
          name: '',
        },
        arrivalTerminal: {
          code: '',
          name: '',
        },
        comment: '',
        remainingDistanceToPilotStation: null,
        timestamp: null,
        useCustomArrival: false,
      }

  useEffect(() => {
    if (data) {
      return
    }
    setHasUnsavedEvent(dirty)
  }, [data, dirty, setHasUnsavedEvent])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      validate={(values) => {
        try {
          void validateYupSchema(
            values,
            getEventValidationSchema(EventTypeName.ROUTE_CHANGE),
            true,
            { report },
          )
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
    >
      {({
        dirty,
        errors: formikErrors,
        isSubmitting,
        setFieldValue,
        submitForm,
        touched,
        values,
      }) => {
        const errors = getFormikTouchedFieldsErrors(formikErrors, touched)
        setDirty(dirty)
        return (
          <S.Wrapper>
            <S.Description>
              If either your Arrival port or Route has changed in this report
              period, please enter date and time for the change and the updated
              Remaining distance to pilot station and/or the new Arrival port.
              This will update the information in the Voyage tab.
            </S.Description>
            <Notifications alerts={flattenFormikErrors(errors)} />
            <SimpleFormRow>
              <FormInputDateTime
                name='timestamp'
                label='Route change date and time, UTC'
                min={report.periodStart}
                max={report.periodEnd}
                openToDate={report.periodStart}
                minuteSpecific
                passThroughMoment
                hideErrorMessage
              />
            </SimpleFormRow>
            <SimpleFormRow>
              {values.useCustomArrival ? (
                <>
                  <HDCInputField
                    error={getIn(errors, 'alternativeArrivalTerminal')}
                    label='Arrival port code'
                    name='alternativeArrivalTerminal.code'
                    showSensorValue={false}
                  />
                  <HDCInputField
                    error={getIn(errors, 'alternativeArrivalTerminal')}
                    label='Arrival port name'
                    name='alternativeArrivalTerminal.name'
                    showSensorValue={false}
                  />
                </>
              ) : (
                <>
                  <FormTypeahead
                    name='arrivalTerminal.name'
                    id='arrivalTerminal.name'
                    label='Arrival port code'
                    error={getIn(errors, 'arrivalTerminal')}
                    options={activeTerminalOptions}
                    placeholder={
                      getIn(values, 'arrivalTerminal.code') || 'Type to search'
                    }
                    onChange={(portCode) => {
                      const terminal = terminals?.find(
                        (t) => t.data.code === portCode,
                      )
                      if (!terminal) return
                      setFieldValue(
                        'arrivalTerminal.code',
                        portCode as string,
                        !!getIn(errors, 'arrivalTerminal'),
                      )
                      setFieldValue(
                        'arrivalTerminal.name',
                        terminal.data.city.name,
                        !!getIn(errors, 'arrivalTerminal'),
                      )
                    }}
                  />
                  <InputField
                    label='Arrival port name'
                    value={values.arrivalTerminal.name}
                    disabled
                  />
                </>
              )}
              <McCheckbox
                fit={windowSize}
                label='Arrival port not in list'
                checked={values.useCustomArrival}
                change={() =>
                  handleCustomArrivalToggle(
                    values.useCustomArrival,
                    setFieldValue,
                  )
                }
              />
            </SimpleFormRow>
            {reportType !== ApiReportType.ARRIVAL && (
              <SimpleFormRow>
                <HDCInputField
                  name='remainingDistanceToPilotStation'
                  label='Remaining distance to PS at closing time of this report'
                  type='number'
                  decimals={2}
                  addon={UNITS.NAUTICAL_MILE}
                  isInvalid={!!errors.remainingDistanceToPilotStation}
                  showSensorValue={false}
                />
              </SimpleFormRow>
            )}
            <SimpleFormRow>
              <HDCTextareaField
                name='comment'
                label='Additional comments to the route change event'
              />
            </SimpleFormRow>
            <S.ButtonWrapper>
              <McButton
                fit={windowSize}
                click={submitForm}
                type='button'
                disabled={isSubmitting}
              >
                {data ? 'Save changes' : 'Add Event'}
              </McButton>
            </S.ButtonWrapper>
            {isSubmitting && <OverlayLoader padding='0px' />}
          </S.Wrapper>
        )
      }}
    </Formik>
  )
}
export default RouteChangeEvent
