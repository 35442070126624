import { createColumnHelper } from '@tanstack/react-table'

import { formatValue } from '../../../../utils'
import {
  NumericCell,
  NumericHeader,
} from '../../../../components/Table/Table.styles'
import { type IHdcFuelConsumptionTableData } from './types'
import {
  filterFuelTypeSelection,
  mapFuelLineTypeToHeaderText,
} from '../../../../utils/mappers/FuelLineMappers.utils'

const columnHelper = createColumnHelper<IHdcFuelConsumptionTableData>()

export const getColumns = (
  fuelLineTypes: Array<number>,
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[],
  hasMainEngSecondaryFuelLine: boolean,
) => [
  columnHelper.accessor('fuelType', {
    header: 'Fuel',
    cell: (props) => (
      <span
        style={{ fontWeight: props.row.getCanExpand() ? 'bold' : 'normal' }}
      >
        {props.getValue()}
      </span>
    ),
    footer: 'Total',
    enableSorting: false,
  }),
  ...fuelLineTypes.map((fuelLineType) => {
    const matchingFuelTypeSelection = filterFuelTypeSelection(
      currentFuelTypeSelections,
      fuelLineType,
    )
    return columnHelper.display({
      id: fuelLineType.toString(),
      header: () => (
        <NumericHeader>
          {mapFuelLineTypeToHeaderText(
            matchingFuelTypeSelection?.fuelType ?? 0,
            matchingFuelTypeSelection?.fuelLineType ?? 0,
            hasMainEngSecondaryFuelLine,
          )}{' '}
          (MT)
        </NumericHeader>
      ),
      cell: ({ row }) => (
        <NumericCell>
          {formatValue(row.original[fuelLineType] || null, 3)}
        </NumericCell>
      ),
      footer: ({ table }) => {
        const total = table
          .getCoreRowModel()
          .rows.reduce(
            (acc, curr) => acc + (curr.original[fuelLineType] ?? 0),
            0,
          )

        return <NumericCell>{formatValue(total || null, 3)}</NumericCell>
      },
      enableSorting: false,
    })
  }),
  columnHelper.accessor('total', {
    header: () => <NumericHeader>Total (MT)</NumericHeader>,
    cell: (props) => (
      <NumericCell>{formatValue(props.getValue() || null, 3)}</NumericCell>
    ),
    footer: ({ table }) => {
      const total = table
        .getCoreRowModel()
        .rows.reduce((acc, curr) => acc + (curr.original.total ?? 0), 0)

      return <NumericCell>{formatValue(total || null, 3)}</NumericCell>
    },
    enableSorting: false,
  }),
  columnHelper.accessor('rob', {
    header: () => <NumericHeader>ROB (MT)</NumericHeader>,
    cell: (props) => (
      <NumericCell>{formatValue(props.getValue() || null, 3)}</NumericCell>
    ),
    footer: ({ table }) => {
      const robTotal = table
        .getCoreRowModel()
        .rows.reduce((acc, curr) => acc + (curr.original.rob ?? 0), 0)

      return <NumericCell>{formatValue(robTotal || null, 3)}</NumericCell>
    },
    enableSorting: false,
  }),
]
