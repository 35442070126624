import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { type FieldProps } from 'formik'

import { FuelLineType, FuelType } from '../../../../utils'
import { Performance } from '../../../../api-models'
import { FormFuelLineChangeModal } from './FormFuelLineChangeModal'
import { FuelConsumers } from '../../../fdl-4/components/consumer-selection/FuelConsumers'
import { StockState } from '../../models'

const ConsumersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const SelectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  justify-content: left;
`

type Props = FieldProps & {
  uniqueFuelTypesOnboard: Array<FuelType>
  hasMainEngSecondaryFuelLine: boolean
  stockState: StockState
}

export const FormFuelTypeSelection = ({
  field,
  form,
  uniqueFuelTypesOnboard,
  hasMainEngSecondaryFuelLine,
  stockState,
}: Props) => {
  const [selectedFuelLine, setSelectedFuelLine] = useState<number>()
  const [showFuelLineChangeModal, setShowFuelLineChangeModal] = useState(false)
  const [uniqueFuelTypes, setUniqueFuelTypes] = useState<Array<FuelType>>([])

  const addFuelTypeSelection = useCallback(
    (fuelType: FuelType) => {
      const newFuelTypeSelections = field.value.filter(
        (selection) => selection.fuelLineType !== selectedFuelLine,
      )
      if (selectedFuelLine)
        newFuelTypeSelections.push({
          fuelType,
          fuelLineType: selectedFuelLine,
        })
      // Push fuelType as MM for vessels with Main Engine Secondary Fuel Line
      if (hasMainEngSecondaryFuelLine && fuelType !== FuelType.MM) {
        switch (selectedFuelLine) {
          case FuelLineType.MEMain:
            if (
              !newFuelTypeSelections.find(
                (selection) =>
                  selection.fuelLineType === FuelLineType.MESecondary,
              )
            ) {
              newFuelTypeSelections.push({
                fuelType: FuelType.MM,
                fuelLineType: FuelLineType.MESecondary,
              })
            }
            break
          case FuelLineType.MESecondary:
            if (
              !newFuelTypeSelections.find(
                (selection) => selection.fuelLineType === FuelLineType.MEMain,
              )
            ) {
              newFuelTypeSelections.push({
                fuelType: FuelType.MM,
                fuelLineType: FuelLineType.MEMain,
              })
            }
            break
          case FuelLineType.AEMain:
            if (
              !newFuelTypeSelections.find(
                (selection) =>
                  selection.fuelLineType === FuelLineType.AESecondary,
              )
            ) {
              newFuelTypeSelections.push({
                fuelType: FuelType.MM,
                fuelLineType: FuelLineType.AESecondary,
              })
            }
            break
          case FuelLineType.AESecondary:
            if (
              !newFuelTypeSelections.find(
                (selection) => selection.fuelLineType === FuelLineType.AEMain,
              )
            ) {
              newFuelTypeSelections.push({
                fuelType: FuelType.MM,
                fuelLineType: FuelLineType.AEMain,
              })
            }
            break
          default:
            break
        }
      }

      void form.setFieldValue(field.name, newFuelTypeSelections)
      setShowFuelLineChangeModal(false)
    },
    [field.name, field.value, form, selectedFuelLine],
  )

  const getUniqueFuelTypes = (
    hasMainEngSecondaryFuelLine: boolean,
    fuelLineType: number,
    uniqueFuelTypesOnboard: any,
  ) => {
    let existingFuelTypes
    let excludeMM = false
    if (hasMainEngSecondaryFuelLine) {
      switch (fuelLineType) {
        case FuelLineType.MEMain:
        case FuelLineType.MESecondary:
          existingFuelTypes = field.value?.filter(
            (item) =>
              item.fuelLineType === FuelLineType.MEMain ||
              item.fuelLineType === FuelLineType.MESecondary,
          )
          break
        case FuelLineType.AEMain:
        case FuelLineType.AESecondary:
          existingFuelTypes = field.value?.filter(
            (item) =>
              item.fuelLineType === FuelLineType.AEMain ||
              item.fuelLineType === FuelLineType.AESecondary,
          )
          break
        default:
          break
      }
    }

    if (
      existingFuelTypes?.length > 0 ||
      fuelLineType === FuelLineType.BoilerMain
    ) {
      excludeMM = true
    }
    if (excludeMM) {
      return uniqueFuelTypesOnboard.filter(
        (fuelType) => fuelType !== FuelType.MM,
      )
    }

    return uniqueFuelTypesOnboard
  }

  const openFuelLineChangeModal = useCallback(
    (
      fuelLine:
        | Performance.Status.FuelLine
        | Performance.Status.MainEngFuelLine,
    ) => {
      const { fuelLineType } = fuelLine
      const uniqueFuelTypes = getUniqueFuelTypes(
        hasMainEngSecondaryFuelLine,
        fuelLineType,
        uniqueFuelTypesOnboard,
      )
      setUniqueFuelTypes(uniqueFuelTypes)

      setSelectedFuelLine(fuelLineType)
      setShowFuelLineChangeModal(true)
    },
    [setSelectedFuelLine, setShowFuelLineChangeModal, setUniqueFuelTypes],
  )

  const closeFuelLineChangeModal = useCallback(() => {
    setShowFuelLineChangeModal(false)
    void form.setFieldTouched(field.name)
  }, [field.name, form])

  return (
    <>
      <SelectionTitle>Consumers</SelectionTitle>
      <ConsumersWrapper>
        <FuelConsumers
          onClick={openFuelLineChangeModal}
          fuelTypeSelections={field.value}
          stockState={stockState}
        />
      </ConsumersWrapper>
      {showFuelLineChangeModal && selectedFuelLine && (
        <FormFuelLineChangeModal
          currentlySelectedFuelType={field.value?.find(
            (selection) => selection.fuelLineType === selectedFuelLine,
          )}
          uniqueFuelTypesOnboard={uniqueFuelTypes}
          fuelLineType={selectedFuelLine}
          closeHandler={closeFuelLineChangeModal}
          onSave={addFuelTypeSelection}
        />
      )}
    </>
  )
}
