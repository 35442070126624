import { useCallback, useContext, useState } from 'react'
import * as echarts from 'echarts'
import { EChartOption, init } from 'echarts'
import moment from 'moment'

import { Performance } from '../../../api-models'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import { ToggleInFuelConsumptionChart } from '../models/fuel-consumption'
import styled, { grey } from '../../../theme'
import { FUEL_TYPE_NAMES, tooltip } from '../../../utils'
import {
  EntryType,
  FUEL_STOCK_ENTRY_TYPE_NAMES,
  UserEntryTypes,
} from '../../stock-management/models'
import {
  DIM_BATCH_ID,
  DIM_BATCH_NAME,
  DIM_BATCH_TYPE,
  DIM_END_TIME,
  DIM_ERROR_MESSAGE,
  DIM_FROM_BATCH_NAME,
  DIM_FROM_BATCH_TYPE,
  DIM_FUEL_LINE_TYPE,
  DIM_FUEL_TYPE,
  DIM_INDICATOR_TYPE,
  DIM_START_TIME,
  DIM_TO_BATCH_NAME,
  DIM_TO_BATCH_TYPE,
  DIMENSION_NAMES,
} from '../models/batch-monitor'
import { getElementStyles, truncateLabel } from '../utils/batch-monitor'
import { StockEntryContext } from '../../../contexts'
import { BatchIndicatorType } from '../../../queries/PerformanceApi/Papi.consts'
import {
  filterFuelTypeSelection,
  mapFuelLineTypeFuelTypeToName,
} from '../../../utils/mappers/FuelLineMappers.utils'

const Wrapper = styled.div`
  .tooltip {
    border-spacing: 4px;
    border-collapse: collapse;

    td,
    th {
      padding-left: 12px;
      text-align: right;

      &:first-child {
        padding-left: 0;
      }

      &:first-child,
      &:last-child {
        text-align: left;
      }
    }

    .right-aligned {
      width: 100%;
      display: block;
      text-align: right;
    }

    tr:first-of-type {
      td {
        padding-top: 4px;
      }
    }

    thead {
      tr:first-of-type {
        th {
          text-align: center;
          padding-bottom: 4px;
        }
      }

      tr:last-of-type {
        border-bottom: 1px solid #fff;
      }
    }
  }
`

const HEIGHT_RATIO = 0.8
const LABEL_WIDTH_PADDING = 40
const BAR_HEIGHT = 25

type Props = {
  id: string
  queryPeriod: Performance.Common.Period
  batchSeries: any
  toggleInChart: ToggleInFuelConsumptionChart
  setChartRef: (chart: echarts.ECharts) => void
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
  hasMainEngSecondaryFuelLine: boolean
}

export const BatchMonitorChart = ({
  id,
  queryPeriod,
  toggleInChart,
  batchSeries,
  setChartRef,
  currentFuelTypeSelections,
  hasMainEngSecondaryFuelLine,
}: Props) => {
  const [chart, setChart]: [Chart | undefined, Function] = useState()
  const { setCurrentEntry } = useContext(StockEntryContext)

  const getOptions = useCallback((): EChartOption | any => {
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: moment.utc(queryPeriod.from).valueOf(),
        max: moment.utc(queryPeriod.to).valueOf(),
        show: false,
      },
      yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        data: batchSeries.fuelLines
          .sort()
          .map((line) => {
            const matchingFuelTypeSelection = filterFuelTypeSelection(
              currentFuelTypeSelections,
              +line,
            )
            return mapFuelLineTypeFuelTypeToName(
              matchingFuelTypeSelection?.fuelLineType ?? 0,
              matchingFuelTypeSelection?.fuelType ?? 0,
              hasMainEngSecondaryFuelLine,
            )
          })
          .reverse(),
        position: 'right',
        splitLine: { show: false },
        axisPointer: {
          show: false,
        },
      },
      toolbox: {
        ...TOOLBOX_TIMELINE,
      },
      tooltip: {
        axisPointer: {
          show: false,
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
      },
      dataZoom: [{ ...DATA_ZOOM_TIMELINE, filterMode: 'none' }],
      grid: {
        ...GRID,
        containLabel: false,
        show: true,
        top: 0,
        bottom: 0,
        right: 70,
        height: BAR_HEIGHT * batchSeries.fuelLines.length,
        backgroundColor: '#fff',
        borderWidth: 0,
        left: 20,
      },
      series: [
        {
          id: 'BatchMonitor',
          type: 'custom',
          label: { fontSize: 10 },
          tooltip: {
            formatter: (params) => {
              const data = params.data
              const indicatorType = data[DIM_INDICATOR_TYPE]
              const startTime = data[DIM_START_TIME]

              if (indicatorType === BatchIndicatorType.Error) {
                return tooltip(
                  startTime,
                  [{ label: data[DIM_ERROR_MESSAGE] }],
                  [{ label: 'Error' }],
                )
              }

              if (indicatorType === UserEntryTypes.BunkeredBatch) {
                const displayName = data[DIM_BATCH_NAME]
                const fuelType = data[DIM_FUEL_TYPE]
                const columnHeaders = [
                  {
                    label: 'Batch info',
                  },
                ]
                const tooltips = [
                  {
                    label: displayName,
                  },
                  {
                    label: FUEL_TYPE_NAMES[fuelType],
                  },
                ]
                return tooltip(startTime, tooltips, columnHeaders)
              } else if (indicatorType === UserEntryTypes.MixedBatch) {
                const fromBatchName = data[DIM_FROM_BATCH_NAME]
                const fromBatchFuelType = data[DIM_FUEL_TYPE]
                const fromBatchType = data[DIM_FROM_BATCH_TYPE]

                const toBatchName = data[DIM_TO_BATCH_NAME]
                const toBatchFuelType = data[DIM_FUEL_TYPE]
                const toBatchType = data[DIM_TO_BATCH_TYPE]

                const columnHeaders = [
                  {
                    from: 'From batch',
                    to: 'To batch',
                  },
                ]

                const tooltips = [
                  {
                    from: fromBatchName,
                    to: toBatchName,
                  },
                  {
                    from: FUEL_TYPE_NAMES[fromBatchFuelType],
                    to: FUEL_TYPE_NAMES[toBatchFuelType],
                  },
                  {
                    from: FUEL_STOCK_ENTRY_TYPE_NAMES[fromBatchType],
                    to: FUEL_STOCK_ENTRY_TYPE_NAMES[toBatchType],
                  },
                ]
                return tooltip(startTime, tooltips, columnHeaders)
              } else {
                return null
              }
            },
          },
          renderItem: (params, api) => {
            const categoryIndex = api.value(DIM_FUEL_LINE_TYPE)
            const startTime = api.coord([
              api.value(DIM_START_TIME),
              categoryIndex,
            ])
            const endTime = api.coord([api.value(DIM_END_TIME), categoryIndex])

            const barLength = endTime[0] - startTime[0]
            const barHeight = api.size([0, 1])[1] * HEIGHT_RATIO
            const x = startTime[0]
            const y = startTime[1] - barHeight / 2

            const label = api.value(DIM_BATCH_NAME) + ''
            const batchId = api.value(DIM_BATCH_ID)
            const indicatorType = api.value(DIM_INDICATOR_TYPE)
            const batchType = api.value(DIM_BATCH_TYPE)
            const fuelType = api.value(DIM_FUEL_TYPE)
            const text = truncateLabel(x, label, barLength, LABEL_WIDTH_PADDING)

            const rectText = clipRectByRect(params, {
              x: x,
              y: y,
              width: barLength,
              height: barHeight,
            })

            const elementStyles = getElementStyles(indicatorType, fuelType)

            const isVisible =
              rectText &&
              (indicatorType === BatchIndicatorType.Error ||
                indicatorType === BatchIndicatorType.Blend ||
                (toggleInChart[FUEL_TYPE_NAMES[fuelType]] &&
                  indicatorType === BatchIndicatorType.Batch))

            return {
              type: 'rect',
              ignore: !isVisible,
              z2: indicatorType === BatchIndicatorType.Batch ? 1 : 0,
              shape: rectText,
              info:
                indicatorType === BatchIndicatorType.Batch
                  ? batchType === 10
                    ? { entryId: batchId, entryType: EntryType.Batch }
                    : { entryId: batchId, entryType: EntryType.MixedBatch }
                  : '',
              style: api.style({
                ...elementStyles,
                text: text,
              }),
            }
          },
          dimensions: DIMENSION_NAMES,
          encode: {
            x: [DIM_START_TIME, DIM_END_TIME],
            y: DIM_FUEL_LINE_TYPE,
            tooltip: [
              DIM_FUEL_LINE_TYPE,
              DIM_START_TIME,
              DIM_END_TIME,
              DIM_BATCH_NAME,
              DIM_INDICATOR_TYPE,
              DIM_FUEL_TYPE,
              DIM_FROM_BATCH_NAME,
              DIM_TO_BATCH_NAME,
            ],
          },
          data: batchSeries.indicators,
        },
      ],
    }
  }, [queryPeriod, toggleInChart, batchSeries])

  const refCallback = useCallback(
    (chartNode) => {
      if (chartNode !== null) {
        const chart = init(chartNode) as Chart
        chart.setOption(getOptions())
        chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
        chart.group = 'fuel-consumption-group'

        chart.on('click', function (params) {
          if (params.info) {
            setCurrentEntry(params.info.entryType, params.info.entryId)
          }
        })

        chart.on('mousemove', (params) => {
          const { info } = params
          if (info) {
            chart.getZr().setCursorStyle('pointer')
          } else {
            chart.getZr().setCursorStyle('default')
          }
        })
        setChart(chart)
        const chartRef = echarts.getInstanceByDom(chartNode)
        setChartRef(chartRef)
      }
    },
    [getOptions, setChartRef, setCurrentEntry],
  )

  const zoomOut = () => {
    const { from, to } = queryPeriod
    chart?.dispatchAction({
      type: 'dataZoom',
      batch: [
        {
          startValue: moment.utc(from).valueOf(),
          endValue: moment.utc(to).valueOf(),
        },
      ],
    })
  }

  const clipRectByRect = (params, rect) => {
    return echarts.graphic.clipRectByRect(rect, {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    })
  }

  if (
    !batchSeries ||
    !batchSeries.fuelLines ||
    batchSeries.fuelLines.length === 0 ||
    currentFuelTypeSelections.length === 0
  )
    return null
  return (
    <Wrapper>
      <div
        style={{
          minHeight: BAR_HEIGHT * batchSeries.fuelLines.length,
          paddingRight: '66px',
        }}
        onDoubleClick={() => zoomOut()}
        id={id}
        ref={refCallback}
      />
    </Wrapper>
  )
}
