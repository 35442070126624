export enum FuelType {
  HS = 1,
  ULS = 2,
  MDO = 3,
  VLS = 4,
  MM = 10,
}

export enum FuelTypes {
  HS = 1,
  ULS = 2,
  MDO = 4,
  VLS = 8,
  MM = 16,
}

export const FUEL_TYPES_FDL4 = {
  [FuelType.HS]: 1,
  [FuelType.ULS]: 2,
  [FuelType.MDO]: 3,
  [FuelType.VLS]: 4,
  [FuelType.MM]: 10,
}

export const FUEL_TYPE_NAMES = {
  [FuelType.HS]: 'HS',
  [FuelType.ULS]: 'ULS',
  [FuelType.MDO]: 'MDO',
  [FuelType.VLS]: 'VLS',
  [FuelType.MM]: 'MM',
  [`${FuelTypes.HS + FuelTypes.MM}`]: 'HS+MM',
  [`${FuelTypes.ULS + FuelTypes.MM}`]: 'ULS+MM',
  [`${FuelTypes.MDO + FuelTypes.MM}`]: 'MDO+MM',
  [`${FuelTypes.VLS + FuelTypes.MM}`]: 'VLS+MM',
}

export const FUEL_TYPE_WASTE = 10

export enum FuelLineType {
  MEMain = 10,
  MESecondary = 11,
  AEMain = 20, //fuellineType +FuelType = 20+10 =Dual , 21 + 1/2/3/4 =Main
  AESecondary = 21,
  AEMDO = 25,
  BoilerMain = 30,
  BoilerMDO = 35,
}

export const FUEL_LINE_TO_CONSUMER = {
  [FuelLineType.MEMain]: 'ME',
  [FuelLineType.MESecondary]: 'ME',
  [FuelLineType.AEMain]: 'AE',
  [FuelLineType.AESecondary]: 'AE',
  [FuelLineType.AEMDO]: 'AE',
  [FuelLineType.BoilerMain]: 'Boiler',
  [FuelLineType.BoilerMDO]: 'Boiler',
}

export const FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING = {
  [FuelLineType.MEMain]: 'ME Main',
  [FuelLineType.MESecondary]: 'ME Secondary',
  [FuelLineType.AEMain]: 'AE Main',
  [FuelLineType.AESecondary]: 'AE Secondary',
  [FuelLineType.AEMDO]: 'AE MDO',
  [FuelLineType.BoilerMain]: 'Boiler Main',
  [FuelLineType.BoilerMDO]: 'Boiler MDO',
}

export const FUEL_LINE_TYPE_SELECT_OPTIONS = [
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.MEMain],
    value: FuelLineType.MEMain,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.MESecondary],
    value: FuelLineType.MESecondary,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.AEMain],
    value: FuelLineType.AEMain,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.AESecondary],
    value: FuelLineType.AESecondary,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.AEMDO],
    value: FuelLineType.AEMDO,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.BoilerMain],
    value: FuelLineType.BoilerMain,
  },
  {
    label: FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[FuelLineType.BoilerMDO],
    value: FuelLineType.BoilerMDO,
  },
]
