import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import { ModelData, initChart, updateChart, TestData } from './Charts.utils'
import { resolveOperatingCondition } from '../../MetcReportPage.utils'
import {
  ChartsWrapper,
  ChartWrapper,
} from '../../../EngineTestReportPage.styles'

const TC_PSCAV_CHART_ID = 'tc-pscav-chart'
const TC_SPEED_CHART_ID = 'tc-speed-chart'
const TC_INLET_TEMP_CHART_ID = 'tc-inlet-temp-chart'

type Props = {
  isActive: boolean
  general: GandalfApi.Metc.General
  equipment: GandalfApi.Metc.Equipment
  result: GandalfApi.Metc.Result
  dataFormat: number
}

const TcEfficiencyCharts = ({
  isActive,
  general,
  equipment,
  result,
  dataFormat,
}: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const pscavChartRef = useRef<EChartsType>()
  const speedChartRef = useRef<EChartsType>()
  const inletTempChartRef = useRef<EChartsType>()

  const operatingCondition = resolveOperatingCondition(
    dataFormat,
    general,
    equipment,
  )

  const setPscavData = () => {
    if (!operatingCondition) {
      return
    }

    const modelData: Array<ModelData> = [
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.scavAirPres,
        name: 'Pscav shop test',
      },
    ]
    const testData: Array<TestData> = [
      {
        x: result.general.engineLoad,
        y: result.tcEfficiency.scavAirPres.isoConditionAdjustedValue,
        name: 'Pscav test (ISO)',
      },
    ]

    updateChart(modelData, testData, pscavChartRef.current)
  }

  const setTcSpeedData = () => {
    if (!operatingCondition) {
      return
    }

    const modelData: Array<ModelData> = [
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.tcSpeed,
        name: 'TC speed shop test',
      },
    ]
    const testData: Array<TestData> = [
      {
        x: result.general.engineLoad,
        y: result.tcEfficiency.tcSpeed.isoConditionAdjustedValue,
        name: 'TC speed test (ISO)',
      },
    ]

    updateChart(modelData, testData, speedChartRef.current)
  }

  const setInletTempData = () => {
    if (!operatingCondition) {
      return
    }

    const modelData: Array<ModelData> = [
      {
        x: operatingCondition.referenceCondition.load,
        y: operatingCondition.isoCondition.turbineInletTemp,
        name: 'TC inlet temp shop test',
      },
    ]
    const testData: Array<TestData> = [
      {
        x: result.general.engineLoad,
        y: result.tcEfficiency.tempBeforeTc.isoConditionAdjustedValue,
        name: 'TC inlet temp test (ISO)',
      },
    ]

    updateChart(modelData, testData, inletTempChartRef.current)
  }

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      pscavChartRef.current = initChart({
        id: TC_PSCAV_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Pscav',
        yUnit: 'bar',
      })
      setPscavData()

      speedChartRef.current = initChart({
        id: TC_SPEED_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Turbocharger speed',
        yUnit: 'RPM',
      })
      setTcSpeedData()

      inletTempChartRef.current = initChart({
        id: TC_INLET_TEMP_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Turbocharger inlet temp',
        yUnit: '°C',
      })
      setInletTempData()
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) {
      return
    }

    setPscavData()
    setTcSpeedData()
    setInletTempData()
  }, [result.adjustedIsoConditions])

  return (
    <ChartsWrapper>
      <ChartWrapper id={TC_PSCAV_CHART_ID} />
      <ChartWrapper id={TC_SPEED_CHART_ID} />
      <ChartWrapper id={TC_INLET_TEMP_CHART_ID} />
    </ChartsWrapper>
  )
}

export default TcEfficiencyCharts
