import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Formik,
  type FormikErrors,
  type FormikHelpers,
  getIn,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import moment, { type Moment } from 'moment'
import { McButton, McCheckbox, McRadio } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import HDCContext from '../../context/context'
import { type Event, type EventType } from '../../../../api-models/hdc/events'
import { getEventTypeOptions } from './helpers'
import { editEvent, postEvent } from '../../../../services/hdc'
import { maerskBlue } from '../../../../theme'
import { displayErrorModal } from '../../../../utils'
import { SimpleFormRow } from '../../components/form-row'
import Accordion from '../../components/off-service-accordion'
import OverlayLoader from '../../components/overlay-loader'
import {
  ESubReport,
  EventTypeName,
  HDCFormType,
  type HDCReportFormValues,
} from '../../types'
import {
  flattenFormikErrors,
  getFormikTouchedFieldsErrors,
  isEventGroup,
} from '../../utils'
import getEventValidationSchema from '../../validation/events'
import * as S from './style'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import InputField from '../../../../components/form/input-field'
import { FormInputDateTime, FormTypeahead } from '../../../../components/formik'
import Notifications from '../../../../components/Notifications/Notifications'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../reports.utils'

interface IFormValues {
  comment: string
  endTimestamp: Moment | null
  port: {
    code: string
    name: string
  }
  startTimestamp: Moment | null
  timeLoss: {
    days: number
    hours: number
    minutes: number
  }
  typeId: number | undefined
  useCustomPort: boolean
}

type TSetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean,
) => void

type Props = {
  data?: Event
  handleClose?: Function
  type:
    | EventTypeName.OPERATIONAL_OFF_SERVICE
    | EventTypeName.TECHNICAL_OFF_SERVICE
}

const OffServiceEvent = ({ data, handleClose, type }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { values: report } = useFormikContext<HDCReportFormValues>()
  const {
    imoNo,
    setEvents,
    setHasUnsavedEvent,
    state: HDCState,
  } = useContext(HDCContext)
  const { eventTypes, form, report: HDCReport } = HDCState

  const [dirty, setDirty] = useState<boolean>(false)
  const [selectedPath, setSelectedPath] = useState<number[]>([])

  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []
    return terminals.filter((t) => t.data.isActive).map(getTerminalOption)
  }, [terminals, isTerminalsSuccess])

  const offServiceEvent = useMemo(
    (): EventType | undefined =>
      eventTypes?.find((eventType) => eventType.name === type),
    [eventTypes, type],
  )

  const accordionEvents = (
    values: IFormValues,
    setFieldValue: TSetFieldValue,
  ) => {
    if (!offServiceEvent?.eventTypes) {
      return null
    }

    const recursiveAccordion = (
      eventType: EventType,
      idx: number,
      remainingPath: number[],
    ) => {
      if (isEventGroup(eventType)) {
        const isActive = !!remainingPath.length && idx === remainingPath[0]
        const activeKey = isActive ? remainingPath.shift() : null
        return {
          header: eventType.name,
          content: (
            <Accordion
              selectedActiveKey={remainingPath.length ? activeKey : undefined}
              collapsables={eventType.eventTypes!.map((et, idx) =>
                recursiveAccordion(et, idx, remainingPath),
              )}
            />
          ),
        }
      } else {
        return {
          content: (
            <S.RadioButtonWrapper key={eventType.id}>
              <McRadio
                label={eventType.name}
                checked={eventType.id === values.typeId}
                change={() =>
                  handleReasonSelectAccordion(
                    eventType.id!,
                    eventType.name,
                    setFieldValue,
                  )
                }
              />
            </S.RadioButtonWrapper>
          ),
        }
      }
    }
    const remainingPath = [...selectedPath]
    const activeKey = remainingPath.shift()

    return (
      <Accordion
        selectedActiveKey={remainingPath.length ? activeKey : undefined}
        collapsables={offServiceEvent.eventTypes.map((et, idx) => {
          const isActive = activeKey === idx
          const nextActiveKey = isActive ? remainingPath.shift() : null
          return {
            header: et.name,
            inactiveColor: maerskBlue[600],
            activeColor: maerskBlue[600],
            content: (
              <Accordion
                selectedActiveKey={
                  remainingPath.length ? nextActiveKey : undefined
                }
                collapsables={et.eventTypes!.map((et, idx) =>
                  recursiveAccordion(et, idx, isActive ? remainingPath : []),
                )}
              />
            ),
          }
        })}
      />
    )
  }

  const getTimeLoss = (
    timeLossInMinutes: number,
  ): { days: number; hours: number; minutes: number } => {
    const days = Math.floor(timeLossInMinutes / (60 * 24))
    const remainder = timeLossInMinutes % (60 * 24)
    const hours = Math.floor(remainder / 60)
    const minutes = remainder % 60
    return { days, hours, minutes }
  }

  const handleCustomPortToggle = (
    useCustomPort: boolean,
    setFieldValue: TSetFieldValue,
  ) => {
    setFieldValue('port', { code: '', name: '' })
    setFieldValue('useCustomPort', !useCustomPort)
  }

  const handleReasonSelectAccordion = (
    id: number,
    name: string,
    setFieldValue: TSetFieldValue,
  ) => {
    setFieldValue('typeId', id)
  }

  const handleSubmit = async (
    values: IFormValues,
    helpers: FormikHelpers<any>,
  ): Promise<void> => {
    if (!HDCState.form.type) {
      return displayErrorModal({
        statusText: 'Failed to proceed',
        message: 'Could not save event data, please try to refresh the page.',
      })
    }

    const timeLossInMinutes =
      values.timeLoss.days * 24 * 60 +
      values.timeLoss.hours * 60 +
      values.timeLoss.minutes

    // The ! bangs are fine here as the formik validator will catch null values
    // See `getEventValidationSchema`
    const event = {
      comment: values.comment || null,
      endTimestamp: values.endTimestamp
        ? moment(values.endTimestamp).toISOString()
        : '',
      metadata: {
        ...(type === EventTypeName.OPERATIONAL_OFF_SERVICE && {
          port: values.port,
        }),
        timeLoss: timeLossInMinutes,
      },
      source: HDCState.form.type,
      startTimestamp: values.startTimestamp
        ? moment(values.startTimestamp).toISOString()
        : '',
      typeId: values.typeId!,
    }

    const resetForm = () => {
      helpers.resetForm()
      setSelectedPath([])
    }

    try {
      let response: { event: Event }
      const subReport =
        form.type === HDCFormType.BRIDGE_FORM
          ? ESubReport.Bridge
          : ESubReport.EngineRoom
      if (data) {
        response = await editEvent(
          { ...event, id: data.id, typeName: data.typeName },
          imoNo,
          report.id,
          form.type!,
        )
        const events = (HDCReport?.[subReport].events || []).filter(
          ({ id }) => id !== data.id,
        )
        setEvents([...events, response.event], subReport)
        handleClose?.()
      } else {
        response = await postEvent(event, imoNo, report.id, HDCState.form.type)
        const events = HDCReport?.[subReport].events || []
        setEvents([...events, response.event], subReport)
        resetForm()
      }
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to save event',
        message:
          err?.body?.error || 'Could not save event data, please try again',
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const handleTimestampChange = (
    startTimestamp: Moment | string | null,
    endTimestamp: Moment | string | null,
    setFieldValue: TSetFieldValue,
  ) => {
    if (!startTimestamp || !endTimestamp) return

    if (typeof startTimestamp === 'string') {
      startTimestamp = moment(startTimestamp)
    }

    if (typeof endTimestamp === 'string') {
      endTimestamp = moment(endTimestamp)
    }

    const duration = moment.duration(endTimestamp.diff(startTimestamp))
    const days = parseInt(`${duration.asDays()}`)
    duration.subtract(moment.duration(days, 'days'))
    const hours = parseInt(`${duration.asHours()}`)
    duration.subtract(moment.duration(hours, 'hours'))
    const minutes = parseInt(`${duration.asMinutes() % 60}`)
    setFieldValue('timeLoss', { days, hours, minutes })
  }

  const initialValues = data
    ? {
        comment: data.comment || '',
        endTimestamp: moment(data.endTimestamp).utc(),
        port: {
          code: data.metadata?.port?.code || '',
          name: data.metadata?.port?.name || '',
        },
        startTimestamp: moment(data.startTimestamp).utc(),
        timeLoss: getTimeLoss(data.metadata!.timeLoss!),
        typeId: data.typeId,
        useCustomPort: !terminals?.find(
          (terminal) => terminal.data.code === data.metadata?.port?.code,
        ),
      }
    : {
        comment: '',
        endTimestamp: null,
        port: {
          code: '',
          name: '',
        },
        startTimestamp: null,
        timeLoss: {
          days: 0,
          hours: 0,
          minutes: 0,
        },
        typeId: undefined,
        useCustomPort: false,
      }

  const renderPort = (
    values: IFormValues,
    errors: FormikErrors<IFormValues>,
    setFieldValue: TSetFieldValue,
  ) => {
    return (
      <SimpleFormRow>
        {(values.useCustomPort && (
          <>
            <HDCInputField
              error={getIn(errors, 'port')}
              label='Port code'
              name='port.code'
              showSensorValue={false}
            />
            <HDCInputField
              error={getIn(errors, 'port')}
              label='Port name'
              name='port.name'
              showSensorValue={false}
            />
          </>
        )) || (
          <>
            <FormTypeahead
              name='port.name'
              id='port.name'
              label='Port code'
              error={getIn(errors, 'port')}
              options={activeTerminalOptions}
              placeholder={getIn(values, 'port.code') || 'Type to search'}
              onChange={(portCode) => {
                const terminal = terminals?.find(
                  (t) => t.data.code === portCode,
                )
                if (!terminal) return
                setFieldValue(
                  'port.name',
                  terminal.data.city.name,
                  !!getIn(errors, 'port'),
                )
                setFieldValue(
                  'port.code',
                  portCode as string,
                  !!getIn(errors, 'port'),
                )
              }}
            />
            <InputField label='Port name' value={values.port.name} disabled />
          </>
        )}
        <McCheckbox
          fit={windowSize}
          label='Port not in list'
          checked={values.useCustomPort}
          change={() =>
            handleCustomPortToggle(values.useCustomPort, setFieldValue)
          }
        />
      </SimpleFormRow>
    )
  }

  // typeIdToPath holds event type id as key and array of indices representing path to that id in offServiceEvent object as value;
  // it allows to open accordion all the way down to the selected event type, when chosen from the search bar
  const typeIdToPath = useMemo((): Map<number, number[]> => {
    const result = new Map()

    if (!offServiceEvent?.eventTypes) {
      return result
    }

    const recursiveTraverse = (
      eventType: EventType,
      path: number[],
      resultMap: Map<number, number[]>,
    ) => {
      if (isEventGroup(eventType)) {
        eventType.eventTypes!.forEach((et, idx) => {
          recursiveTraverse(et, [...path, idx], resultMap)
        })
      } else {
        resultMap.set(eventType.id!, path)
      }
    }

    offServiceEvent.eventTypes.forEach((eventType, idx) => {
      recursiveTraverse(eventType, [idx], result)
    })

    return result
  }, [offServiceEvent])

  useEffect(() => {
    if (data) {
      return
    }
    setHasUnsavedEvent(dirty)
  }, [data, dirty, setHasUnsavedEvent])

  useEffect(() => {
    if (!data) {
      return
    }
    setSelectedPath(typeIdToPath.get(data.typeId) || [])
  }, [data, typeIdToPath])

  const eventTypeOptions = useMemo(() => {
    if (!offServiceEvent?.eventTypes) return []
    return getEventTypeOptions(offServiceEvent.eventTypes)
  }, [offServiceEvent])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      validate={(values) => {
        try {
          void validateYupSchema(values, getEventValidationSchema(type), true, {
            report,
          })
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
    >
      {({
        dirty,
        errors: formikErrors,
        isSubmitting,
        setFieldValue,
        submitForm,
        touched,
        values,
      }) => {
        const errors = getFormikTouchedFieldsErrors(formikErrors, touched)
        setDirty(dirty)
        return (
          <S.Wrapper>
            <S.Description>
              Search or select via the buttons the correct off-service reason
              you need to report.
            </S.Description>
            <Notifications alerts={flattenFormikErrors(errors)} />
            <S.FormWrapper>
              <S.FormColumn>
                <S.FormColumnField>
                  <FormTypeahead
                    name='typeId'
                    id='typeId'
                    label='Reason for off-service'
                    error={getIn(errors, 'typeId')}
                    options={eventTypeOptions}
                    placeholder='Type to search'
                    hideErrorMessage
                    isNumericFieldValue
                    onChange={(typeIdStr) => {
                      const typeIdNum = parseInt(typeIdStr, 10)
                      setFieldValue('typeId', typeIdNum)
                      setSelectedPath(typeIdToPath?.get(typeIdNum) || [])
                    }}
                  />
                </S.FormColumnField>
                <S.FormColumnField>
                  <FormInputDateTime
                    name='startTimestamp'
                    label='Start date and time, UTC'
                    min={report.periodStart}
                    max={report.periodEnd}
                    openToDate={report.periodStart}
                    minuteSpecific
                    passThroughMoment
                    hideErrorMessage
                    onChange={(event) =>
                      handleTimestampChange(
                        event,
                        values.endTimestamp,
                        setFieldValue,
                      )
                    }
                  />
                </S.FormColumnField>
                <S.FormColumnField>
                  <FormInputDateTime
                    name='endTimestamp'
                    label='End date and time, UTC'
                    min={report.periodStart}
                    max={report.periodEnd}
                    openToDate={report.periodEnd}
                    minuteSpecific
                    passThroughMoment
                    hideErrorMessage
                    onChange={(event) =>
                      handleTimestampChange(
                        values.startTimestamp,
                        event,
                        setFieldValue,
                      )
                    }
                  />
                </S.FormColumnField>
                <SimpleFormRow>
                  {Object.keys(values.timeLoss).map((key) => (
                    <HDCInputField
                      isInvalid={!!errors.timeLoss}
                      key={`timeLoss.${key}`}
                      label={`Time loss - ${key}`}
                      name={`timeLoss.${key}`}
                      style={{ width: '100%' }}
                      type='number'
                      showSensorValue={false}
                    />
                  ))}
                </SimpleFormRow>
                {type === EventTypeName.OPERATIONAL_OFF_SERVICE &&
                  renderPort(values, errors, setFieldValue)}
                <S.FormColumnField>
                  <HDCTextareaField
                    name='comment'
                    label='Comment to be shared with technical management'
                  />
                </S.FormColumnField>
              </S.FormColumn>
              <S.FormColumn>
                {accordionEvents(values, setFieldValue)}
              </S.FormColumn>
            </S.FormWrapper>
            <S.ButtonWrapper>
              <McButton
                fit={windowSize}
                disabled={isSubmitting}
                click={submitForm}
                type='button'
              >
                {data ? 'Save changes' : 'Add Event'}
              </McButton>
            </S.ButtonWrapper>
            {isSubmitting && <OverlayLoader padding='0px' />}
          </S.Wrapper>
        )
      }}
    </Formik>
  )
}
export default OffServiceEvent
