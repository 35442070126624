import { McButton } from '@maersk-global/mds-react-wrapper'

import type { Performance } from '../../../../api-models'
import {
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
  FuelLineType,
  FuelType,
} from '../../../../utils'
import styled, {
  FuelColors,
  type IColorScheme,
  lg,
  md,
  media,
  sm,
  whiteColorScheme,
} from '../../../../theme'
import { mapFuelLineTypeToHeaderText } from '../../../../utils/mappers/FuelLineMappers.utils'
import { StockState } from '../../../stock-management/models'

const ConsumerCard = styled.div<{ colorScheme: IColorScheme }>`
  border: 1px solid ${({ colorScheme }) => colorScheme.stroke};
  border-radius: 4px;
  background: ${({ colorScheme }) => colorScheme.fill};
  ${media.sm`padding: ${sm.CONTENT_CARD_HEADER_PADDING}`}
  ${media.lg`padding: ${lg.CONTENT_CARD_HEADER_PADDING}`}
  ${media.md`padding: ${md.CONTENT_CARD_HEADER_PADDING}`}
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;

  > mc-button {
    margin-top: auto;
  }
`

const FuelTypeIndicator = styled.div<{ colorScheme: IColorScheme }>`
  margin-bottom: 8px;
  font-size: 2em;
  color: ${({ colorScheme }) => colorScheme.stroke};
`

type Props = {
  stockState: StockState
  fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine
  hasMainEngSecondaryFuelLine: boolean
  onClick: (
    fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine,
  ) => void
}

export const FuelConsumerCard = ({
  stockState,
  fuelLine,
  hasMainEngSecondaryFuelLine,
  onClick,
}: Props) => {
  const { fuelType, fuelLineType } = fuelLine

  const colorScheme =
    fuelType && fuelType > 0 ? FuelColors[FuelType[fuelType]] : whiteColorScheme

  // Mark the fuel line to be labelled as automatically selected when
  //  - the fuel line is a MEMain/MESecondary/AEMain/AESecondary fuel line and the fuel type is MM
  //  - the fuel line is an AEMDO/BoilerMDO fuel line
  const automaticallySelected =
    (hasMainEngSecondaryFuelLine &&
      (fuelLineType === FuelLineType.MEMain ||
        fuelLineType === FuelLineType.MESecondary ||
        fuelLineType === FuelLineType.AEMain ||
        fuelLineType === FuelLineType.AESecondary) &&
      fuelType === FuelType.MM) ||
    fuelLineType === FuelLineType.AEMDO ||
    fuelLineType === FuelLineType.BoilerMDO

  const handleClick = (event) => {
    if (!automaticallySelected) {
      event.preventDefault()
      event.stopPropagation()
      onClick(fuelLine)
    }
  }

  return (
    <ConsumerCard colorScheme={colorScheme}>
      <p>
        {stockState === StockState.STARTUP
          ? FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType]
          : mapFuelLineTypeToHeaderText(
              fuelType,
              fuelLineType,
              hasMainEngSecondaryFuelLine,
            )}
      </p>
      <FuelTypeIndicator colorScheme={colorScheme}>
        {FuelType[fuelType] ? FuelType[fuelType] : 'N/A'}
      </FuelTypeIndicator>
      <McButton
        fit='small'
        appearance='neutral'
        width='full-width'
        trailingicon={!automaticallySelected ? 'arrows-left-right' : undefined}
        click={handleClick}
        disabled={automaticallySelected}
      >
        {fuelType !== -1
          ? automaticallySelected
            ? 'Automatically selected'
            : 'Change fuel type'
          : 'Select fuel type'}
      </McButton>
    </ConsumerCard>
  )
}
