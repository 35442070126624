import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Block,
  ChartContainer,
  ChartStencil,
  FancyLabel,
  VesselStatusBlock,
} from '../../../commons'
import { LegendDropdown } from './LegendDropdown'
import {
  getConsumptionSeries,
  mapDensity15Series,
  mapFuelTypeToColorScheme,
} from '../mappers'
import { UNITS } from '../../../utils/constants'
// import { displayErrorModal, FuelLineType, FuelType } from '../../../utils'
import { displayErrorModal, FuelType } from '../../../utils'
import { whiteColorScheme } from '../../../theme'
import { FuelConsumptionBlock } from './FuelConsumptionBlock'
import {
  Density15SeriesModel,
  FuelConsumptionModel,
  ToggleInFuelConsumptionChart,
} from '../models/fuel-consumption'
import { BatchMonitorChart } from '../charts/BatchMonitorChart'
import { CorrectionsChart } from '../charts/CorrectionsChart'
import { Performance } from '../../../api-models'
import { FuelConsumptionChart } from '../charts/FuelConsumptionChart'
import { BatchMonitorSeriesMapped } from '../models/batch-monitor'
import { ECharts } from 'echarts'
import { convertToFuelLineUnit } from '../utils/fuel-consumption'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'
import { FuelConsumptionContext, VesselPageContext } from '../../../contexts'
import FuelConsumptionLosses from '../corrections/LossesAccordion'
import FuelConsumptionCorrections from '../corrections/CorrectionsAccordion'
import * as S from './FuelConsumptionChartWrapper.styles'
import ExclamationCircle from '../../../components/icons/exclamation-circle'

export interface Density15TogglesModel {
  [fuelLineType: number]: boolean
}

interface FuelConsumptionChartContainerProps {
  batchMonitorSeries: BatchMonitorSeriesMapped
  fuelConsumption: FuelConsumptionModel
  hasTwoMainEngines: boolean
  id: string
  mixingPeriodSeries: Array<Array<number | null>>
  queryPeriod: Performance.Common.Period | undefined
  setChartRef: React.Dispatch<React.SetStateAction<ECharts | undefined>>
  setConsumptionChartRef: React.Dispatch<
    React.SetStateAction<ECharts | undefined>
  >
  setMonitorChartRef: React.Dispatch<React.SetStateAction<ECharts | undefined>>
  unit: string
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
}

const getFuelTypeFromName = (type: string) => {
  switch (type) {
    case 'HS':
      return FuelType.HS
    case 'VLS':
      return FuelType.VLS
    case 'ULS':
      return FuelType.ULS
    case 'MDO':
      return FuelType.MDO
    case 'MM':
      return FuelType.MM
    default:
      return 0
  }
}

export const FuelConsumptionChartWrapper: FunctionComponent<
  FuelConsumptionChartContainerProps
> = ({
  batchMonitorSeries,
  fuelConsumption,
  hasTwoMainEngines,
  id,
  mixingPeriodSeries,
  queryPeriod,
  setChartRef,
  setConsumptionChartRef,
  setMonitorChartRef,
  unit,
  currentFuelTypeSelections,
}) => {
  const { imoNo, consumption, refreshFuelConsumption } = useContext(
    FuelConsumptionContext,
  )
  const configuration = useContext(VesselPageContext).configuration!
  const { losses, overwrites, repairs } = consumption
  const { status, dataFuelLines, timestamps } = fuelConsumption
  const [toggleInChart, setToggleInChart]: [
    ToggleInFuelConsumptionChart,
    Function,
  ] = useState({
    HS: true,
    VLS: true,
    ULS: true,
    MDO: true,
    MM: true,
  })

  const [isDataLossAccordionOpen, setIsDataLossAccordionOpen] = useState(false)

  const isMainEngine = id === 'fc-me-charts'

  const [density15Series, setDensity15Series]: [
    Density15SeriesModel,
    Function,
  ] = useState({})
  const [density15Toggles, setDensity15Toggles]: [
    Density15TogglesModel,
    Function,
  ] = useState({})
  const [showCorrectionsGraph, setShowCorrectionsGraph] = useState(true)

  useEffect(() => {
    if (dataFuelLines !== undefined && dataFuelLines !== null) {
      const density15SeriesMapped = mapDensity15Series(dataFuelLines)
      setDensity15Series(density15SeriesMapped)
      setDensity15Toggles(
        Object.keys(density15SeriesMapped).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      )
    }
  }, [dataFuelLines])

  const getY2Label = useCallback(() => {
    const isDensity15Visible = Object.keys(density15Toggles).find(
      (key) => density15Toggles[key] === true,
    )
    if (isDensity15Visible) {
      return {
        name: UNITS.KILO_GRAM_PER_CUBIC_METER,
        colorScheme: whiteColorScheme,
      }
    }
    return undefined
  }, [density15Toggles])

  const consumptionSeries = getConsumptionSeries(
    dataFuelLines,
    convertToFuelLineUnit,
  )

  /**
   * refetchFuelConsumption
   * @description Memorixed function that re-fetches all consumption data on change.
   */
  const refetchFuelConsumption = useCallback(async () => {
    try {
      await refreshFuelConsumption()
    } catch (err) {
      displayErrorModal({
        statusText: 'Failed to refetch',
        message:
          'Failed to get the updated fuel consumption, please refresh application',
      })
    }
  }, [refreshFuelConsumption])

  const fuelLineTypesFromCurrentSelections = useMemo(() => {
    return currentFuelTypeSelections?.map(
      (currentSelection) => currentSelection.fuelLineType,
    )
  }, [currentFuelTypeSelections])

  /**
   * fuelLinesLosses
   * @description Represents a memorized array of data losses, filtered by the fuel line types
   * represented in the graph.
   */
  const fuelLinesLosses = useMemo((): FuelLineDataLoss[] => {
    if (!losses || !fuelLineTypesFromCurrentSelections) {
      return []
    }

    return losses.filter((loss) =>
      fuelLineTypesFromCurrentSelections.includes(loss.fuelLineType),
    )
  }, [losses, fuelLineTypesFromCurrentSelections])

  /**
   * fuelLinesOverwrites
   * @description Represents a memorized array of corrections of type manual overwirte,
   * filtered by the fuel line types represented in the graph.
   */
  const fuelLinesOverwrites = useMemo((): ManualCorrectionOverwrite[] => {
    if (!overwrites || !fuelLineTypesFromCurrentSelections) {
      return []
    }
    return overwrites.filter(({ fuelLineType }) =>
      fuelLineTypesFromCurrentSelections.includes(fuelLineType),
    )
  }, [overwrites, fuelLineTypesFromCurrentSelections])

  /**
   * fuelLinesRepairs
   * @description Represents a memorized array of corrections of type manual overwirte,
   * filtered by the fuel line types represented in the graph.
   */
  const fuelLinesRepairs = useMemo((): ManualCorrectionRepair[] => {
    if (!repairs || !fuelLineTypesFromCurrentSelections) {
      return []
    }
    return repairs.filter(({ fuelLineType }) =>
      fuelLineTypesFromCurrentSelections.includes(fuelLineType),
    )
  }, [repairs, fuelLineTypesFromCurrentSelections])

  return (
    <S.Wrapper>
      <div className='legend'>
        <div className='toggles'>
          {Object.keys(toggleInChart).map((key) => (
            <FancyLabel
              key={key}
              colorScheme={mapFuelTypeToColorScheme(getFuelTypeFromName(key))}
              value={toggleInChart[key]}
              onChange={() => {
                const newToggleInChart = {
                  ...toggleInChart,
                  [key]: !toggleInChart[key],
                }
                setToggleInChart(newToggleInChart)
              }}
            >
              {key}
            </FancyLabel>
          ))}
        </div>
        <LegendDropdown
          density15Toggles={density15Toggles}
          setDensity15Toggles={setDensity15Toggles}
          currentFuelTypeSelections={currentFuelTypeSelections}
          hasMainEngSecondaryFuelLine={
            configuration.hasMainEngSecondaryFuelLine
          }
        />
      </div>
      <ChartContainer
        y1Label={{
          name: unit,
          colorScheme: whiteColorScheme,
        }}
        y2Label={getY2Label()}
      >
        {consumptionSeries && toggleInChart && queryPeriod ? (
          <FuelConsumptionChart
            currentFuelTypeSelections={currentFuelTypeSelections}
            consumptionLosses={fuelLinesLosses}
            consumptionOverwrites={fuelLinesOverwrites}
            consumptionSeries={consumptionSeries}
            density15Series={density15Series}
            fuelLines={dataFuelLines}
            hasTwoMainEngines={hasTwoMainEngines}
            id={`${id}-consumption`}
            isMainEngine={isMainEngine}
            mixingPeriodSeries={mixingPeriodSeries}
            queryPeriod={queryPeriod}
            setChartRef={setChartRef}
            timestamps={timestamps}
            toggleInChart={{ ...density15Toggles, ...toggleInChart }}
            unit={unit}
            hasMainEngSecondaryFuelLine={
              configuration.hasMainEngSecondaryFuelLine
            }
          />
        ) : (
          <ChartStencil chartType='area' />
        )}
        {!!status && (
          <VesselStatusBlock timestamp={status.timestamp}>
            {currentFuelTypeSelections &&
              currentFuelTypeSelections.map((currentSelection, i) => {
                const fuelLine = status.dataFuelLines
                  ? status.dataFuelLines.find(
                      (fuelLine) =>
                        fuelLine.fuelLineType === currentSelection.fuelLineType,
                    )
                  : undefined
                return (
                  <Block key={i}>
                    <FuelConsumptionBlock
                      fuelLine={fuelLine}
                      unit={unit}
                      fuelLineType={currentSelection.fuelLineType}
                      fuelType={currentSelection.fuelType}
                    />
                  </Block>
                )
              })}
          </VesselStatusBlock>
        )}
      </ChartContainer>
      {queryPeriod && batchMonitorSeries && (
        <S.BatchAndCorrectionsWrapper>
          <S.BatchWrapper>
            <BatchMonitorChart
              currentFuelTypeSelections={currentFuelTypeSelections}
              batchSeries={batchMonitorSeries}
              id={`${id}-monitor`}
              queryPeriod={queryPeriod}
              setChartRef={setMonitorChartRef}
              toggleInChart={toggleInChart}
              hasMainEngSecondaryFuelLine={
                configuration.hasMainEngSecondaryFuelLine
              }
            />
            {fuelLinesLosses?.length ||
            fuelLinesOverwrites?.length ||
            fuelLinesRepairs?.length ? (
              <S.CorrectionsTrigger
                isCorrectionsShown={showCorrectionsGraph}
                onClick={() => setShowCorrectionsGraph(!showCorrectionsGraph)}
              >
                <p>Corrections</p>
                {!!fuelLinesLosses?.length && <ExclamationCircle fill='red' />}
              </S.CorrectionsTrigger>
            ) : null}
          </S.BatchWrapper>
          {fuelLinesLosses?.length ||
          fuelLinesOverwrites?.length ||
          fuelLinesRepairs?.length ? (
            <CorrectionsChart
              currentFuelTypeSelections={currentFuelTypeSelections}
              batchSeries={batchMonitorSeries}
              id={`${id}-corrections`}
              isShown={showCorrectionsGraph}
              losses={fuelLinesLosses}
              overwrites={fuelLinesOverwrites}
              queryPeriod={queryPeriod}
              repairs={fuelLinesRepairs}
              setChartRef={setConsumptionChartRef}
              setIsDataLossAccordionOpen={setIsDataLossAccordionOpen}
              refreshFuelConsumption={refetchFuelConsumption}
              hasMainEngSecondaryFuelLine={
                configuration.hasMainEngSecondaryFuelLine
              }
            />
          ) : null}
        </S.BatchAndCorrectionsWrapper>
      )}
      {!!imoNo && (
        <S.AccordionsWrapper>
          <FuelConsumptionLosses
            imoNo={imoNo}
            isOpen={isDataLossAccordionOpen}
            losses={fuelLinesLosses}
            refreshFuelConsumption={refetchFuelConsumption}
            setIsOpen={setIsDataLossAccordionOpen}
            currentFuelTypeSelections={currentFuelTypeSelections}
            hasMainEngSecondaryFuelLine={
              configuration.hasMainEngSecondaryFuelLine
            }
          />
          <FuelConsumptionCorrections
            imoNo={imoNo}
            fuelLineTypes={fuelLineTypesFromCurrentSelections || []}
            overwrites={fuelLinesOverwrites}
            repairs={fuelLinesRepairs}
            refreshFuelConsumption={refetchFuelConsumption}
            currentFuelTypeSelections={currentFuelTypeSelections}
          />
        </S.AccordionsWrapper>
      )}
    </S.Wrapper>
  )
}
