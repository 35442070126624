import { useContext } from 'react'
import { useField } from 'formik'
import moment, { type Moment } from 'moment'
import {
  McIcon,
  McInputDate,
  McInputTime,
} from '@maersk-global/mds-react-wrapper'

import {
  DATE_FORMAT,
  getInternalValues,
  TIME_FORMAT,
} from './InputDateTime.utils'
import { Wrapper } from './InputDateTime.styles'
import { WindowContext } from '../../../contexts'
import { type TWindowSize } from '../../../theme_new/styled'

type Props = {
  name: string
  label: string
  min?: string | Moment
  max?: string | Moment
  openToDate?: string | Moment
  disabled?: boolean
  fit?: TWindowSize
  hasWarning?: boolean
  minuteSpecific?: boolean
  passThroughMoment?: boolean
  hideErrorMessage?: boolean
  onChange?: (timestamp: Moment) => void
}

/**
 * @description A wrapper for
 * [MDS Input Date]{@link https://designsystem.maersk.com/components/input-date/index.html} and
 * [MDS Select]{@link https://designsystem.maersk.com/components/select/index.html}
 * that provides two-way data binding for Formik form date/time values based on the `name` prop
 */
export const FormInputDateTime = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField(props.name)

  const handleDateInput = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement // YYYY-MM-DD

    if (value.length < DATE_FORMAT.length) {
      return
    }

    /* New Moment date with time 00:00 */
    let newTimestamp = moment.utc(value)

    /* If form field already holds a value, add the time part to the new date */
    if (field.value) {
      const currentTimestamp = moment.utc(field.value)
      newTimestamp
        .add(currentTimestamp.hour(), 'hour')
        .add(currentTimestamp.minute(), 'minute')
    }

    if (
      props.min &&
      typeof props.min !== 'string' &&
      newTimestamp.isBefore(props.min)
    ) {
      newTimestamp = props.min.startOf('minute')
    }

    if (
      props.max &&
      typeof props.max !== 'string' &&
      newTimestamp.isAfter(props.max)
    ) {
      newTimestamp = props.max.startOf('minute')
    }

    /* Keep the result as a Moment object or cast to ISO string */
    if (props.passThroughMoment) {
      helpers.setValue(newTimestamp)
      props.onChange?.(newTimestamp)
    } else {
      helpers.setValue(newTimestamp.toISOString())
    }
  }

  const handleTimeChange = (event: InputEvent) => {
    const { value } = event.target as HTMLInputElement

    if (value.length < TIME_FORMAT.length) {
      return
    }

    const [hours, minutes] = value.split(':')

    if (field.value) {
      const newTimestamp = moment
        .utc(field.value)
        .hours(+hours)
        .minutes(+minutes)

      /* Keep the result as a Moment object or cast to ISO string */
      if (props.passThroughMoment) {
        helpers.setValue(newTimestamp)
        props.onChange?.(newTimestamp)
      } else {
        helpers.setValue(newTimestamp.toISOString())
      }
    }
  }

  const _errorMessage =
    !props.hideErrorMessage && meta.touched ? meta.error : undefined

  let { min, max, date, time } = getInternalValues(
    props.min,
    props.max,
    field.value,
  )

  const handleDateFocus = () => {
    if (date !== '' || !props.openToDate) return
    helpers.setValue(moment.utc(props.openToDate).toISOString())
  }

  return (
    <Wrapper>
      <McInputDate
        fit={props.fit ?? windowSize}
        width='100'
        name={props.name}
        disabled={props.disabled}
        invalid={meta.touched && !!meta.error}
        errormessage={_errorMessage}
        focus={handleDateFocus}
        input={handleDateInput}
        min={min}
        max={max}
        value={date}
        data-e2e={'Date'}
      >
        <span slot='label'>
          {props.label}&nbsp;
          {props.hasWarning && (
            <McIcon
              className='mc-icon--warning'
              icon='exclamation-triangle-solid'
              size={
                windowSize === 'small' || props.fit === 'small' ? '20' : '24'
              }
            />
          )}
        </span>
      </McInputDate>
      <McInputTime
        fit={props.fit ?? windowSize}
        width='100'
        hiddenlabel
        value={time}
        input={handleTimeChange}
        minutestep={props.minuteSpecific ? 1 : 10}
        invalid={meta.touched && !!meta.error}
        errormessage={props.hideErrorMessage ? undefined : '\u00A0'}
        disabled={props.disabled}
        data-e2e={'Time'}
      />
    </Wrapper>
  )
}
