import { useContext, useEffect, useMemo, useState } from 'react'
import {
  Formik,
  type FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import moment, { type Moment } from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import HDCContext from '../../context/context'
import { WindowContext } from '../../../../contexts'
import { type Event } from '../../../../api-models/hdc/events'
import { editEvent, postEvent } from '../../../../services/hdc'
import { displayErrorModal } from '../../../../utils'
import { SimpleFormRow } from '../../components/form-row'
import OverlayLoader from '../../components/overlay-loader'
import {
  ESubReport,
  EventTypeName,
  HDCFormType,
  type HDCReportFormValues,
} from '../../types'
import {
  flattenFormikErrors,
  getFormikTouchedFieldsErrors,
  timeDataPointToMomentDataPoint,
} from '../../utils'
import getEventValidationSchema from '../../validation/events'
import * as S from './style'
import HDCTextareaField from '../../components/hdc-textarea-field'
import { FormInputDateTime } from '../../../../components/formik'
import Notifications from '../../../../components/Notifications/Notifications'

interface IFormValues {
  comment: string
  eta: Moment | null
  timestamp: Moment | null
}

type Props = {
  data?: Event
  handleClose?: Function
}

const ETAChangeEvent = ({ data, handleClose }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { values: report, setFieldValue } =
    useFormikContext<HDCReportFormValues>()
  const {
    state: HDCState,
    imoNo,
    getReport,
    setEvents,
    setHasUnsavedEvent,
  } = useContext(HDCContext)
  const { eventTypes, form, report: HDCReport } = HDCState
  const [dirty, setDirty] = useState<boolean>(false)

  const eventType = useMemo(() => {
    return eventTypes?.find(
      (eventType) => eventType.name === EventTypeName.ETA_CHANGE,
    )
  }, [eventTypes])

  const handleSubmit = async (
    values: IFormValues,
    helpers: FormikHelpers<any>,
  ): Promise<void> => {
    if (!eventType?.id || !HDCState.form.type) {
      return displayErrorModal({
        statusText: 'Failed to proceed',
        message: 'Could not save event data, please try to refresh the page.',
      })
    }

    // The ! bangs are fine here as the formik validator will catch null values
    // see `getEventValidationSchema`
    const event = {
      comment: values.comment || null,
      endTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      metadata: {
        eta: values.eta ? moment(values.eta).toISOString() : '',
      },
      source: HDCState.form.type,
      startTimestamp: values.timestamp
        ? moment(values.timestamp).toISOString()
        : '',
      typeId: eventType.id,
    }

    try {
      let response: { event: Event }
      const subReport =
        form.type === HDCFormType.BRIDGE_FORM
          ? ESubReport.Bridge
          : ESubReport.EngineRoom
      if (data) {
        response = await editEvent(
          { ...event, id: data.id, typeName: data.typeName },
          imoNo,
          report.id,
          form.type!,
        )
        const events = (HDCReport?.[subReport].events || []).filter(
          ({ id }) => id !== data.id,
        )
        setEvents([...events, response.event], subReport)
        setFieldValue(`${subReport}.events`, [...events, response.event])
        handleClose?.()
      } else {
        response = await postEvent(event, imoNo, report.id, HDCState.form.type)
        const events = HDCReport?.[subReport].events || []
        setEvents([...events, response.event], subReport)
        setFieldValue(`${subReport}.events`, [...events, response.event])
        helpers.resetForm()
      }

      // fetch report to get updated ETA
      const updatedReport = await getReport(report.id)
      if (!updatedReport.bridge) {
        throw new Error('Failed to update ETA on Voyage step')
      }
      setFieldValue(
        'bridge.eta',
        timeDataPointToMomentDataPoint(updatedReport.bridge.eta),
      )
    } catch (err) {
      void displayErrorModal({
        statusText: err.message || 'Failed to save event',
        message:
          err?.body?.error || 'Could not save event data, please try again',
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const initialValues = data
    ? {
        comment: data.comment || '',
        eta: moment(data.metadata?.eta).utc(),
        timestamp: moment(data.startTimestamp).utc(),
      }
    : {
        comment: '',
        eta: null,
        timestamp: null,
      }

  useEffect(() => {
    if (data) {
      return
    }
    setHasUnsavedEvent(dirty)
  }, [data, dirty, setHasUnsavedEvent])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={false}
      validate={(values) => {
        try {
          void validateYupSchema(
            values,
            getEventValidationSchema(EventTypeName.ETA_CHANGE),
            true,
            { report },
          )
        } catch (err) {
          return yupToFormErrors(err)
        }
      }}
    >
      {({ dirty, errors: formikErrors, isSubmitting, submitForm, touched }) => {
        const errors = getFormikTouchedFieldsErrors(formikErrors, touched)
        setDirty(dirty)
        return (
          <S.Wrapper>
            <S.Description>
              If a new ETA to pilot station has been issued, please enter date
              and time for when the change was applied. ETA information will
              then be updated on the Voyage tab. It is possible to report
              multiple ETA changes if this should occur within the same report
              period.
            </S.Description>
            <Notifications alerts={flattenFormikErrors(errors)} />
            <SimpleFormRow>
              <FormInputDateTime
                name='timestamp'
                label='Date and time for change of ETA, UTC'
                min={report.periodStart}
                max={report.periodEnd}
                openToDate={report.periodStart}
                minuteSpecific
                passThroughMoment
                hideErrorMessage
              />
            </SimpleFormRow>
            <SimpleFormRow>
              <FormInputDateTime
                name='eta'
                label='New ETA to pilot station, UTC'
                min={report.periodEnd}
                max={moment.utc().add(1, 'y')}
                openToDate={report.periodEnd}
                minuteSpecific
                passThroughMoment
                hideErrorMessage
              />
            </SimpleFormRow>
            <SimpleFormRow>
              <HDCTextareaField
                name='comment'
                label='Additional comments to the ETA change event'
              />
            </SimpleFormRow>
            <S.ButtonWrapper>
              <McButton
                fit={windowSize}
                click={submitForm}
                type='button'
                disabled={isSubmitting}
              >
                {data ? 'Save changes' : 'Add Event'}
              </McButton>
            </S.ButtonWrapper>
            {isSubmitting && <OverlayLoader padding='0px' />}
          </S.Wrapper>
        )
      }}
    </Formik>
  )
}
export default ETAChangeEvent
