import moment from 'moment'
import styled from 'styled-components'

import { type FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import Accordion from '../../../components/accordion'
import DataLossForm from '../components/data-loss-form'
import { ExclamationCircle } from '../../../components/icons'
import { mapFuelLineTypeToHeaderText } from '../../../utils/mappers/FuelLineMappers.utils'

const AccItem = styled.div`
  display: flex;
  align-items: center;
`

const AccIcon = styled.div`
  display: inline-flex;
  margin-left: 0.5rem;
`

type Props = {
  imoNo: string
  isOpen: boolean
  losses: FuelLineDataLoss[]
  refreshFuelConsumption: () => void
  setIsOpen: Function
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
  hasMainEngSecondaryFuelLine: boolean
}

/**
 * FuelConsumptionLosses
 * @description component used to display data losses based on array of fuel lines provided.
 *
 * @param {string} imoNo A string representing the unique vessel ID.
 * @param {boolean} isOpen determines whether the accordion should be open or not.
 * @param {FuelLineDataLoss[]} losses An array of consumption losses filtered by fuel line type
 * @param {function} refreshFuelConsumption A callback that refreshes all of fuel consumption data set
 * @param {function} setIsOpen A callback that sets whether the accordion is open or not in the parent state
 * @param currentFuelTypeSelections An array of current fuel type selections
 * @param hasMainEngSecondaryFuelLine A boolean that determines if the vessel is Dual Fuel and has a secondary fuel line
 */
const FuelConsumptionLosses = ({
  imoNo,
  isOpen,
  losses,
  refreshFuelConsumption,
  setIsOpen,
  currentFuelTypeSelections,
  hasMainEngSecondaryFuelLine,
}: Props) => {
  if (!imoNo || !losses.length) {
    return null
  }

  const manualRefresh = () => {
    if (losses.length > 1) {
      refreshFuelConsumption()
    } else {
      window.location.reload()
    }
  }

  const formatTimestamp = (ts: string) =>
    moment(ts).utc().format('DD MMM YYYY HH:mm')

  const blerb1 = `Data loss${
    losses.length > 1 ? 'es' : ''
  } detected between following timestamps `
  const start = formatTimestamp(losses[0].startTimestamp)
  const end = formatTimestamp(losses[losses.length - 1].endTimestamp)
  const blerb2 =
    'Please enter estimated consumption or confirm StarConnect’s estimated consumption.'

  return (
    <Accordion
      depth={0}
      isOpen={isOpen}
      itemRenderer={() => (
        <AccItem>
          Data Loss{' '}
          {
            <AccIcon>
              <ExclamationCircle fill='red' />
            </AccIcon>
          }
        </AccItem>
      )}
      setIsOpen={setIsOpen}
      toggle={''}
    >
      <p>
        {blerb1}
        <strong>{start}</strong> - <strong>{end}</strong> {blerb2}
      </p>
      {losses.map((loss, index) => {
        const { fuelLineType, startTimestamp, endTimestamp } = loss

        const currentFuelLineType = currentFuelTypeSelections.find(
          (fl) => fl.fuelLineType === fuelLineType,
        )
        if (!currentFuelLineType) return null

        const fuelLineLoss = mapFuelLineTypeToHeaderText(
          currentFuelLineType.fuelType,
          currentFuelLineType.fuelLineType,
          hasMainEngSecondaryFuelLine,
        )
        return (
          <Accordion
            key={`${fuelLineType}-${startTimestamp}-${endTimestamp}`}
            depth={1}
            toggle={`${fuelLineLoss}
             ${formatTimestamp(startTimestamp)} - ${formatTimestamp(
              endTimestamp,
            )}`}
            isDisabled={index > 0}
          >
            <DataLossForm
              id={`loss-${loss.fuelLineType}-${index}`}
              loss={loss}
              refreshData={manualRefresh}
            />
          </Accordion>
        )
      })}
    </Accordion>
  )
}

export default FuelConsumptionLosses
