export const StaticModelType = {
  Hull: 1,
  Propeller: 2,
  Whr: 3,
  MainEng: 4,
  AuxEng: 5,
  Boiler: 6,
  ShaftMotorGenerator: 7,
} as const

export const StaticModelTypeNames = {
  [StaticModelType.Hull]: 'Hull',
  [StaticModelType.Propeller]: 'Propeller',
  [StaticModelType.Whr]: 'WHR',
  [StaticModelType.MainEng]: 'Main Engine',
  [StaticModelType.AuxEng]: 'Aux Engine',
  [StaticModelType.Boiler]: 'Boiler',
  [StaticModelType.ShaftMotorGenerator]: 'Shaft Motor Generator',
} as const

export enum OperatingConditionCode {
  DEFAULT = 'DEFAULT',
  TCCO = 'TCCO',
  EGR = 'EGR',
  ExhaustGasbypass = 'DEFAULT (Exhaust gas by-pass)',
  PilotModeDefault = 'PILOT MODE (Tier II)',
  PilotModeTCCO = 'PILOT MODE (TCCO)',
  PilotModeTierIII = 'PILOT MODE (Tier III)',
  PilotModeExhaustGasbypass = 'PILOT MODE (Exhaust gas by-pass)',
}

export const OperatingConditionNames: Record<OperatingConditionCode, string> = {
  [OperatingConditionCode.DEFAULT]: 'DEFAULT (Tier II)',
  [OperatingConditionCode.TCCO]: 'DEFAULT (TCCO)',
  [OperatingConditionCode.EGR]: 'DEFAULT (Tier III)',
  [OperatingConditionCode.ExhaustGasbypass]: 'DEFAULT (Exhaust gas by-pass)',
  [OperatingConditionCode.PilotModeDefault]: 'PILOT MODE (Tier II)',
  [OperatingConditionCode.PilotModeTCCO]: 'PILOT MODE (TCCO)',
  [OperatingConditionCode.PilotModeTierIII]: 'PILOT MODE (Tier III)',
  [OperatingConditionCode.PilotModeExhaustGasbypass]:
    'PILOT MODE (Exhaust gas by-pass)',
} as const

export const MainEngInstanceName = {
  1: 'Port',
  2: 'Stbd.',
}

export const VesselStatus = {
  Active: 'ACTIVE',
  Outfleeted: 'OUTFLEETED',
}

export const OwnershipTypes = {
  BareboatCharter: 'BAREBOAT_CHARTER',
  ForeignFeeder: 'FOREIGN_FEEDER',
  Own: 'OWN',
  TimeCharter: 'TIME_CHARTER',
  Unknown: 'UNKNOWN',
  Vsa: 'VSA',
}
