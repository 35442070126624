import { useContext, useMemo, useRef } from 'react'
import {
  getCoreRowModel,
  getExpandedRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'

import {
  flattenFuelLines,
  flattenFuelTypes,
  getColumns,
  isDataAvailabilityLow,
} from './FuelConsumption.utils'
import { TableWrapper } from './FuelConsumption.styles'
import { useGetCurrentFuelTypeSelections } from '../../../../queries/PerformanceApi/Papi'
import { Performance } from '../../../../api-models'
import { VesselPageContext } from '../../../../contexts'
import Table, { type ITableRef } from '../../../../components/Table/Table'
import { Loading } from '../../../../commons'

const FUEL_TYPES = ['HS', 'VLS', 'ULS', 'MDO', 'MM']

type Props = {
  fuelConsumption: Performance.Counter.FuelConsumption
  batchEntries: Array<Performance.FuelOilStock.BatchResponse>
  numberOfDigits: number
  showBatchDetails: (batchId: string) => void
}

const FuelConsumption = ({
  batchEntries,
  fuelConsumption,
  numberOfDigits,
  showBatchDetails,
}: Props) => {
  const configuration = useContext(VesselPageContext).configuration!

  const { data: getCurrentFuelTypeSelections, isLoading } =
    useGetCurrentFuelTypeSelections(configuration.imoNo!.toString())

  const fuelConsumptionByFuelType = useMemo(
    () => flattenFuelTypes(fuelConsumption.fuelTypes),
    [fuelConsumption.fuelTypes, getCurrentFuelTypeSelections],
  )

  const totalsByFuelType = useMemo(() => {
    return FUEL_TYPES.map((fuelType) => {
      const fuelConsumptionForCurrentFuelType =
        fuelConsumptionByFuelType[fuelType]
      return {
        ...flattenFuelLines(fuelConsumptionForCurrentFuelType?.fuelLines ?? []),
        fuelType: fuelType,
        total: fuelConsumptionForCurrentFuelType?.total ?? '',
      }
    })
  }, [fuelConsumptionByFuelType])

  const batchesByFuelType = useMemo(() => {
    const transformBatchResponseToBatch = (fuelType) => (batch) => ({
      ...flattenFuelLines(batch.fuelLines),
      fuelType: fuelType.toUpperCase(),
      batchName: batchEntries.find(
        (batchResponse) => batch.batchId === batchResponse.id,
      )?.displayName,
      total: batch.total,
      batchId: batch.batchId || 'unknown',
    })

    return FUEL_TYPES.reduce((batchesByFuelType, fuelType) => {
      const fuelConsumption = fuelConsumptionByFuelType[fuelType]
      const batches: Performance.Counter.Batch[] =
        fuelConsumption?.batches || []
      const unknownBatch: Performance.Counter.Batch =
        fuelConsumption?.unknownBatch
      batchesByFuelType[fuelType] = batches.map(
        transformBatchResponseToBatch(fuelType),
      )
      if (unknownBatch) {
        batchesByFuelType[fuelType].push(
          transformBatchResponseToBatch(fuelType)(unknownBatch),
        )
      }
      return batchesByFuelType
    }, {})
  }, [batchEntries, fuelConsumptionByFuelType])

  const data = useMemo(
    () =>
      totalsByFuelType.map((item) => {
        const subRows = batchesByFuelType[item.fuelType]?.map((item) => {
          const { batchId, batchName, fuelType, total, ...consumers } = item

          const subRow = {
            ...item,
            fuelType: batchName,
            cellFeedback: {},
          }

          // @ts-ignore
          for (const [key, { dataAvailability }] of Object.entries(consumers)) {
            if (isDataAvailabilityLow(dataAvailability)) {
              subRow.cellFeedback = {
                ...subRow.cellFeedback,
                [`${key}.consumedMass`]: 'danger',
              }
            }
          }

          return subRow
        })

        const { fuelType, total, ...consumers } = item

        const row = {
          ...item,
          fuelTypeColor: fuelType.toLowerCase(),
          cellFeedback: {},
          subRows,
        }

        // @ts-ignore
        for (const [key, { dataAvailability }] of Object.entries(consumers)) {
          if (isDataAvailabilityLow(dataAvailability)) {
            row.cellFeedback = {
              ...row.cellFeedback,
              [`${key}.consumedMass`]: 'danger',
            }
          }
        }

        return row
      }),
    [totalsByFuelType, batchesByFuelType],
  )

  const tableRef = useRef<ITableRef>(null)

  const table = useReactTable({
    data,
    columns: getColumns(
      configuration.fuelLineTypes,
      batchEntries,
      numberOfDigits,
      getCurrentFuelTypeSelections ?? [],
      configuration.hasMainEngSecondaryFuelLine,
    ),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
  })

  const handleRowClick = (row: Row<any>) => {
    tableRef?.current?.deselectRow()

    if (row.getCanExpand()) {
      row.toggleExpanded()
    } else if (row.original.batchId) {
      showBatchDetails(row.original.batchId)
    }
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <TableWrapper>
      <Table
        table={table}
        hidePagination
        disableRowHighlightOnHover
        ref={tableRef}
        onRowSelect={handleRowClick}
      />
    </TableWrapper>
  )
}

export default FuelConsumption
