import { useMemo } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'

import {
  filterTechnicalEventTypes,
  getComponentOptions,
  getEquipmentOptions,
  getEventTypeId,
  getSubComponentOptions,
} from './General.utils'
import { statusOptions } from './General.consts'

import { Row, Ruler } from '../MalfunctioningEquipmentReportPage.styles'
import MfeInputDateTime from '../MfeInputDateTime/MfeInputDateTime'
import MfeInputRadioGroup from '../MfeInputRadioGroup/MfeInputRadioGroup'
import MfeInputSelect from '../MfeInputSelect/MfeInputSelect'
import { useGetEventTypes } from '../../../../queries/GandalfApi/GandalfApi'
import { MfeStatus } from '../../../../queries/GandalfApi/GandalfApi.consts'
import { PadContent } from '../../../../layout/styles'
import { DATE_FORMAT } from '../../../../components/formik/InputDateTime/InputDateTime.utils'
import MfeEngineSpecificFields from '../MfeEngineSpecificFields/MfeEngineSpecificFields'

const General = () => {
  const getEventTypes = useGetEventTypes()
  const { values, setFieldValue } = useFormikContext<GandalfApi.MfeReport>()

  const { status, equipment, component } = values.data

  const technicalEventTypes = useMemo(() => {
    if (!getEventTypes.isSuccess) {
      return []
    }
    return filterTechnicalEventTypes(getEventTypes.data.eventTypes)
  }, [getEventTypes.data])

  const equipmentOptions = useMemo(
    () => getEquipmentOptions(technicalEventTypes),
    [technicalEventTypes],
  )

  const componentOptions = useMemo(
    () => getComponentOptions(technicalEventTypes, equipment),
    [technicalEventTypes, equipment],
  )

  const subComponentOptions = useMemo(
    () => getSubComponentOptions(technicalEventTypes, equipment, component),
    [technicalEventTypes, equipment, component],
  )

  const handleEquipmentChange = () => {
    void setFieldValue('data.component', null)
    void setFieldValue('data.subComponent', null)
    void setFieldValue('data.eventTypeId', null)
    void setFieldValue('data.meStatus', null)
    void setFieldValue('data.staticModel.id', null)
    void setFieldValue('data.staticModel.instanceNo', null)
    void setFieldValue('data.staticModel.model', null)
  }

  const handleComponentChange = () => {
    void setFieldValue('data.subComponent', null)
    void setFieldValue('data.eventTypeId', null)
  }

  const handleSubComponentChange = (subComponent: string) => {
    void setFieldValue(
      'data.eventTypeId',
      getEventTypeId(technicalEventTypes, equipment, component, subComponent),
    )
  }

  const handleStatusChange = (status: string) => {
    if (status === MfeStatus.OutOfService) {
      void setFieldValue('endTimestamp', null)
    }
  }

  return (
    <PadContent>
      <h3>General info</h3>
      <Row>
        <MfeInputDateTime
          name='startTimestamp'
          label='Start of malfunction'
          maxDate={moment.utc().format(DATE_FORMAT)}
        />
        <MfeInputRadioGroup<GandalfApi.Mfe.Status>
          name='data.status'
          label='Status of malfunction'
          options={statusOptions}
          onChange={handleStatusChange}
        />
        <div />
      </Row>
      {status !== null && status !== MfeStatus.OutOfService && (
        <Row>
          <MfeInputDateTime
            name='endTimestamp'
            label={
              status === MfeStatus.Ongoing
                ? 'Expected due date (Estimate)'
                : 'End date of malfunction'
            }
          />
          <div />
          <div />
        </Row>
      )}
      <Row>
        <MfeInputSelect
          name='data.equipment'
          label='Equipment'
          options={equipmentOptions}
          onChange={handleEquipmentChange}
        />
        <MfeInputSelect
          name='data.component'
          label='Component'
          options={componentOptions}
          onChange={handleComponentChange}
        />
        <MfeInputSelect
          name='data.subComponent'
          label='Sub-component'
          options={subComponentOptions}
          onChange={handleSubComponentChange}
        />
      </Row>
      <MfeEngineSpecificFields />
      <Ruler />
    </PadContent>
  )
}

export default General
