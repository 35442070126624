import { useState } from 'react'

import { Modal } from '../../../../commons'
import { InitialRobForm } from './InitialRobForm'
import { InfoBox } from '../../../../commons'
import { errorTheme } from '../../../../theme'
import { StockState } from '../../models'

type Props = {
  closeHandler: (redirect: boolean) => void
  stateOfStock: StockState
}

export const InitialRobModal = ({ closeHandler, stateOfStock }: Props) => {
  const [modalErrorMessage, setModalErrorMessage] = useState('')

  const renderErrorMessage = () => {
    return (
      modalErrorMessage && (
        <InfoBox hideIcons={true} theme={errorTheme}>
          {modalErrorMessage}
        </InfoBox>
      )
    )
  }

  return (
    <Modal
      title='Stock: Initial remaining on board setup'
      notification={renderErrorMessage()}
      notificationBorderLeft={`1px solid ${errorTheme.border}`}
      notificationBorderRight={`1px solid ${errorTheme.border}`}
      notificationBorderRadius='4px'
      notificationMargin='8px'
      visible
      closeHandler={() => closeHandler(true)}
      className='joyride-rob-1'
    >
      <InitialRobForm
        stateOfStock={stateOfStock}
        closeHandler={closeHandler}
        setModalErrorMessage={setModalErrorMessage}
      />
    </Modal>
  )
}
