import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as ECharts from 'echarts'
import moment from 'moment'

import { StockEntryContext, VesselPageContext } from '../../../../contexts/'
import { Performance } from '../../../../api-models'
import { formatValue, getDataIndex, isNumber, tooltip } from '../../../../utils'
import { grey, seaGreen } from '../../../../theme'
import { Chart } from '../../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../../utils/constants'
import {
  FUEL_OIL_USER_ENTRY_TYPE_NAMES,
  USER_ENTRY_TYPE_TO_ENTRY_TYPE,
} from '../../models'
import { usePrevious } from '../../../../hooks'
import {
  type BatchBurndownSeries,
  type BatchBurndownSeriesLog,
  type ToggleInBurndownChart,
  type TransformedUserEntriesSeries,
} from './models'
import { mapBatchBurndownEventsToString } from './utils'
import { PositionTooltipFunc } from '../../../../chart-lib/tooltip-utils'

const SECONDARY_YAXIS_HEIGHT = 100

const positionTooltipLeftRight: PositionTooltipFunc = (
  point,
  params,
  element,
  rect,
  size,
) => {
  const x =
    point[0] > size.viewSize[0] / 2
      ? point[0] - size.contentSize[0] / 2 - 280
      : point[0] - size.contentSize[0] / 2 + 280
  const y = Math.min(
    Math.max(GRID.top + 20, point[1] - size.contentSize[1] / 2),
    size.viewSize[1] - GRID.top - GRID.bottom - 140 - size.contentSize[1] / 2,
  )
  return [x, y]
}

type Props = {
  combinedBurndownSeries: Array<BatchBurndownSeries>
  combinedUserEntriesSeries?: Array<Performance.FuelOilStock.UserEntry>
  id: string
  openWindow?: Performance.FuelOilStock.OpenWindow
  toggleInChart: ToggleInBurndownChart
  period: Performance.Common.Period
  setInitialZoom: any
  initialZoom: any
}

export const BatchBurndownChart = ({
  combinedBurndownSeries,
  combinedUserEntriesSeries,
  id,
  openWindow,
  toggleInChart,
  period,
  initialZoom,
  setInitialZoom,
}: Props) => {
  const { setCurrentEntry } = useContext(StockEntryContext)
  const chartNode = useRef<HTMLDivElement>(null)
  const [chart, setChart]: [Chart | undefined, Function] = useState()
  const prevToggleInChart: ToggleInBurndownChart = usePrevious(toggleInChart)
  const initialZoomRef = useRef(initialZoom)
  const configuration = useContext(VesselPageContext).configuration!

  useEffect(() => {
    if (chart && prevToggleInChart) {
      Object.keys(toggleInChart).forEach((key) => {
        if (
          chart &&
          prevToggleInChart &&
          toggleInChart[key] !== prevToggleInChart[key]
        ) {
          chart.dispatchAction({
            type: toggleInChart[key] ? 'legendSelect' : 'legendUnSelect',
            name: key,
          })
        }
      })
    }
  }, [chart, toggleInChart, prevToggleInChart])

  const userClickedOnChart = useCallback(
    (params): void => {
      if (params && params.seriesName === 'Events') {
        const { data } = params
        if (data && data.length === 3) {
          const userEntry = data[2]
          if (userEntry.type < 11)
            setCurrentEntry(
              USER_ENTRY_TYPE_TO_ENTRY_TYPE[userEntry.type],
              userEntry.id,
            )
        }
      }
    },
    [setCurrentEntry],
  )

  const transformedUserEntries = useMemo(() => {
    if (!combinedUserEntriesSeries) return []
    const transformedEntries: TransformedUserEntriesSeries[] =
      combinedUserEntriesSeries.reduce(
        (entries: TransformedUserEntriesSeries[], currentEntry) =>
          entries.concat([
            [currentEntry.timestamp, 0, currentEntry],
            [currentEntry.timestamp, SECONDARY_YAXIS_HEIGHT, currentEntry],
            [currentEntry.timestamp, null, currentEntry],
          ]),
        [],
      )
    return transformedEntries
  }, [combinedUserEntriesSeries])

  const zoomOut = () => {
    chart?.dispatchAction({
      type: 'dataZoom',
      batch: [
        {
          start: 0,
          end: 100,
        },
      ],
    })
    setInitialZoom(undefined)
  }

  const getOptions = useCallback((): ECharts.EChartOption | any => {
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: period.from,
        max: period.to,
      },
      yAxis: [
        {
          id: 'burndown',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => formatValue(value, 0),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          position: 'left',
          boundaryGap: [0, '10%'],
          scale: true,
          min: 0,
        },
        {
          id: 'user-entries',
          type: 'value',
          position: 'right',
          show: false,
          min: 0,
          max: SECONDARY_YAXIS_HEIGHT,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          ...initialZoomRef.current,
          minValueSpan: 3600 * 12 * 1000,
          zoomOnMouseWheel: false,
          filterMode: 'none',
          bottom: 20,
          showDataShadow: false,
          labelFormatter: function (value, valueStr) {
            return moment.utc(value).format('DD MMM')
          },
        },
      ],
      dataset: [
        ...combinedBurndownSeries.map((batchInfo) => ({
          dimensions: ['timestamp', 'value'],
          source: batchInfo.logs,
        })),
        {
          source: transformedUserEntries,
        },
      ],
      legend: {
        show: false,
        selected: toggleInChart,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          snap: false,
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        transitionDuration: 0,
        position: positionTooltipLeftRight,
        formatter: (params) => {
          const firstEntry = params ? params[0] : null

          if (!firstEntry || !Array.isArray(params)) {
            return ''
          }

          const timestamp = firstEntry.data?.timestamp

          // remove fromRob and sort by batch rob desc to have a relation between order in tooltip and lines on chart
          const reducedSortedParams = params
            .reduce((acc, curr) => {
              if (curr.data.displayInTooltip) {
                acc.push(curr)
              }
              return acc
            }, [])
            .sort((a, b) => {
              const aMax = Math.max(
                a.data.rob,
                a.data.rob === 0 ? a.data.fromRob ?? 0 : a.data.rob,
              )
              const bMax = Math.max(
                b.data.rob,
                a.data.rob === 0 ? b.data.fromRob ?? 0 : b.data.rob,
              )

              return bMax - aMax
            })

          // we do not want to show the From column if none of the hovered points have a fromRob
          const hasFromRob = reducedSortedParams.some(
            (param) => param.data.fromRob !== null,
          )
          const hasEvents = reducedSortedParams.some(
            (param) => param.data.events.length > 0,
          )

          const tooltips = reducedSortedParams.reduce((acc, curr) => {
            const batch: string = curr.seriesName
            const log: BatchBurndownSeriesLog = curr.data
            const rob: string = formatValue(log.rob, 3)

            if (hasFromRob) {
              const fromRob: string =
                log.fromRob !== null ? formatValue(log.fromRob, 3) : ''
              if (hasEvents) {
                acc.push({
                  batch: batch,
                  events: mapBatchBurndownEventsToString(log.events),
                  fromRob: fromRob,
                  rob: rob,
                  unit: UNITS.METRIC_TON,
                })
              } else {
                acc.push({
                  batch: batch,
                  fromRob: fromRob,
                  rob: rob,
                  unit: UNITS.METRIC_TON,
                })
              }
            } else {
              if (hasEvents) {
                acc.push({
                  batch: batch,
                  events: mapBatchBurndownEventsToString(log.events),
                  rob: rob,
                  unit: UNITS.METRIC_TON,
                })
              } else {
                acc.push({
                  batch: batch,
                  rob: rob,
                  unit: UNITS.METRIC_TON,
                })
              }
            }

            return acc
          }, [])

          const headers: any[] = []

          if (hasFromRob) {
            if (hasEvents) {
              headers.push({
                batch: 'Batch',
                events: 'Events',
                fromRob: 'ROB before',
                rob: 'ROB',
                unit: '',
              })
            } else {
              headers.push({
                batch: 'Batch',
                fromRob: 'ROB before',
                rob: 'ROB',
                unit: '',
              })
            }
          } else {
            if (hasEvents) {
              headers.push({
                batch: 'Batch',
                events: 'Events',
                rob: 'ROB',
                unit: '',
              })
            } else {
              headers.push({
                batch: 'Batch',
                rob: 'ROB',
                unit: '',
              })
            }
          }

          const textAligns: { [key: string]: string } = {
            batch: 'left',
            events: 'left',
            fromRob: 'right',
            rob: 'right',
            unit: 'right',
          }

          return tooltip(timestamp, tooltips, headers, textAligns)
        },
      },
      grid: { ...GRID, bottom: 60, right: 40 }, // right: 40 => make room for data zoom label
      toolbox: TOOLBOX_TIMELINE,
      series: [
        ...combinedBurndownSeries.map((batchInfo, index) => ({
          name: batchInfo.batch.displayName,
          id: batchInfo.batch.id,
          type: 'line',
          datasetIndex: index,
          animation: true,
          hoverAnimation: true,
          symbolSize: 5,
          symbol: 'circle',
          itemStyle: {
            color: batchInfo.color,
            borderColor: batchInfo.color,
          },
          lineStyle: {
            color: batchInfo.color,
          },
        })),
        {
          name: 'open window',
          type: 'line',
          lineStyle: { color: '#003e5e', width: 1 },
          data: [],
          yAxisIndex: 1,
          symbol: 'none',
          z: 1,
          markArea: {
            silent: true,
            data: [
              [
                {
                  xAxis: 0,
                  yAxis: 0,
                  label: {
                    offset: [0, -5],
                    color: grey[700],
                    fontSize: 10,
                  },
                  itemStyle: { color: 'rgba(163,168,173,0.25)' },
                },
                {
                  xAxis: openWindow?.period.from,
                  yAxis: SECONDARY_YAXIS_HEIGHT,
                },
              ],
            ],
          },
        },
        {
          name: 'Events',
          type: 'line',
          lineStyle: {
            color: seaGreen[700],
            width: 1,
          },
          itemStyle: { color: seaGreen[700] },
          symbol: (value: any, params) => {
            return value.length && typeof value[1] === 'number' && value[1] > 0
              ? params.data[2].type < 11
                ? 'circle'
                : 'square'
              : 'none'
          },
          symbolSize: (value, params) => {
            const data = params.data[2]
            return data.type < 11 ? 10 : 8
          },
          animation: false,
          hoverAnimation: false,
          datasetIndex: combinedBurndownSeries.length,
          yAxisIndex: 1,
          z: 4,
          tooltip: {
            position: 'top',
            backgroundColor: seaGreen[700],
            trigger: 'item',
            axisPointer: {
              type: 'line',
            },
            formatter: (params: ECharts.EChartOption.Tooltip.Format) => {
              const dataIndex = getDataIndex(params)
              if (!isNumber(dataIndex)) return ''
              const { data } = params
              if (data.length !== 3) return ''
              const userEntry = data[2]
              const tooltipData = [
                {
                  label: `${
                    typeof FUEL_OIL_USER_ENTRY_TYPE_NAMES[userEntry.type] ===
                    'function'
                      ? FUEL_OIL_USER_ENTRY_TYPE_NAMES[userEntry.type](
                          userEntry.fuelType,
                          configuration.hasMainEngSecondaryFuelLine,
                        )
                      : FUEL_OIL_USER_ENTRY_TYPE_NAMES[userEntry.type]
                  } added`,
                },
              ]
              if (userEntry.type < 11)
                tooltipData.push({
                  label: 'Click to view',
                })
              return tooltip(userEntry.timestamp, tooltipData)
            },
          },
        },
      ],
    }
  }, [
    combinedBurndownSeries,
    toggleInChart,
    openWindow,
    transformedUserEntries,
    period,
  ])

  useEffect(() => {
    if (chartNode.current && combinedBurndownSeries) {
      const chart = ECharts.init(chartNode.current)
      chart.setOption(getOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.on('click', userClickedOnChart)
      setChart(chart)
    }
  }, [combinedBurndownSeries, getOptions, userClickedOnChart, id])

  useEffect(() => {
    if (chart) {
      chart.on('datazoom', (params) => {
        // Zoomed by dragging cursor on chart
        if (params && params.batch && params.batch[0]) {
          const initialZoomChange = {
            startValue: moment
              .utc(Math.floor(params.batch[0].startValue))
              .valueOf(),
            endValue: moment.utc(Math.ceil(params.batch[0].endValue)).valueOf(),
          }
          setInitialZoom(initialZoomChange)
        }
      })
    }
    return () => {
      chart?.off('datazoom')
      chart?.off('click')
    }
  }, [chart, setInitialZoom])

  useEffect(() => {
    if (chartNode.current && combinedBurndownSeries) {
      const chart = ECharts.init(chartNode.current)
      chart.setOption(getOptions(), true)
    }
  }, [
    combinedBurndownSeries,
    combinedUserEntriesSeries,
    getOptions,
    id,
    openWindow,
  ])

  if (!combinedBurndownSeries) {
    return null
  }

  return <div onDoubleClick={zoomOut} id={id} ref={chartNode} />
}
