import { useContext } from 'react'
import { useField } from 'formik'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  name: string
  options: Array<{
    label: string
    value: string
  }>
  onBlur: () => void
}

const SelectField = ({ name, options, onBlur }: Props) => {
  const { windowSize } = useContext(WindowContext)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name)

  const handleOptionSelected = async ({ detail }: CustomEvent) => {
    await helpers.setValue(detail.value)
  }

  return (
    <McSelect
      fit={windowSize}
      hiddenlabel
      value={field.value}
      optionselected={handleOptionSelected}
      blur={onBlur}
    >
      {options.map((o) => (
        <McOption key={o.value} value={o.value} label={o.label} />
      ))}
    </McSelect>
  )
}

export default SelectField
