import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  type EChartOption,
  type ECharts,
  getInstanceByDom,
  init,
} from 'echarts'
import moment from 'moment'

import { Performance } from '../../../api-models'
import { formatValue, getDataIndex, isNumber, tooltip } from '../../../utils'
import {
  green,
  greenTheme,
  grey,
  maerskBlue,
  maerskBlueTheme,
  maritimeBlueTheme,
  orangeTheme,
  purpleTheme,
  red,
} from '../../../theme'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  FUEL_LINE_TYPES,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import {
  Density15SeriesModel,
  FuelConsumptionSeriesModel,
  ToggleInFuelConsumptionChart,
} from '../models/fuel-consumption'
import {
  convertToFuelLineUnit,
  getDecimalsFromUnit,
  mapSeriesZero,
} from '../utils/fuel-consumption'
import { getSplitLineColorByName } from '../mappers'
import { positionTooltip } from '../../../chart-lib'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'
import {
  filterFuelTypeSelection,
  mapFuelLineTypeFuelTypeToName,
} from '../../../utils/mappers/FuelLineMappers.utils'

type Props = {
  id: string
  unit: string
  timestamps: number[] | undefined
  consumptionSeries: FuelConsumptionSeriesModel
  consumptionLosses: FuelLineDataLoss[]
  consumptionOverwrites: Array<
    ManualCorrectionOverwrite | ManualCorrectionRepair
  >
  queryPeriod: Performance.Common.Period
  toggleInChart: ToggleInFuelConsumptionChart
  density15Series: Density15SeriesModel
  fuelLines: Performance.FuelConsumption.FuelLineConsumptionSeries[] | undefined
  hasTwoMainEngines?: boolean
  setChartRef: (chart: ECharts) => void
  mixingPeriodSeries: Array<Array<number | null>>
  isMainEngine: boolean
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
  hasMainEngSecondaryFuelLine: boolean
}

export const FuelConsumptionChart = ({
  consumptionLosses,
  consumptionOverwrites,
  consumptionSeries,
  density15Series,
  fuelLines,
  hasTwoMainEngines,
  id,
  isMainEngine,
  queryPeriod,
  setChartRef,
  timestamps,
  toggleInChart,
  unit,
  currentFuelTypeSelections,
  hasMainEngSecondaryFuelLine,
}: Props) => {
  // Split lines must be connected to the fuel lines fuel type consumption series
  const splitLineSeries = useMemo(() => {
    if (isMainEngine && hasTwoMainEngines) {
      const splitLines = Object.keys(consumptionSeries.meSplitLine).map(
        (seriesKey) => {
          return {
            animation: false,
            yAxisIndex: 0,
            xAxisIndex: 0,
            name: seriesKey,
            type: 'line',
            symbol: 'none',
            lineStyle: {
              color: getSplitLineColorByName(seriesKey),
              width: 2,
            },
            data: (timestamps || []).map((timestamp, index) => [
              timestamp,
              consumptionSeries.meSplitLine[seriesKey][index],
            ]),
          }
        },
      )
      return splitLines
    } else return []
  }, [consumptionSeries, hasTwoMainEngines, isMainEngine, timestamps])

  /**
   * Maps entry start and end timestamps to coordinates for plotting on the chart.
   *
   * @param {FuelLineDataLoss|ManualCorrectionOverwrite|ManualCorrectionRepair} entry
   * @returns
   */
  const mapIndicators = (
    entry:
      | FuelLineDataLoss
      | ManualCorrectionOverwrite
      | ManualCorrectionRepair,
  ): Array<{ xAxis: number }> => [
    {
      xAxis: moment.utc(entry.startTimestamp).valueOf(),
    },
    {
      xAxis: moment.utc(entry.endTimestamp).valueOf(),
    },
  ]

  /**
   * lossSeries
   * @description Array of markArea indicators for data losses
   */
  const lossSeries = consumptionLosses
    .map(mapIndicators)
    .filter((entry) => entry.length)

  /**
   * overwriteSeries
   * @description Array of markArea indicators for corrections of type Manual Overwrite
   */
  const overwriteSeries = consumptionOverwrites
    .map(mapIndicators)
    .filter((entry) => entry.length)

  const getOptions = useCallback(
    (): EChartOption | any => ({
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: queryPeriod.from,
        max: queryPeriod.to,
      },
      yAxis: [
        {
          id: 'consumption',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) =>
              formatValue(value, getDecimalsFromUnit(unit, false)),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          position: 'left',
        },
        {
          id: 'density15',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => formatValue(value, 0),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          position: 'right',
          min: 700,
          max: (value) => {
            // Trick to avoid showing yaxis on first render
            const fixed_y_axis_max = 1200
            return value.max - (value.max - fixed_y_axis_max)
          },
        },
      ],
      title: { id: id },
      grid: {
        ...GRID,
        left: 48,
        right: 46,
        bottom: 36,
        containLabel: false,
      },
      toolbox: TOOLBOX_TIMELINE,
      dataZoom: [
        {
          type: 'slider',
          zoomOnMouseWheel: false,
          show: false,
        },
        DATA_ZOOM_TIMELINE,
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        transitionDuration: 0,
        position: positionTooltip,
        formatter: (
          params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[],
        ) => {
          if (!fuelLines) return 'No fuel line data'
          const dataIndex = getDataIndex(params)
          if (!isNumber(dataIndex)) return ''
          const densityVals = {}
          Object.keys(density15Series).forEach((key) => {
            if (+key === FUEL_LINE_TYPES.ME_MAIN && hasTwoMainEngines) {
              // If two main engines in main engine fuel consumption chart,
              // there's a split consumption for the port and stbd engines
              densityVals['me1'] = formatValue(
                density15Series[key][dataIndex],
                getDecimalsFromUnit(UNITS.KILO_GRAM_PER_CUBIC_METER, true),
              )
              densityVals['me2'] = formatValue(
                density15Series[key][dataIndex],
                getDecimalsFromUnit(UNITS.KILO_GRAM_PER_CUBIC_METER, true),
              )
            } else {
              const matchingFuelTypeSelection = filterFuelTypeSelection(
                currentFuelTypeSelections,
                +key,
              )
              densityVals[
                mapFuelLineTypeFuelTypeToName(
                  matchingFuelTypeSelection?.fuelLineType ?? 0,
                  matchingFuelTypeSelection?.fuelType ?? 0,
                  hasMainEngSecondaryFuelLine,
                )
              ] = formatValue(
                density15Series[key][dataIndex],
                getDecimalsFromUnit(UNITS.KILO_GRAM_PER_CUBIC_METER, true),
              )
            }
          })
          const consumptionVals = {}
          fuelLines.forEach((f) => {
            if (
              f.fuelLineType === FUEL_LINE_TYPES.ME_MAIN &&
              hasTwoMainEngines
            ) {
              // If two main engines in main engine fuel consumption chart,
              // there's a split consumption for the port and stbd engines
              const consumption1Series = f.consumption1Series
              const consumption2Series = f.consumption2Series
              consumptionVals['me1'] = formatValue(
                convertToFuelLineUnit(
                  consumption1Series ? consumption1Series[dataIndex] : null,
                  f.fuelLineType,
                ),
                getDecimalsFromUnit(unit, true, f.fuelLineType),
              )
              consumptionVals['me2'] = formatValue(
                convertToFuelLineUnit(
                  consumption2Series ? consumption2Series[dataIndex] : null,
                  f.fuelLineType,
                ),
                getDecimalsFromUnit(unit, true, f.fuelLineType),
              )
            } else {
              const matchingFuelTypeSelection = filterFuelTypeSelection(
                currentFuelTypeSelections,
                +f.fuelLineType,
              )
              consumptionVals[
                mapFuelLineTypeFuelTypeToName(
                  matchingFuelTypeSelection?.fuelLineType ?? 0,
                  matchingFuelTypeSelection?.fuelType ?? 0,
                  hasMainEngSecondaryFuelLine,
                )
              ] = formatValue(
                convertToFuelLineUnit(
                  f.consumptionSeries[dataIndex],
                  f.fuelLineType,
                ),
                getDecimalsFromUnit(unit, true, f.fuelLineType),
              )
            }
          })
          let consumption: any, density15: any
          const isMainEngChart = fuelLines.find(
            (f) => f.fuelLineType === FUEL_LINE_TYPES.ME_MAIN,
          )
          const isDualEngineAndMainEngChart =
            hasTwoMainEngines && isMainEngChart
          if (fuelLines.length > 1 || isDualEngineAndMainEngChart) {
            let totalConsumption: number | null = Object.keys(
              consumptionSeries,
            ).reduce(
              (acc, key) =>
                consumptionSeries[key][dataIndex]
                  ? acc + consumptionSeries[key][dataIndex]
                  : acc,
              0,
            )

            // If any of the keys in null, then display totalConsumption as a '-'
            const hasValueForAllFuelLines = Object.keys(fuelLines).every(
              (f) =>
                typeof fuelLines[f].consumptionSeries[dataIndex] === 'number',
            )

            consumption = {
              label: `Consumption`,
              ...consumptionVals,
              total: formatValue(
                hasValueForAllFuelLines ? totalConsumption : null,
                getDecimalsFromUnit(unit, true, fuelLines[0].fuelLineType),
              ),
              unit: unit,
            }
            density15 = {
              label: `Density 15`,
              ...densityVals,
              total: '',
              unit: UNITS.KILO_GRAM_PER_CUBIC_METER,
            }
          } else {
            consumption = {
              label: `Consumption`,
              ...consumptionVals,
              unit: unit,
            }
            density15 = {
              label: `Density 15`,
              ...densityVals,
              unit: UNITS.KILO_GRAM_PER_CUBIC_METER,
            }
          }
          const tooltipData = [consumption, density15]
          let columnHeaders: any = {
            label: '',
          }
          if (isDualEngineAndMainEngChart) {
            columnHeaders['me1'] = 'Port'
            columnHeaders['me2'] = 'Stbd'
          } else {
            Object.keys(density15Series).forEach((key) => {
              const matchingFuelTypeSelection = filterFuelTypeSelection(
                currentFuelTypeSelections,
                +key,
              )
              columnHeaders[
                mapFuelLineTypeFuelTypeToName(
                  matchingFuelTypeSelection?.fuelLineType ?? 0,
                  matchingFuelTypeSelection?.fuelType ?? 0,
                  hasMainEngSecondaryFuelLine,
                )
              ] = mapFuelLineTypeFuelTypeToName(
                matchingFuelTypeSelection?.fuelLineType ?? 0,
                matchingFuelTypeSelection?.fuelType ?? 0,
                hasMainEngSecondaryFuelLine,
              )
            })
          }
          if (fuelLines.length > 1 || isDualEngineAndMainEngChart) {
            columnHeaders = {
              ...columnHeaders,
              total: 'Total',
              unit: 'Unit',
            }
          } else {
            columnHeaders = {
              ...columnHeaders,
              unit: 'Unit',
            }
          }

          return tooltip(timestamps ? timestamps[dataIndex] : '', tooltipData, [
            columnHeaders,
          ])
        },
      },
      legend: {
        show: false,
        selected: toggleInChart,
      },
      dataset: {
        source: {
          timestamps: timestamps,
          HS: mapSeriesZero(consumptionSeries.HS),
          VLS: mapSeriesZero(consumptionSeries.VLS),
          MDO: mapSeriesZero(consumptionSeries.MDO),
          ULS: mapSeriesZero(consumptionSeries.ULS),
          MM: mapSeriesZero(consumptionSeries.MM),
          ...density15Series,
        },
      },
      series: [
        ...Object.keys(density15Series).map((density15SeriesKey) => {
          return {
            animation: false,
            yAxisIndex: 1,
            xAxisIndex: 0,
            name: density15SeriesKey,
            type: 'line',
            symbol: 'none',
            lineStyle: {
              color: green[500],
            },
            dimensions: ['timestamps', density15SeriesKey],
          }
        }),
        ...splitLineSeries,
        {
          animation: false,
          xAxisIndex: 0,
          yAxisIndex: 0,
          name: 'HS',
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: maritimeBlueTheme.bg,
          },
          areaStyle: {
            color: maritimeBlueTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'HS'],
        },
        {
          animation: false,
          name: 'VLS',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: purpleTheme.bg,
          },
          areaStyle: {
            color: purpleTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'VLS'],
        },
        {
          animation: false,
          name: 'MDO',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: orangeTheme.bg,
          },
          areaStyle: {
            color: orangeTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'MDO'],
        },
        {
          animation: false,
          name: 'ULS',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: maerskBlueTheme.bg,
          },
          areaStyle: {
            color: maerskBlueTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'ULS'],
        },
        {
          animation: false,
          name: 'MM',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: greenTheme.bg,
          },
          areaStyle: {
            color: greenTheme.bg,
            opacity: 0.2,
          },
          dimensions: ['timestamps', 'MM'],
        },
        {
          animation: false,
          name: 'Loss indicators',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          markArea: {
            itemStyle: {
              color: red[500],
              opacity: 0.2,
            },
            data: lossSeries,
          },
          dimensions: ['timestamps'],
        },
        {
          animation: false,
          name: 'Overwrite indicators',
          xAxisIndex: 0,
          yAxisIndex: 0,
          stack: 'fuel-consumption',
          type: 'line',
          symbol: 'none',
          markArea: {
            itemStyle: {
              color: maerskBlue[500],
              opacity: 0.2,
            },
            data: overwriteSeries,
          },
          dimensions: ['timestamps'],
        },
      ],
    }),
    [
      queryPeriod.from,
      queryPeriod.to,
      id,
      toggleInChart,
      timestamps,
      consumptionSeries,
      density15Series,
      splitLineSeries,
      lossSeries,
      overwriteSeries,
      unit,
      fuelLines,
      hasTwoMainEngines,
    ],
  )
  const [chart, setChart]: [Chart | undefined, Function] = useState()

  const prevToggleInChartRef = useRef<any>()
  useEffect(() => {
    prevToggleInChartRef.current = toggleInChart
  })

  const refCallback = useCallback(
    (chartNode) => {
      if (chartNode !== null) {
        const chart = init(chartNode)
        chart.setOption(getOptions())
        chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
        chart.group = 'fuel-consumption-group'
        setChart(chart)
        const chartRef = getInstanceByDom(chartNode)
        setChartRef(chartRef)
      }
    },
    [getOptions, setChartRef],
  )

  useEffect(() => {
    if (chart && prevToggleInChartRef.current) {
      Object.keys(toggleInChart).forEach((key) => {
        if (
          chart &&
          prevToggleInChartRef.current &&
          toggleInChart[key] !== prevToggleInChartRef.current[key]
        ) {
          chart.dispatchAction({
            type: toggleInChart[key] ? 'legendSelect' : 'legendUnSelect',
            name: key,
          })
        }
      })
    }
  }, [chart, toggleInChart])

  const zoomOut = () => {
    const { from, to } = queryPeriod
    chart?.dispatchAction({
      type: 'dataZoom',
      batch: [
        {
          startValue: moment.utc(from).valueOf(),
          endValue: moment.utc(to).valueOf(),
        },
      ],
    })
  }

  return <div onDoubleClick={() => zoomOut()} id={id} ref={refCallback} />
}
