import { useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

import { FormBatchSelection } from './FormBatchSelection'
import { FormFuelTypeSelection } from './FormFuelTypeSelection'
import { ErrorMessage } from '../ErrorMessage'
import { FuelLineType, FuelType } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import type {
  BatchCommons,
  BunkeredBatchLabReport,
} from '../../../../api-models/performance/fuel-oil-stock'
import { StockState } from '../../models'

export interface BatchSelectionCommons extends BatchCommons {
  id: string
  hasLabReport?: boolean
  labReport?: null | BunkeredBatchLabReport
}

const ConsumptionSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Separator = styled.hr`
  margin: 32px 0;
  width: 100%;
`

const validateBatchSelections = (batchSelections) => {
  let error
  const mdoSelection = batchSelections.find(
    ({ fuelType }) => fuelType === FuelType.MDO,
  )
  if (!mdoSelection) {
    error = 'An MDO batch must be selected.'
  }
  return error
}

type Props = {
  batches: Array<BatchSelectionCommons>
  stateOfStock: StockState
}

export const FormConsumptionSelection = ({ batches, stateOfStock }: Props) => {
  const { configuration } = useContext(VesselPageContext)

  const validateFuelTypeSelections = useCallback(
    (fuelTypeSelections) => {
      let error
      const currentFuelLines = fuelTypeSelections.map(
        ({ fuelLineType }) => fuelLineType,
      )
      const allRequiredFuelLines = configuration?.fuelLineTypes.filter(
        (fuelLineType) =>
          ![FuelLineType.AEMDO, FuelLineType.BoilerMDO].includes(fuelLineType),
      )

      allRequiredFuelLines?.forEach((fuelLineType) => {
        if (
          configuration?.hasMainEngSecondaryFuelLine &&
          !currentFuelLines.includes(fuelLineType)
        ) {
          switch (fuelLineType) {
            case FuelLineType.MEMain:
              if (
                currentFuelLines.includes(FuelLineType.MEMain) &&
                !currentFuelLines.includes(FuelLineType.MESecondary)
              ) {
                currentFuelLines.push(FuelLineType.MESecondary)
              }
              break
            case FuelLineType.MESecondary:
              if (
                currentFuelLines.includes(FuelLineType.MESecondary) &&
                !currentFuelLines.includes(FuelLineType.MEMain)
              ) {
                currentFuelLines.push(FuelLineType.MEMain)
              }
              break
            case FuelLineType.AEMain:
              if (
                currentFuelLines.includes(FuelLineType.AEMain) &&
                !currentFuelLines.includes(FuelLineType.AESecondary)
              ) {
                currentFuelLines.push(FuelLineType.AESecondary)
              }
              break
            case FuelLineType.AESecondary:
              if (
                currentFuelLines.includes(FuelLineType.AESecondary) &&
                !currentFuelLines.includes(FuelLineType.AEMain)
              ) {
                currentFuelLines.push(FuelLineType.AEMain)
              }
              break
            default:
              break
          }
        }
      })

      const hasAllRequiredFuelLines = allRequiredFuelLines?.every(
        (fuelLineType) => currentFuelLines.includes(fuelLineType),
      )
      if (!hasAllRequiredFuelLines) {
        error = 'All fuel type selections must be made'
      }
      return error
    },
    [configuration],
  )

  const _uniqueFuelTypesOnboard = Array.from(
    new Set(batches.map(({ fuel }) => fuel.type).sort((a, b) => a - b)),
  )

  return (
    <>
      <ConsumptionSelectionContainer className='joyride-rob-consumption'>
        <ErrorMessagesContainer>
          <ErrorMessage
            name='consumption.batchSelections'
            width='calc(100% - 16px)'
          />
          <ErrorMessage
            name='consumption.fuelTypeSelections'
            width='calc(100% - 16px)'
          />
        </ErrorMessagesContainer>
        <div className='joyride-batch-selections'>
          <Field
            name='consumption.batchSelections'
            validate={validateBatchSelections}
            component={(props) => (
              <FormBatchSelection
                {...props}
                batches={batches}
                uniqueFuelTypesOnboard={_uniqueFuelTypesOnboard}
              />
            )}
          />
        </div>
        <Separator />
        <div className='joyride-fuel-type-selection'>
          <Field
            name='consumption.fuelTypeSelections'
            validate={validateFuelTypeSelections}
            component={(props) => (
              <FormFuelTypeSelection
                stockState={stateOfStock}
                {...props}
                uniqueFuelTypesOnboard={_uniqueFuelTypesOnboard}
                hasMainEngSecondaryFuelLine={
                  configuration?.hasMainEngSecondaryFuelLine
                }
              />
            )}
          />
        </div>
      </ConsumptionSelectionContainer>
    </>
  )
}
