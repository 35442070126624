import React, {
  useCallback,
  useMemo,
  FunctionComponent,
  useContext,
} from 'react'
import { ECharts } from 'echarts'
import moment from 'moment'

import { Performance } from '../../../api-models'
import { ContentCard, Loading } from '../../../commons'
import { UNITS, FUEL_LINE_TYPES } from '../../../utils/constants'
import { useXAxisZoomSync } from '../../../chart-lib'
import { FuelConsumptionChartWrapper } from './FuelConsumptionChartWrapper'
import { UserEntryTypes } from '../../stock-management/models'
import { useConsumerConsumption } from '..'
import { mapBatchMonitorToDimensions } from '../utils/batch-monitor'
import { FuelConsumptionContext } from '../../../contexts/fuel-consumption'
import { useGetCurrentFuelTypeSelections } from '../../../queries/PerformanceApi/Papi'

interface FuelConsumptionProps {
  configuration: Performance.VesselConfiguration.Configuration
}

export const FuelConsumption: FunctionComponent<FuelConsumptionProps> = ({
  configuration,
}) => {
  const [meChart, setMeChart] = React.useState<ECharts>()
  const [meMonitorChart, setMeMonitorChart] = React.useState<ECharts>()
  const [meConsumptionChart, setMeConsumptionChart] = React.useState<ECharts>()
  const [aeChart, setAeChart] = React.useState<ECharts>()
  const [aeMonitorChart, setAeMonitorChart] = React.useState<ECharts>()
  const [aeConsumptionChart, setAeConsumptionChart] = React.useState<ECharts>()
  const [boilerChart, setBoilerChart] = React.useState<ECharts>()
  const [boilerMonitorChart, setBoilerMonitorChart] = React.useState<ECharts>()
  const [boilerConsumptionChart, setBoilerConsumptionChart] =
    React.useState<ECharts>()
  const { consumption } = useContext(FuelConsumptionContext)

  const { data: getCurrentFuelTypeSelections, isLoading } =
    useGetCurrentFuelTypeSelections(configuration.imoNo.toString())

  const oilConsumption = consumption.monitor.oil
  const batchConsumption = consumption.monitor.batch

  // Sync x axis zooming across charts
  useXAxisZoomSync(
    meChart,
    meMonitorChart,
    meConsumptionChart,
    aeChart,
    aeMonitorChart,
    aeConsumptionChart,
    boilerChart,
    boilerMonitorChart,
    boilerConsumptionChart,
  )

  const [mainEngFuelConsumption, auxEngFuelConsumption, boilerFuelConsumption] =
    useConsumerConsumption(oilConsumption)
  const batchMonitorSeries = useMemo(
    () => ({
      mainEngSeries: mapBatchMonitorToDimensions(
        configuration.hasMainEngSecondaryFuelLine,
        getCurrentFuelTypeSelections?.filter((fuelLine) =>
          [FUEL_LINE_TYPES.ME_MAIN, FUEL_LINE_TYPES.ME_SECONDARY].includes(
            fuelLine.fuelLineType,
          ),
        ) ?? [],
        batchConsumption?.mainEngFuelLines as
          | Performance.FuelConsumption.FuelLineBatchMonitor[]
          | undefined,
        batchConsumption?.queryPeriod,
      ),
      auxEngSeries: mapBatchMonitorToDimensions(
        configuration.hasMainEngSecondaryFuelLine,
        getCurrentFuelTypeSelections?.filter((fuelLine) =>
          [
            FUEL_LINE_TYPES.AE_MAIN,
            FUEL_LINE_TYPES.AE_SECONDARY,
            FUEL_LINE_TYPES.AE_MDO,
          ].includes(fuelLine.fuelLineType),
        ) ?? [],
        batchConsumption?.auxEngFuelLines,
        batchConsumption?.queryPeriod,
      ),
      boilerSeries: mapBatchMonitorToDimensions(
        configuration.hasMainEngSecondaryFuelLine,
        getCurrentFuelTypeSelections?.filter((fuelLine) =>
          [FUEL_LINE_TYPES.BOILER_MAIN, FUEL_LINE_TYPES.BOILER_MDO].includes(
            fuelLine.fuelLineType,
          ),
        ) ?? [],
        batchConsumption?.boilerLines,
        batchConsumption?.queryPeriod,
      ),
    }),
    [batchConsumption, configuration, getCurrentFuelTypeSelections],
  )

  const getMixingPeriodSeriesForFuelType = useCallback(
    (fuelLines?: Performance.FuelConsumption.FuelLineBatchMonitor[]) => {
      const mixingPeriod: Array<Array<number | null>> = []
      if (!fuelLines || !fuelLines[0]) return mixingPeriod
      fuelLines.forEach((fuelLine) =>
        fuelLine.indicators.forEach((indicator, index, indicators) => {
          if (indicator.type === UserEntryTypes.MixedBatch) {
            mixingPeriod.push([moment.utc(indicator.timestamp).valueOf(), 1])
            const nextIndicator = indicators[index + 1]
            if (!nextIndicator) {
              mixingPeriod.push([moment.utc().valueOf(), 1])
            }
            if (
              nextIndicator &&
              nextIndicator.type !== UserEntryTypes.MixedBatch
            ) {
              mixingPeriod.push([
                moment.utc(nextIndicator.timestamp).valueOf(),
                1,
              ])
            }
          } else {
            mixingPeriod.push([moment.utc(indicator.timestamp).valueOf(), null])
          }
        }),
      )

      return mixingPeriod
    },
    [],
  )

  const mainEngMixingPeriodSeries = useMemo(
    () =>
      getMixingPeriodSeriesForFuelType(
        batchConsumption?.mainEngFuelLines as Performance.FuelConsumption.FuelLineBatchMonitor[],
      ),
    [batchConsumption, getMixingPeriodSeriesForFuelType],
  )

  const auxEngMixingPeriodSeries = useMemo(
    () => getMixingPeriodSeriesForFuelType(batchConsumption?.auxEngFuelLines),
    [batchConsumption, getMixingPeriodSeriesForFuelType],
  )

  const boilerEngMixingPeriodSeries = useMemo(
    () => getMixingPeriodSeriesForFuelType(batchConsumption?.boilerLines),
    [batchConsumption, getMixingPeriodSeriesForFuelType],
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <ContentCard
        id='fc-me'
        title='ME'
        helpTextKey='fuel-consumption/main-engines'
      >
        <FuelConsumptionChartWrapper
          batchMonitorSeries={batchMonitorSeries.mainEngSeries}
          fuelConsumption={mainEngFuelConsumption}
          currentFuelTypeSelections={
            getCurrentFuelTypeSelections?.filter((fuelLine) =>
              [FUEL_LINE_TYPES.ME_MAIN, FUEL_LINE_TYPES.ME_SECONDARY].includes(
                fuelLine.fuelLineType,
              ),
            ) ?? []
          }
          hasTwoMainEngines={!!configuration?.hasTwoMainEngines}
          id={'fc-me-charts'}
          mixingPeriodSeries={mainEngMixingPeriodSeries}
          queryPeriod={oilConsumption?.queryPeriod}
          setChartRef={setMeChart}
          setConsumptionChartRef={setMeConsumptionChart}
          setMonitorChartRef={setMeMonitorChart}
          unit={UNITS.MT_PER_HOUR}
        />
      </ContentCard>

      <ContentCard
        id='fc-ae'
        title='AE'
        helpTextKey='fuel-consumption/auxiliary-engines'
      >
        <FuelConsumptionChartWrapper
          batchMonitorSeries={batchMonitorSeries.auxEngSeries}
          fuelConsumption={auxEngFuelConsumption}
          currentFuelTypeSelections={
            getCurrentFuelTypeSelections?.filter((fuelLine) =>
              [
                FUEL_LINE_TYPES.AE_MAIN,
                FUEL_LINE_TYPES.AE_SECONDARY,
                FUEL_LINE_TYPES.AE_MDO,
              ].includes(fuelLine.fuelLineType),
            ) ?? []
          }
          hasTwoMainEngines={!!configuration?.hasTwoMainEngines}
          id={'fc-ae-charts'}
          mixingPeriodSeries={auxEngMixingPeriodSeries}
          queryPeriod={oilConsumption?.queryPeriod}
          setChartRef={setAeChart}
          setConsumptionChartRef={setAeConsumptionChart}
          setMonitorChartRef={setAeMonitorChart}
          unit={UNITS.KG_PER_HOUR}
        />
      </ContentCard>

      <ContentCard
        id='fc-boiler'
        title='Boiler'
        helpTextKey='fuel-consumption/boiler'
      >
        <FuelConsumptionChartWrapper
          batchMonitorSeries={batchMonitorSeries.boilerSeries}
          fuelConsumption={boilerFuelConsumption}
          currentFuelTypeSelections={
            getCurrentFuelTypeSelections?.filter((fuelLine) =>
              [
                FUEL_LINE_TYPES.BOILER_MAIN,
                FUEL_LINE_TYPES.BOILER_MDO,
              ].includes(fuelLine.fuelLineType),
            ) ?? []
          }
          hasTwoMainEngines={!!configuration?.hasTwoMainEngines}
          id={'fc-boiler-charts'}
          mixingPeriodSeries={boilerEngMixingPeriodSeries}
          queryPeriod={oilConsumption?.queryPeriod}
          setChartRef={setBoilerChart}
          setConsumptionChartRef={setBoilerConsumptionChart}
          setMonitorChartRef={setBoilerMonitorChart}
          unit={UNITS.KG_PER_HOUR}
        />
      </ContentCard>
    </React.Fragment>
  )
}
