import styled from 'styled-components'

import Accordion from '../../../components/accordion'
import OverwriteForm from '../components/overwrite-form'
import type {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'
import { FuelLineType } from '../../../utils'
import { HelpText } from '../../help-texts'
import { useContext } from 'react'
import { VesselPageContext } from '../../../contexts'
import { getFuelLineTypeSelectOptions } from '../../../utils/mappers/FuelLineMappers.utils'

const AccItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`

type Props = {
  imoNo: string
  fuelLineTypes: FuelLineType[]
  repairs: ManualCorrectionRepair[]
  overwrites: ManualCorrectionOverwrite[]
  refreshFuelConsumption: () => void
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
}

/**
 * FuelConsumptionCorrections
 * @description component used to display corrections, this covers both repairs and manual overwrites.
 *
 * @param {string} imoNo A string representing the unique vessel ID.
 * @param {FuelLineType[]} fuelLineTypes An array of graph associated fuel line types.
 * @param {ManualCorrections[]} repairs An array of consumption repairs filtered by fuel line type.
 * @param {ManualCorrections[]} overwrites An array of consumption overwrites filtered by fuel line type.
 * @param {function} refreshFuelConsumption A callback that refreshes all of fuel consumption data set
 * @param currentFuelTypeSelections An array of current fuel type selections
 */
const FuelConsumptionCorrections = ({
  imoNo,
  fuelLineTypes,
  overwrites,
  repairs,
  refreshFuelConsumption,
  currentFuelTypeSelections,
}: Props) => {
  const configuration = useContext(VesselPageContext).configuration!
  const fuelLineOptions: { label: string; value: FuelLineType }[] =
    currentFuelTypeSelections?.flatMap((fl) =>
      getFuelLineTypeSelectOptions(
        fl.fuelType,
        fl.fuelLineType,
        configuration.hasMainEngSecondaryFuelLine,
      ),
    ) || []

  return (
    <Accordion
      depth={0}
      itemRenderer={() => (
        <AccItem>
          Manual Overwrite {<HelpText textKey='fuel-consumption/overwrite' />}
        </AccItem>
      )}
      toggle={''}
    >
      <OverwriteForm
        id='lorem'
        refreshData={refreshFuelConsumption}
        fuelLineOptions={fuelLineOptions}
      />
    </Accordion>
  )
}

export default FuelConsumptionCorrections
