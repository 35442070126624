import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { recalculateResults } from '../../EngineTestReportPage.utils'
import {
  TableContainer,
  TableHeadingCol,
  TableValueCol,
} from '../../EngineTestReportPage.styles'
import Legend from '../../Common/Legend'
import SelectField from '../../Common/SelectField'
import NumberInputField from '../../Common/NumberInputField'
import CommentField from '../../Common/CommentField'
import Checkbox from '../../Common/Checkbox'
import ReadonlyInputField from '../../Common/ReadonlyInputField'
import { VesselPageContext, WindowContext } from '../../../../contexts'
import { formatValue, isShoreContext } from '../../../../utils'
import { UNITS } from '../../../../utils/constants'
import { MetcDataFormat } from '../../../../queries/GandalfApi/GandalfApi.consts'
import { OperatingConditionNames } from '../../../../queries/MasterDataApi/MasterDataApi.consts'

type Props = {
  dataFormat: number
}

const GeneralTab = ({ dataFormat }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const vesselPageContext = useContext(VesselPageContext)
  const { configuration } = vesselPageContext

  const ctx = useFormikContext<GandalfApi.MetcReport>()
  const { data } = ctx.values

  const operatingConditionOptions: Array<{
    label: string
    value: MasterDataApi.StaticModels.MainEngOperationMode
  }> = useMemo(() => {
    if (dataFormat < MetcDataFormat.dualFuel) {
      return []
    }
    return data.equipment.operatingConditions.map((oc) => ({
      label: OperatingConditionNames[oc.code],
      value: oc.code,
    }))
  }, [data.equipment.operatingConditions])

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  const renderSfocFields = () => {
    return (
      <>
        <tr>
          <td>SFOC measured</td>
          <td>
            <ReadonlyInputField
              value={formatValue(data.result.general.sfoc, 2)}
              unit={UNITS.SFOC}
            />
          </td>
        </tr>
        <tr>
          <td>SFOC LCV Corrected to 42700 kg/kJ</td>
          <td>
            <ReadonlyInputField
              value={formatValue(data.result.general.sfocLcvCorrected, 2)}
              unit={UNITS.SFOC}
            />
          </td>
        </tr>
      </>
    )
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Operating conditions</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Engine shaft power</td>
              <td>
                <NumberInputField
                  name={'data.general.enginePower'}
                  unit='kW'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Engine load</td>
              <td>
                <ReadonlyInputField
                  value={formatValue(data.result.general.engineLoad, 2)}
                  unit='%MCR'
                />
              </td>
            </tr>
            <tr>
              <td>Engine RPM</td>
              <td>
                <NumberInputField
                  name={'data.general.shaftRpm'}
                  unit='RPM'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Engine running hours</td>
              <td>
                <NumberInputField
                  name={'data.general.engineRunningHours'}
                  unit='hours'
                />
              </td>
            </tr>
            {dataFormat >= MetcDataFormat.dualFuel && (
              <tr>
                <td>Operation mode</td>
                <td>
                  <SelectField
                    name='data.general.operationMode'
                    options={operatingConditionOptions}
                    onBlur={_recalculateResults}
                  />
                </td>
              </tr>
            )}
            {data.equipment.hasTcco && (
              <tr>
                <td>TCCO operation</td>
                <td>
                  <Checkbox
                    name='data.general.tccoEngaged'
                    label='Engaged'
                    onChange={_recalculateResults}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>Auxiliary blowers</td>
              <td>
                <Checkbox name='data.general.auxBlowersOn' label='Engaged' />
              </td>
            </tr>
            {data.equipment.hasWhr && (
              <tr>
                <td>Exhaust gas bypass valve position</td>
                <td>
                  <NumberInputField
                    name={'data.general.exhaustBypassPosition'}
                    unit='%'
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Fuel</th>
              <th>Value</th>
            </tr>
          </thead>
          {!configuration?.hasMainEngSecondaryFuelLine && (
            <tbody>
              <tr>
                <td>Fuel batch</td>
                <td
                  style={{
                    textAlign: 'end',
                    lineHeight: 2.2,
                  }}
                >
                  {data.general.batchName}
                </td>
              </tr>
              <tr>
                <td>Fuel LCV</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(data.general.batchLcv, 0)}
                    unit={UNITS.KJ_PER_KILO}
                  />
                </td>
              </tr>
              <tr>
                <td>Fuel CCAI</td>
                <td>
                  <NumberInputField name={'data.general.batchCcai'} />
                </td>
              </tr>
              <tr>
                <td>Fuel consumption</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(data.general.consumedMass?.value, 0)}
                    unit={UNITS.KILOGRAM}
                  />
                </td>
              </tr>
              {renderSfocFields()}
            </tbody>
          )}
          {configuration?.hasMainEngSecondaryFuelLine && (
            <tbody>
              <tr>
                <td>Fuel batch</td>
                <td
                  style={{
                    textAlign: 'end',
                    lineHeight: 2.2,
                  }}
                >
                  {data.general.pilotBatchName}
                </td>
              </tr>
              <tr>
                <td>Fuel LCV</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(data.general.pilotBatchLcv, 0)}
                    unit={UNITS.KJ_PER_KILO}
                  />
                </td>
              </tr>
              <tr>
                <td>Fuel CCAI</td>
                <td>
                  <NumberInputField name={'data.general.batchCcai'} />
                </td>
              </tr>
              <tr>
                <td>Fuel consumption</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(
                      data.general.pilotConsumedMass?.value,
                      0,
                    )}
                    unit={UNITS.KILOGRAM}
                  />
                </td>
              </tr>
              <tr>
                <td>DF Fuel batch</td>
                <td
                  style={{
                    textAlign: 'end',
                    lineHeight: 2.2,
                  }}
                >
                  {data.general.batchName}
                </td>
              </tr>
              <tr>
                <td>DF Fuel LCV</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(data.general.batchLcv, 0)}
                    unit={UNITS.KJ_PER_KILO}
                  />
                </td>
              </tr>
              <tr>
                <td>DF Fuel consumption</td>
                <td>
                  <ReadonlyInputField
                    value={formatValue(data.general.consumedMass.value, 0)}
                    unit={UNITS.KILOGRAM}
                  />
                </td>
              </tr>
              {renderSfocFields()}
            </tbody>
          )}
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Load condition</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ambient pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.erAmbientPres'}
                  unit='mbar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenging air temperature</td>
              <td>
                <NumberInputField
                  name={'data.general.scavAirTemp'}
                  unit='°C'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenging air pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.scavAirPres'}
                  unit='bar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Exhaust gas receiver pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.exhaustGasReceiverPres'}
                  unit='bar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Blower inlet temperature</td>
              <td>
                <NumberInputField
                  name={'data.general.blowerInletTemp'}
                  unit='°C'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Turbine back pressure</td>
              <td>
                <NumberInputField
                  name={'data.general.turbineBackPres'}
                  unit='mmH2O'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <CommentField label='Comment' name='data.comment' />
    </div>
  )
}

export default GeneralTab
