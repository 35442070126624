import { useContext } from 'react'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import {
  TableContainer,
  TableHeadingCol,
  TableValueCol,
} from '../../EngineTestReportPage.styles'
import { formatValue } from '../../../../utils'
import { WindowContext } from '../../../../contexts'
import { UNITS } from '../../../../utils/constants'
import { MetcDataFormat } from '../../../../queries/GandalfApi/GandalfApi.consts'
import { OperatingConditionNames } from '../../../../queries/MasterDataApi/MasterDataApi.consts'

const ENGINE_RUN_CONDITION_TOOLTIP =
  'Indicates if the engine has been tested at light or heavy running condition. A positive number indicates that engine is heavy running, and a negative number means light running.'

type Props = {
  general: GandalfApi.Metc.General
  equipment: GandalfApi.Metc.Equipment
  result: GandalfApi.Metc.Result
  dataFormat: number
}

const OperatingConditions = ({
  general,
  equipment,
  result,
  dataFormat,
}: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <colgroup>
          <TableHeadingCol />
          <TableValueCol />
        </colgroup>
        <thead>
          <tr>
            <th>Operating conditions</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Engine name</td>
            <td>{`${equipment.designer} ${equipment.engineType}`}</td>
          </tr>
          <tr>
            <td>Model name</td>
            <td>{equipment.name}</td>
          </tr>
          <tr>
            <td>Engine shaft power</td>
            <td>{general.enginePower.value} kW</td>
          </tr>
          <tr>
            <td>Engine load</td>
            <td>{formatValue(result.general.engineLoad, 1)} %MCR</td>
          </tr>
          <tr>
            <td>Engine run condition</td>
            <td>
              <McTooltip appearance='inverse' position='top-center' width={600}>
                <span slot='trigger' style={{ cursor: 'help' }}>
                  {formatValue(result.general.engineRunCondition, 1)} %
                </span>
                <span>{ENGINE_RUN_CONDITION_TOOLTIP}</span>
              </McTooltip>
            </td>
          </tr>
          <tr>
            <td>Engine RPM</td>
            <td>{formatValue(general.shaftRpm.value, 0)}</td>
          </tr>
          <tr>
            <td>Engine running hours</td>
            <td>{formatValue(general.engineRunningHours.value, 0)} hours</td>
          </tr>
          {dataFormat >= MetcDataFormat.dualFuel && (
            <tr>
              <td>Operation mode</td>
              <td>{OperatingConditionNames[general.operationMode]}</td>
            </tr>
          )}
          {equipment.hasTcco && (
            <tr>
              <td>TCCO engaged</td>
              <td>{general.tccoEngaged.value ? 'Yes' : 'No'}</td>
            </tr>
          )}
          <tr>
            <td>SFOC measured</td>
            <td>{formatValue(result.general.sfoc, 2)} g/kWh</td>
          </tr>
          <tr>
            <td>SFOC LCV Corrected to 42700 {UNITS.KJ_PER_KILO}</td>
            <td>{formatValue(result.general.sfocLcvCorrected, 2)} g/kWh</td>
          </tr>
          <tr>
            <td>Pcomp measured</td>
            <td>{formatValue(result.cylinders.pcompAvg, 2)} bar</td>
          </tr>
          <tr>
            <td>Pmax measured</td>
            <td>{formatValue(result.cylinders.pmaxAvg, 2)} bar</td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default OperatingConditions
