import React, { FunctionComponent } from 'react'
import { FuelConsumptionBlock } from '../../fuel-consumption'
import styled from 'styled-components'
import { Block } from '../../../commons'
import { Performance } from '../../../api-models'

export const Wrapper = styled.div`
  margin-bottom: -8px; /* To compensate and not add padding to last element */
  .header {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
  }
  label {
    margin-bottom: 8px;
  }
`

interface StatusBlockProps {
  header: string
  dataFuelLines?: Performance.Status.FuelLine[] | null
  unit: string
  currentFuelTypeSelections: Papi.FDL.FuelTypeSelectionResponse[]
}

export const StatusBlock: FunctionComponent<StatusBlockProps> = ({
  header,
  dataFuelLines,
  unit,
  currentFuelTypeSelections,
}) => {
  return (
    <Wrapper>
      <Block>
        <div className='header'>{header}</div>
        {currentFuelTypeSelections &&
          currentFuelTypeSelections.map((currentSelection, i) => {
            const fuelLine = dataFuelLines
              ? dataFuelLines.find(
                  (fuelLine) =>
                    fuelLine?.fuelLineType === currentSelection.fuelLineType,
                )
              : undefined
            return (
              <FuelConsumptionBlock
                key={i}
                fuelLine={fuelLine}
                fuelLineType={currentSelection.fuelLineType}
                fuelType={currentSelection.fuelType}
                unit={unit}
              />
            )
          })}
      </Block>
    </Wrapper>
  )
}
