import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { McButton, McTab, McTabBar } from '@maersk-global/mds-react-wrapper'
import { Form, Formik, type FormikHelpers } from 'formik'

import {
  Section,
  TabIndex,
  MetcReportTabSearchParam,
  TabIndexToSection,
  type TabsStatus,
} from './MetcReportPage.consts'
import {
  getIndeterminateTabsStatus,
  resolveInitialTabIndex,
  resolveInitialTabsStatus,
  resolveTabsStatus,
} from './MetcReportPage.utils'
import Header from './Common/Header'
import TabPrefix from '../Common/TabPrefix'
import GeneralTab from './GeneralTab/GeneralTab'
import CylinderTab from './CylinderTab/CylinderTab'
import TurbochargerTab from './TurbochargerTab/TurbochargerTab'
import ResultsTab from './ResultsTab/ResultsTab'
import {
  displaySuccessToast,
  setFieldErrors,
} from '../EngineTestReportPage.utils'
import { TabStatus } from '../EngineTestReportPage.consts'
import { ActionButtonsWrapper } from '../EngineTestReportPage.styles'
import useSearchParams from '../../../hooks/useSearchParams'
import { WindowContext } from '../../../contexts'
import { ContentLayout } from '../../../layout'
import { ContentCard, Loading } from '../../../commons'
import { PadContent } from '../../../layout/styles'
import { type routerParams } from '../../../routes'
import {
  useGetReport,
  usePatchReport,
  usePutReport,
} from '../../../queries/GandalfApi/GandalfApi'
import { ReportType } from '../../../queries/GandalfApi/GandalfApi.consts'
import { isShoreContext } from '../../../utils'
import TemNotifications from '../../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../../components/PerformanceAlerts/PerformanceAlerts'
import Footer from '../../../components/Reporting/Footer/Footer'

type Params = routerParams & {
  reportId: string
}

const MetcReportPage = () => {
  const { windowSize } = useContext(WindowContext)
  const history = useHistory()
  const location = useLocation()
  const { vesselId, reportId } = useParams<Params>()
  const searchParams = useSearchParams()

  const getReport = useGetReport<GandalfApi.Metc.ReportData>(
    vesselId,
    reportId,
    ReportType.Metc,
  )
  const patchReport = usePatchReport(vesselId, reportId, ReportType.Metc)
  const putReport = usePutReport(vesselId, reportId, ReportType.Metc)

  const [isUpdating, setIsUpdating] = useState(false)
  const [tabIndex, setTabIndex] = useState<TabIndex>(() =>
    resolveInitialTabIndex(searchParams),
  )
  const [tabsStatus, setTabsStatus] = useState<TabsStatus>(
    getIndeterminateTabsStatus,
  )

  useEffect(() => {
    if (getReport.isSuccess) {
      setTabsStatus(resolveInitialTabsStatus(getReport.data.report.reportState))
    }
  }, [getReport.isSuccess])

  if (!getReport.isSuccess) {
    return <Loading />
  }

  const updateTabIndex = (index: number) => {
    setTabIndex(index)
    searchParams.set(MetcReportTabSearchParam, index.toString())
    history.replace({ ...location, search: searchParams.toString() })
  }

  const isResultsTab = tabIndex === TabIndex.RESULTS

  const handleSubmit = async (
    values: GandalfApi.MetcReport,
    helpers: FormikHelpers<GandalfApi.MetcReport>,
  ) => {
    setIsUpdating(true)
    try {
      if (isResultsTab) {
        await putReport.mutateAsync(values)
        displaySuccessToast()
        history.push(`/MaerskStarConnect/vessel/${vesselId}/engine-test`)
      } else {
        await patchReport.mutateAsync({
          section: TabIndexToSection[tabIndex],
          payload: values,
        })
        setTabsStatus({
          ...tabsStatus,
          [tabIndex]: TabStatus.SUCCESS,
        })
        updateTabIndex(tabIndex + 1)
      }
    } catch (error) {
      setFieldErrors(error, helpers.setFieldError)
      if (isResultsTab) {
        setTabsStatus(resolveTabsStatus(error))
      } else {
        setTabsStatus({
          ...tabsStatus,
          [tabIndex]: TabStatus.ERROR,
        })
      }
    } finally {
      setIsUpdating(false)
    }
  }

  const handleDiscardChanges = () => {
    history.push(`/MaerskStarConnect/vessel/${vesselId}/engine-test`)
  }

  const handleTabChange = ({ detail }: CustomEvent<number>) => {
    updateTabIndex(detail)
  }

  const submitBtnIcon = isResultsTab ? 'check' : 'arrow-right'
  const submitBtnLabel = isResultsTab ? 'Submit test' : 'Save & Next'

  const report = getReport.data.report

  return (
    <Formik
      initialValues={getReport.data.report}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      <Form>
        <ContentLayout header={`Engine test report – No. ${report.reportNo}`}>
          <TemNotifications />
          <PerformanceAlerts />
          <ContentCard id='metc__report' title='' hideHeader>
            <Header report={report} />
            <PadContent>
              <McTabBar
                currentindex={tabIndex}
                fit={windowSize}
                tabchange={handleTabChange}
              >
                <McTab slot='tab' label={Section.GENERAL}>
                  <TabPrefix tabStatus={tabsStatus[TabIndex.GENERAL]} />
                </McTab>
                <GeneralTab dataFormat={report.dataFormat} />
                <McTab slot='tab' label={Section.CYLINDERS}>
                  <TabPrefix tabStatus={tabsStatus[TabIndex.CYLINDERS]} />
                </McTab>
                <CylinderTab isActive={tabIndex === TabIndex.CYLINDERS} />
                <McTab slot='tab' label={Section.TURBOCHARGERS}>
                  <TabPrefix tabStatus={tabsStatus[TabIndex.TURBOCHARGERS]} />
                </McTab>
                <TurbochargerTab />
                <McTab slot='tab' label={Section.RESULTS}>
                  <TabPrefix tabStatus={tabsStatus[TabIndex.RESULTS]} />
                </McTab>
                <ResultsTab
                  isActive={tabIndex === TabIndex.RESULTS}
                  dataFormat={report.dataFormat}
                />
              </McTabBar>
            </PadContent>
            <PadContent>
              <ActionButtonsWrapper>
                <McButton
                  fit={windowSize}
                  variant='outlined'
                  label='Close and discard changes'
                  click={handleDiscardChanges}
                />
                <McButton
                  type='submit'
                  fit={windowSize}
                  loading={isUpdating}
                  trailingicon={submitBtnIcon}
                  disabled={isShoreContext()}
                >
                  {submitBtnLabel}
                </McButton>
              </ActionButtonsWrapper>
            </PadContent>
          </ContentCard>
          <Footer dataFormat={report.dataFormat} />
        </ContentLayout>
      </Form>
    </Formik>
  )
}

export default MetcReportPage
