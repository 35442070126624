import { useContext } from 'react'
import { type Table } from '@tanstack/react-table'

import type { UserEntry } from '../../../api-models/performance/fuel-oil-stock'
import { VesselPageContext, WindowContext } from '../../../contexts'
import {
  FilterWrapper,
  ResultsCountTag,
} from '../../../components/Table/Table.styles'
import { FUEL_OIL_USER_ENTRY_TYPE_NAMES } from '../../../features/stock-management/models'
import DateFilter from '../ActivityLogFilters/DateFilter/DateFilter'
import EntryTypeFilter from '../ActivityLogFilters/EntryTypeFilter/EntryTypeFilter'
import FuelTypeFilter from '../ActivityLogFilters/FuelTypeFilter/FuelTypeFilter'
import BatchNameFilter from '../ActivityLogFilters/BatchNameFilter/BatchNameFilter'

type Props = {
  table: Table<UserEntry>
}

const FuelAndStockFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const configuration = useContext(VesselPageContext).configuration!
  const getAvailableEntryTypes = (table: Table<UserEntry>) => {
    const entryTypes = new Set<number>()

    table.getRowModel().rows.forEach((row) => {
      entryTypes.add(row.original.type)
    })

    return Array.from(entryTypes).reduce((acc, entryType) => {
      const row = table
        .getRowModel()
        .rows.find((row) => row.original.type === entryType)
      if (row) {
        const fuelType = row.original.fuelType
        acc[entryType] =
          typeof FUEL_OIL_USER_ENTRY_TYPE_NAMES[entryType] === 'function'
            ? FUEL_OIL_USER_ENTRY_TYPE_NAMES[entryType](
                fuelType,
                configuration.hasMainEngSecondaryFuelLine,
              )
            : FUEL_OIL_USER_ENTRY_TYPE_NAMES[entryType]
      }
      return acc
    }, {} as { [k: number]: string })
  }

  // Usage
  const availableEntryTypes: { [k: number]: string } =
    getAvailableEntryTypes(table)

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='timestamp' />
      <div className='fuel-stock-entry-type-filter'>
        <EntryTypeFilter
          table={table}
          label='Entry type'
          availableEntryTypes={availableEntryTypes}
          columnId='type'
        />
      </div>
      <div className='fuel-type-filter'>
        <FuelTypeFilter table={table} />
      </div>
      <div className='batch-name-filter'>
        <BatchNameFilter table={table} />
      </div>
      <ResultsCountTag
        appearance='info'
        label={`${table.getFilteredRowModel().rows.length} results`}
        fit={windowSize}
      />
    </FilterWrapper>
  )
}

export default FuelAndStockFilters
