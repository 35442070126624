import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { doGet, getPerformanceApiUrl } from '../../utils'
import { Performance } from '../../api-models'

const STALE_TIME_12_HOURS = 1000 * 60 * 60 * 12

const PAPI = 'Papi' as const
export const FUEL_CONSUMPTION_BATCH_MONITOR = {
  [PAPI]: 'fuelConsumptionBatchMonitor',
} as const
export const FUEL_OIL_STOCK_BUNKERED_BATCH = {
  [PAPI]: 'fuelOilStockBunkeredBatch',
} as const
export const FUEL_OIL_STOCK_MIXED_BATCH = {
  [PAPI]: 'fuelOilStockMixedBatch',
} as const
const COUNTER_DATA_POINT_DIFFERENCE = {
  [PAPI]: 'counterDataPointDifference',
}
export const FDL_FUEL_TYPE_SELECTIONS_CURRENT = {
  [PAPI]: 'fdlFuelTypeSelectionsCurrent',
} as const
export const FUEL_OIL_STOCK_STATUS = {
  [PAPI]: 'fuelOilStockStatus',
}

export const keys = {
  fuelConsumptionBatchMonitor: (imoNo: string, from: string, to: string) =>
    [{ ...FUEL_CONSUMPTION_BATCH_MONITOR, imoNo, from, to }] as const,
  fuelOilStockBunkeredBatch: (imoNo: string, id: string) =>
    [{ ...FUEL_OIL_STOCK_BUNKERED_BATCH, imoNo, id }] as const,
  fuelOilStockMixedBatch: (imoNo: string, id: string) =>
    [{ ...FUEL_OIL_STOCK_MIXED_BATCH, imoNo, id }] as const,
  counterDataPointDifference: (
    imoNo: string,
    from: string,
    to: string,
    dataPointId: number,
  ) =>
    [
      { ...COUNTER_DATA_POINT_DIFFERENCE, imoNo, from, to, dataPointId },
    ] as const,
  fdlFuelTypeSelectionsCurrent: (imoNo: string) =>
    [{ ...FDL_FUEL_TYPE_SELECTIONS_CURRENT, imoNo }] as const,
  fuelOilStockStatus: (imoNo: string, includeBurndown?: boolean) =>
    [{ ...FUEL_OIL_STOCK_STATUS, imoNo, includeBurndown }] as const,
}

export const getCounterDataPointDifference = async ({
  queryKey: [{ imoNo, from, to, dataPointId }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['counterDataPointDifference']>
>): Promise<Papi.DataPointDifference.Response> => {
  const searchParams = new URLSearchParams({
    from,
    to,
    dataPointId: dataPointId.toString(),
  })

  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/Counter/DatapointDifference?${searchParams.toString()}`,
  )
}

export const useGetFuelConsumptionBatchMonitor = (
  imoNo: string,
  from: string,
  to: string,
) => {
  return useQuery({
    queryKey: keys.fuelConsumptionBatchMonitor(imoNo, from, to),
    queryFn: getFuelConsumptionBatchMonitor,
    staleTime: STALE_TIME_12_HOURS,
  })
}

const getFuelConsumptionBatchMonitor = async ({
  queryKey: [{ imoNo, from, to }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['fuelConsumptionBatchMonitor']>
>): Promise<Papi.FuelConsumption.BatchMonitor> => {
  const searchParams = new URLSearchParams({ from, to })

  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/BatchMonitor?${searchParams.toString()}`,
  )
}

export const useGetFuelOilStockBunkeredBatch = (imoNo: string, id: string) => {
  return useQuery({
    queryKey: keys.fuelOilStockBunkeredBatch(imoNo, id),
    queryFn: getFuelOilStockBunkeredBatch,
    staleTime: STALE_TIME_12_HOURS,
    enabled: id !== '',
  })
}

const getFuelOilStockBunkeredBatch = async ({
  queryKey: [{ imoNo, id }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['fuelOilStockBunkeredBatch']>
>): Promise<Papi.FuelOil.Stock.BunkeredBatch> => {
  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BunkeredBatch/${id}`,
  )
}

export const useGetFuelOilStockMixedBatch = (imoNo: string, id: string) => {
  return useQuery({
    queryKey: keys.fuelOilStockMixedBatch(imoNo, id),
    queryFn: getFuelOilStockMixedBatch,
    staleTime: STALE_TIME_12_HOURS,
    enabled: id !== '',
  })
}

const getFuelOilStockMixedBatch = async ({
  queryKey: [{ imoNo, id }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['fuelOilStockMixedBatch']>
>): Promise<Papi.FuelOil.Stock.MixedBatch> => {
  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/MixedBatch/${id}`,
  )
}

const getCurrentFuelTypeSelections = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['fdlFuelTypeSelectionsCurrent']>
>): Promise<Papi.FDL.FuelTypeSelectionResponse[]> => {
  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelections/Current`,
  )
}

export const useGetCurrentFuelTypeSelections = (imoNo: string) => {
  return useQuery({
    queryKey: keys.fdlFuelTypeSelectionsCurrent(imoNo),
    queryFn: getCurrentFuelTypeSelections,
    staleTime: STALE_TIME_12_HOURS,
  })
}

//Todo: make types in PAPI for this api
const getStockStatus = async ({
  queryKey: [{ imoNo, includeBurndown }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['fuelOilStockStatus']>
>): Promise<Performance.FuelOilStock.StatusResponse> => {
  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Status${
      includeBurndown ? '?includeBurndown=true' : ''
    }`,
  )
}

export const useGetStockStatus = (imoNo: string, includeBurndown?: boolean) => {
  return useQuery({
    queryKey: keys.fuelOilStockStatus(imoNo, includeBurndown),
    queryFn: getStockStatus,
    staleTime: STALE_TIME_12_HOURS,
  })
}
